import './index.scss'
import { Button, Modal, Input, Form, message, Radio, Popover, Divider, Table } from 'antd';
import { globalState } from '@/stores';
import React, { useState, useEffect } from 'react';
import { queryBizSwitch, updateBizSwitch } from '@/request/DiningOptions';
import { TabType } from '../enum';

interface IProps {
  activeKey: TabType
}
interface IRow {
  display: boolean
  displayOrder: number
  name: string
}
export default function PosModuleDisplay (props: IProps) {
  const i18n = globalState.i18n;
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const tableColumns: any = [
    {
      dataIndex: 'name', key: 'name', title: 'POS Module', width: 100, align: 'center'
    },
    {
      dataIndex: 'display', key: 'display', title: 'Display', width: 120, align: 'center',
      render: (_, row: IRow) => {
        return (
          <div>
            <Radio.Group value={+row.display} onChange={() => { changeTable(row, true) }} disabled={!isEdit}>
              <Radio value={1}></Radio>
            </Radio.Group>
          </div>
        )
      }
    },
    {
      dataIndex: 'display', key: 'display', title: 'Hide', width: 120, align: 'center',
      render: (_, row: IRow) => {
        return (
          <div>
            <Radio.Group value={+row.display} onChange={() => { changeTable(row, false) }} disabled={!isEdit}>
              <Radio value={0}></Radio>
            </Radio.Group>
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    if (props.activeKey === TabType.POSMODULEDISPLAY) {
      fetchTableData();
    } else {
      setIsEdit(false);
    }
  }, [props]);

  const fetchTableData = async () => {
    setLoading(true)
    const { code, data } = await queryBizSwitch();
    if (code === 0) {
      console.log('data', data)
      setTableData(data);
    }
    setLoading(false)
  }

  const changeTable = (row: IRow, display: boolean) => {
    const arr = [...tableData];
    const index = arr.findIndex(item => item.name === row.name)
    arr[index].display = display;
    setTableData(arr);
  }

  const handleSave = async () => {
    const list = tableData.map((ele: IRow) => ({ name: ele.name, display: ele.display }));
    const res = await updateBizSwitch({
      switchList: list
    })
    if (res.code === 0) {
      message.success(res.message);
      setIsEdit(false);
    }
  }

  const handleEdit = () => {
    setIsEdit(true);
  }
  return (
    <div className='pos-module-display-content'>
      <div className="pos-module-display-content-tabpane">
        {
          isEdit ? <Button type="primary" onClick={handleSave}>{i18n.t('save')}</Button> : <Button type="primary" onClick={handleEdit}>{i18n.t('edit_1')}</Button>
        }
      </div>
      <div className="dining-options-content-tableWrap">
        <Table
          loading={loading}
          rowKey={'name'}
          columns={tableColumns}
          dataSource={tableData}
          pagination={false}>
        </Table>
      </div>
    </div>
  )
}
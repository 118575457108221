import HeaderSection from '../previewUI/headerSection'
import RestaurantInfoSection from '../previewUI/restaurantInfoSection';
import CheckAndGuestInfoSection from '../previewUI/checkAndGuestInfoSection';
import ItemInfoSection from '../previewUI/itemInfoSection';
import FooterSection from '../previewUI/footerSection';
import PaymentStatusSection from '../previewUI/paymentStatusSection';
import CheckNotesSection from '../previewUI/checkNotesSection';
import DiscountSection from '../previewUI/discountSection';
import PaymentInfoSection from '../previewUI/paymentInfoSection';
import TipAreaSection from '../previewUI/tipAreaSection';
import CustomerCopySection from '../previewUI/customerCopySection';
import ReprintSection from '../previewUI/reprintSection';
import SignatureSection from '../previewUI/signatureSection';
import SummarySection from '../previewUI/summarySection';
import PaymentDetailsSection from '../previewUI/paymentDetailsSection';
import TipsSection from '../previewUI/tipsSection';
import TotalVoidsSection from '../previewUI/totalVoidsSection'
import TotalDiscountsSection from '../previewUI/totalDiscountsSection';
import RefundsSection from '../previewUI/refundsSection';
import SalesCategoriesSection from '../previewUI/salesCategoriesSection';
import DiningOptionsSection from '../previewUI/diningOptionsSection';
import CashDrawerSection from '../previewUI/cashDrawerSection';
import EndOfReportSection from '../previewUI/endOfReportSection';
import CreditCardPaymentDetailsSection from '../previewUI/creditCardPaymentDetailsSection';
import PrintInfoSection from '../previewUI/printInfoSection';
import DateSection from '../previewUI/dateSection';
import CashReconciliationSection from '../previewUI/cashReconciliationSection';
import ServiceChargeSection from '../previewUI/serviceChargeSection';
import GuestInfoSection from '../previewUI/guestInfoSection';
import PrepStationSettingSection from '../previewUI/prepStationSettingSection';
import PrepStationName from '../previewUI/prepStationName';
import ServiceSection from '../previewUI/serviceDetailSection';
import ServiceDetailSection from '../previewUI/serviceDetailSection';
interface IPreviewComponentList {
  type: string;
  Component: (props: any) => JSX.Element;
}

export const previewComponentList: Array<IPreviewComponentList> = [
  {
    type: 'header-section',
    Component: HeaderSection
  },
  {
    type: 'restaurant-info-section',
    Component: RestaurantInfoSection
  },
  {
    type: 'check-info-section',
    Component: CheckAndGuestInfoSection
  },
  {
    type: 'item-info-section',
    Component: ItemInfoSection
  },
  {
    type: 'check-notes-section',
    Component: CheckNotesSection
  },
  {
    type: 'footer-section',
    Component: FooterSection
  },
  {
    type: 'payment-status-section',
    Component: PaymentStatusSection
  },
  {
    type: 'discount-info-section',
    Component: DiscountSection
  },
  {
    type: 'payment-info-section',
    Component: PaymentInfoSection
  },
  {
    type: 'tip-suggestion-section',
    Component: TipAreaSection
  },
  {
    type: 'signature-section',
    Component: SignatureSection
  },
  {
    type: 'customer-copy-section',
    Component: CustomerCopySection
  },
  {
    type: 'reprint-section',
    Component: ReprintSection
  },
  {
    type: 'summary-section',
    Component: SummarySection
  },
  {
    type: 'payment-details-section',
    Component: PaymentDetailsSection
  },
  {
    type: 'tips-section',
    Component: TipsSection
  },
  {
    type: 'total-voids-section',
    Component: TotalVoidsSection
  },
  {
    type: 'total-discounts-section',
    Component: TotalDiscountsSection
  },
  {
    type: 'refunds-section',
    Component: RefundsSection
  },
  {
    type: 'sales-categories-section',
    Component: SalesCategoriesSection
  },
  {
    type: 'dining-options-section',
    Component: DiningOptionsSection
  },
  {
    type: 'cash-drawer-section',
    Component: CashDrawerSection
  },
  {
    type: 'report-footer-section',
    Component: EndOfReportSection
  },
  {
    type: 'cash-reconciliation-section',
    Component: CashReconciliationSection
  },
  {
    type: 'credit-card-payment-section',
    Component: CreditCardPaymentDetailsSection
  },
  {
    type: 'print-info-section',
    Component: PrintInfoSection
  },
  {
    type: 'date-section',
    Component: DateSection
  },
  {
    type: 'service-charge-section',
    Component: ServiceChargeSection
  },
  {
    type: 'guest-info-section',
    Component: GuestInfoSection
  },
  {
    type: 'prep-station-setting-section',
    Component: PrepStationSettingSection
  },
  {
    type: 'prep-station-name',
    Component: PrepStationName
  },
  {
    type: 'service-detail-section',
    Component: ServiceDetailSection
  }
]
import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';
interface IProps{
  text: string
}
const TotalVoidsSection = (props: IProps) => {
  return <div className='total-voids'>
    <div>
      <TextLineNew
        text='TOTAL VOIDS'
        style={{
          fontWeight: 'bold',
        }}/>
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }}/>
    <div className='total-voids-item'>
      <div className='total-voids-item-title'>
        <TextLineNew text='Sales voids'/>
      </div>
      <div className='total-voids-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>
    <div className='total-voids-item'>
      <div className='total-voids-item-title'>
        <TextLineNew text='Void item quantity'/>
      </div>
      <div className='total-voids-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>
  </div>
}
export default TotalVoidsSection;
export enum GroupType{
  MERGE_SAME_DISHES = '1',
  SPLIT_SAME_DISHES = '2',
  SAME_AS_SHOPPING_CART = '3'
}

export const GroupTypeList = [
  {
    label: 'rms_overseas_operation_configuration_kitchen_GroupType_MERGE_SAME_DISHES',
    value: GroupType.MERGE_SAME_DISHES
  },
  {
    label: 'rms_overseas_operation_configuration_kitchen_GroupType_SPLIT_SAME_DISHES',
    value: GroupType.SPLIT_SAME_DISHES
  },
  {
    label: 'rms_overseas_operation_configuration_kitchen_GroupType_SAME_AS_SHOPPING_CART',
    value: GroupType.SAME_AS_SHOPPING_CART
  }
]

export enum PrintType{
  PRINT_WHOLE = 2,
  PRINT_SEPARATE = 1
}

export const PrintTypeList = [
  {
    label: 'yes',
    value: PrintType.PRINT_WHOLE
  },
  {
    label: 'no',
    value: PrintType.PRINT_SEPARATE
  }
]

export enum PrintedReceiptType{
  PRODUCTION = 'kitchenTicketNum',
  DISH_REFUND = 'voidKitchenTicketNum',
  TRANSFER = 'transferTicketNum',
}

export const PrintedReceiptKitchen = {
  label: 'rms_overseas_operation_configuration_kitchen_ReceiptTypeEnum_PRODUCTION',
  value: PrintedReceiptType.PRODUCTION
}

export const PrintedReceiptTypeList = [
  {
    label: 'rms_overseas_operation_configuration_kitchen_ReceiptTypeEnum_DISH_REFUND',
    value: PrintedReceiptType.DISH_REFUND
  },
  {
    label: 'rms_overseas_operation_configuration_kitchen_ReceiptTypeEnum_TRANSFER',
    value: PrintedReceiptType.TRANSFER
  }
]

export const SelectOptions = [
  {
    value: '1',
    label: '1'
  },
  {
    value: '2',
    label: '2'
  }
]

export const ReceiptList = [
  {
    value: '1',
    displayName: 'Production'
  }, {
    displayName: 'Dish refund',
    value: '5'
  }, {
    displayName: 'Transfer',
    value: '12'
  }
]

export const MerchantReceiptEnum = {
  QUICK_ORDER: '1',
  TABLE_SERVICE: '2',
  CALL_IN: '3',
  OPEN_TAB: '4',
}

export const MerchantReceiptList = [
  {
    label: 'pc_product_mix_sales_quick_service',
    value: MerchantReceiptEnum.QUICK_ORDER
  },
  {
    label: 'rms_overseas_transaction_order_OrderBizType_DINNER',
    value: MerchantReceiptEnum.TABLE_SERVICE
  },
  {
    label: 'pc_product_mix_sales_takeout',
    value: MerchantReceiptEnum.CALL_IN
  },
  {
    label: 'prep_stations_open_tab',
    value: MerchantReceiptEnum.OPEN_TAB
  }
]

export const CustomerReceiptList = [
  {
    label: 'rms_overseas_transaction_order_OrderBizType_DINNER',
    value: MerchantReceiptEnum.TABLE_SERVICE
  },
  {
    label: 'pc_product_mix_sales_takeout',
    value: MerchantReceiptEnum.CALL_IN
  },
  {
    label: 'prep_stations_open_tab',
    value: MerchantReceiptEnum.OPEN_TAB
  }
]
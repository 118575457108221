import React, { useEffect, useState } from 'react'
import { Table, Tooltip } from 'antd'
import { formatDollar, formatPrice } from '@/utils';
import { globalState } from '@/stores';
import './index.scss'
import Price from '../components/Price';
import classNames from 'classnames';
import Columns from '@/Components/Columns/Columns';
import { ColumnsType } from 'antd/es/table';
import { TableEmptyContent } from '@/Components/EmptyContent';
import { InfoCircleOutlined } from '@ant-design/icons';

function PaymentsList ({ list: tableData }) {
  const { i18n } = globalState;
  const [total, setTotal] = useState()
  const [resultList, setResultList] = useState([])
  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)

  const PAY_TYPE = {
    CARD: '2',
    CASH: '1',
    OTHER: '99',
    TOTAL: '0'
  }

  useEffect(() => {
    if (!tableData) return;
    setTotal(tableData.find(x => x.payType === '0')?.totalAmount ?? 0)
    formatResultList();
  }, [tableData]);

  const formatResultList = () => {
    let cardTotal = {};
    const cardItems = [];
    let cashTotal = {};
    const cashItems = [];
    let otherTotal = {};
    const otherItems = [];
    let total = {};
    tableData.forEach(x => {
      if (x.payMethodId === '0') {
        if (x.payType === PAY_TYPE.CASH) {
          cashTotal = x
        } else if (x.payType === PAY_TYPE.CARD) {
          cardTotal = x
        } else if (x.payType === PAY_TYPE.OTHER) {
          otherTotal = x
        } else if (x.payType === PAY_TYPE.TOTAL) {
          total = x
        }
      } else {
        if (x.payType === PAY_TYPE.CASH) {
          cashItems.push(x)
        } else if (x.payType === PAY_TYPE.CARD) {
          cardItems.push(x)
        } else if (x.payType === PAY_TYPE.OTHER) {
          otherItems.push(x)
        }
      }
    })
    setResultList(tableData.length === 0 ? [] : [cardTotal, ...cardItems, cashTotal, ...cashItems, otherTotal, ...otherItems, total].filter(x => Object.keys(x).length > 0))
  }

  const tableColumns = [
    {
      title: i18n.t('pc_sales_summary_payment_type'), dataIndex: 'payType', width: 150, render: (text, record) => {
        if (record.payType === PAY_TYPE.CASH) return 'Cash'
        // 类目汇总行
        if (record.payMethodId === '0') {
          if (record.payType === PAY_TYPE.CARD) return 'Card'
          if (record.payType === PAY_TYPE.OTHER) return 'Other'
          if (record.payType === PAY_TYPE.TOTAL) return 'Total'
        }
        // 单条目
        return (
          <div className="col-payment-type">
            <span>·</span>
            { record.payType === PAY_TYPE.CARD && <span className="card-type">{ record.cardType }</span> }
            { record.payType === PAY_TYPE.OTHER && <span className="card-type">{ record.payMethodName }</span> }
          </div>
        )
      }
    },
    { title: i18n.t('pc_sales_summary_count'), dataIndex: 'paymentCount', width: 80 },
    {
      title: (
        <div className="col-header-wrap">
          <span className="col-header-title">{ i18n.t('pc_sales_summary_amount') }</span>
          <Tooltip placement="top" title={ i18n.t('pc_sales_summary_count_tooltip') }>
            <InfoCircleOutlined className="sales-summary-tip-icon"/>
          </Tooltip>
        </div>
      ),
      dataIndex: 'netTotalWithTax', width: 120, render: (val) => <Price value={ val }/>, pureTitle: i18n.t('pc_sales_summary_amount'),
    },
    { title: i18n.t('pc_sales_summary_tips'), dataIndex: 'tips', width: 100, render: (val) => <Price value={ val }/> },
    { title: i18n.t('pc_sales_summary_grat'), dataIndex: 'gratuity', width: 100, render: (val) => <Price value={ val }/> },
    {
      title: (
        <div className="col-header-wrap">
          <span className="col-header-title">{ i18n.t('pc_sales_summary_tips_grat') }</span>
          <Tooltip placement="top" title={ i18n.t('pc_sales_summary_tips_grat_tooltips') }>
            <InfoCircleOutlined className="sales-summary-tip-icon"/>
          </Tooltip>
        </div>
      ),
      dataIndex: 'tipPercentage', width: 120, render: (val) => `${ val }%`, pureTitle: i18n.t('pc_sales_summary_tips_grat')
    },
    { title: i18n.t('pc_sales_summary_refunds'), dataIndex: 'refunds', width: 100, render: (val) => <Price value={ val }/> },
    { title: i18n.t('pc_sales_summary_total_amount'), dataIndex: 'totalAmount', width: 130, render: (val) => <Price value={ val }/> },
  ]
  const [showColumnsList, setShowColumnsList] = useState(tableColumns.map(x => x.dataIndex))

  const handleChangeColumns = (showColumnsList) => {
    setIsShowEmptyTable(showColumnsList.length === 0)
    setShowColumnsList(showColumnsList);
  }

  return (
    <div className="sales-summary-payments-list">
      <div className="header-wrap">
        <div className="sales-list-title-wrap">
          <span>{ i18n.t('pc_sales_summary_payments') }: </span>
          <Price value={ total }/>
        </div>
        <div className="actions-wrap">
          <Columns
            value={ showColumnsList }
            options={ tableColumns.map(item => ({ label: item.pureTitle ?? item.title, value: item.dataIndex })) }
            onChange={ handleChangeColumns }
          />
        </div>

      </div>
      <div className={ classNames('net-sales-list-table-wrap', { 'no-data': (tableData || []).length === 0 }) }>
        <Table
          className={ isShowEmptyTable && 'empty-table' }
          columns={ tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType }
          dataSource={ resultList.map((x, i) => ({ ...x, key: i })) }
          rowClassName={ (_, index) => classNames('row', { 'sticky-row': index === tableData.length - 1 }) }
          scroll={ { y: 410, x: 'max-content' } }
          pagination={ false }
          summary={ () => (
            isShowEmptyTable && <Table.Summary.Row>
              <Table.Summary.Cell index={ 0 } colSpan={ 999 }>
                { TableEmptyContent }
              </Table.Summary.Cell>
            </Table.Summary.Row>
          ) }
        />
      </div>
    </div>
  )
}

export default React.memo(PaymentsList)
import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { IRenderConfigProps } from '../../interface';
import { observer } from 'mobx-react';
import './RenderCheckBoxGroup.scss'
import { toJS } from 'mobx';
import printOutDecorationStore from '../../store'
import { configDataOptions } from '../../mapData/typeMapConfigDataOptions';
import { handleParentVisibilityBasedOnChildren, handleToggleChildrenVisibility } from '../../utils/fnRenderCheckBoxGroup';

export default observer(function RenderCheckBoxGroup (props: IRenderConfigProps)  {
  const { content, sectionId } = props
  const { defaultValue, defaultSort } = content;


  const [defaultValueOptions, setDefaultValueOptions] = useState<Array<any>>([])
  const [currentValueOptions, setCurrentValueOptions] = useState<Array<string>>([])
  const [defaultSortList, setDefaultSortList] = useState<Array<string[]>>([])
  const [checkboxGroupIds, setCheckboxGroupIds]  = useState<Array<string>>([])

  const onChange = (checkedValuesList) => {
    const filteredValues = checkboxGroupIds.filter(value => !checkedValuesList.includes(value));

    // ***** update ConfigUI *****
    // ***** get previewData *****
    let previewData = toJS(printOutDecorationStore.getPreviewData)

    // ***** modifier previewData *****
    previewData = previewData.map(item => {
      if (item.id === sectionId) {

        const updatedSort = defaultSortList.map(group => group.map(item => filteredValues.includes(item) ? '' : item).filter(f => !!f));

        Object.keys(item.children).forEach(key => {
          if (filteredValues.includes(key)) {
            item.children[key].hide = true;
          } else {
            item.children[key].hide = false;
          }
        });

        // 处理是否全部hide的情况，则父级的disabled为false -> hide：true。若父级的disabled为true -> hide不变
        const previewItem = handleParentVisibilityBasedOnChildren(item)

        // 更新item数组的状态
        return { ...previewItem, sort: updatedSort };
      }
      return item;
    });

    // ***** update previewData *****
    printOutDecorationStore.savePreviewData(previewData)
  };

  useEffect(() => {
    // 默认值
    if (defaultValue) {
      setDefaultValueOptions(defaultValue)
    }

    // 存sortList值
    // ---- todo 要统一改为模版的default中的值，不然需要维护两套
    if (defaultSort) {
      setDefaultSortList(defaultSort)
    }

  }, [defaultValue, defaultSort])

  useEffect(() => {
    // preview 当前值
    const previewData = toJS(printOutDecorationStore.getPreviewData);
    let visibleChildrenKeys;
    previewData.forEach(item => {
      if (item.id === sectionId) {
        visibleChildrenKeys = Object.keys(item.children).filter(key => !item.children[key].hide);
      }
    })
    setCurrentValueOptions(visibleChildrenKeys);


    // 存当前section的全量默认值checkBoxGroup的值
    const typeParamsInUrl = toJS(printOutDecorationStore.getUrlParamsType)
    const checkboxGroupIdsList = [];
    configDataOptions[typeParamsInUrl].forEach(item => {
      if (item.id === sectionId && item.children) {
        item.children.forEach(child => {
          if (child.tag === 'checkboxGroup') {
            // 将符合条件的child的id添加到数组中
            child.defaultValue.forEach(dItem => {
              checkboxGroupIdsList.push(dItem.id);
            });
          }
        });
        setCheckboxGroupIds(checkboxGroupIdsList)
      }
    })

  }, [printOutDecorationStore.getPreviewData, sectionId]);


  return (
    <div className="render-check-box-group">
      <Checkbox.Group
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
        value={currentValueOptions}
        options={defaultValueOptions}
        onChange={values => onChange(values)}
      />
    </div>
  );
})
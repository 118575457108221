import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, InputNumber, message, Radio, Spin, TimePicker } from 'antd';
import './index.scss';
import { globalState } from '@/stores';
import dayjs, { Dayjs } from 'dayjs';
import { ApiOnlineOrderConfigQuery, ApiOnlineOrderConfigSave } from '@/request/api';
import { APPROVAL_ENUM, APPROVAL_ENUM_I18N, IBusinessHour, IPickupConfig, QUOTE_TIME_ENUM, QUOTE_TIME_ENUM_I18N, SCHEDULING_ORDERS_ENUM, SCHEDULING_ORDERS_ENUM_I18N, WEEK_DAY_I18N } from '@/Pages/OnlineOrders/interface';
import { to } from '@/utils';
import NP from '@/utils/NP';
import useGetState from '@/hooks/useGetState';

interface IProps {
  isActive: boolean
}

interface IForm {
  pickUpSwitch: boolean,
  schedulingOrdersMode: SCHEDULING_ORDERS_ENUM,
  quoteTimeMode: QUOTE_TIME_ENUM,
  quoteTimeCustomMin: number,
  quoteTimeCustomHour: number,
  approvalMode: APPROVAL_ENUM,
}

const TIME_BASE = dayjs('1970-01-02 00:00:00.000').valueOf();

export default function Pickup (props: IProps) {
  const { i18n } = globalState;
  const { isActive } = props;
  const [form] = Form.useForm<IForm>()
  const [isInit, setIsInit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const backupDataRef = useRef<string>('');
  const [pickupHoursList, setPickupHoursList, getPickupHoursList] = useGetState<Array<IBusinessHour>>([])

  const watchedQuoteTimeMode = Form.useWatch(['quoteTimeMode'], form);

  useEffect(() => {
    if (isActive && !isInit) {
      fetchDetail();
    }
  }, [isActive]);

  const fetchDetail = async () => {
    setLoading(true);
    const res = await ApiOnlineOrderConfigQuery();
    setLoading(false);
    if (!res?.data) return;
    backupDataRef.current = JSON.stringify(res.data);
    initForm();
    setIsInit(true);
  }

  const initForm = () => {
    const data: IPickupConfig = JSON.parse(backupDataRef.current);
    const {
      PICK_UP_SWITCH, PICK_UP_HOURS, PICK_UP_SCHEDULING_ORDERS, PICK_UP_QUOTE_TIME, PICK_UP_APPROVAL
    } = data;
    const { hours: quoteTimeCustomMin, minutes: quoteTimeCustomHour } = getHoursAndMinutes(PICK_UP_QUOTE_TIME.customMinutes)
    form.setFieldsValue({
      pickUpSwitch: PICK_UP_SWITCH,
      schedulingOrdersMode: PICK_UP_SCHEDULING_ORDERS.mode,
      quoteTimeMode: PICK_UP_QUOTE_TIME.mode,
      quoteTimeCustomMin,
      quoteTimeCustomHour,
      approvalMode: PICK_UP_APPROVAL.mode,
    });
    setPickupHoursList(PICK_UP_HOURS.map(x => ({
      ...x,
      intervalTimes: x.intervalTimes.map(time => ({ startTime: secondsToDayjs(time.startTime), endTime: secondsToDayjs(time.endTime) }))
    })));
  }

  const secondsToDayjs = (sec) => {
    return dayjs(NP.plus(NP.times(sec, 1000), TIME_BASE));
  }

  const dayjsToSeconds = (dayjsObj) => {
    return Math.floor(NP.divide(NP.minus(dayjsObj.valueOf(), TIME_BASE), 1000));
  }

  const getHoursAndMinutes = (totalMinutes) => {
    const maxHours = 999;
    let hours = Math.floor(NP.divide(totalMinutes, 60));
    let minutes = totalMinutes % 60;

    if (hours >= maxHours) {
      minutes = NP.plus(minutes, NP.times(NP.minus(hours, maxHours), 60));
      hours = maxHours;
    }

    return { hours, minutes };
  }

  const formRules = {
    quoteTimeMode: [() => ({
      validator (_, value) {
        if (value === QUOTE_TIME_ENUM.CUSTOM && form.getFieldValue('quoteTimeCustomMin') === 0 && form.getFieldValue('quoteTimeCustomHour') === 0) {
          return Promise.reject(new Error(i18n.t('pc_online_orders_quote_time_cannot')))
        }
        return Promise.resolve()
      },
    })],
    getPickupHoursRules: (_index) => {
      return [() => ({
        validator (_, value) {
          const _pickupHoursList = getPickupHoursList();
          const pickupHourItem = _pickupHoursList[_index];
          const startTime = pickupHourItem.intervalTimes[0].startTime;
          const endTime = pickupHourItem.intervalTimes[0].endTime;
          if (startTime.isSame(endTime) && pickupHourItem.openSwitch) {
            return Promise.reject(new Error(i18n.t('pc_online_orders_quote_time_error')))
          }
          return Promise.resolve()
        },
      })]
    }
  }

  const handleEdit = () => {
    if (!backupDataRef.current) return;
    setIsEdit(true);
  }

  const handleCancel = () => {
    initForm();
    clearError();
    setIsEdit(false);
  }

  const clearError = () => {
    const allFormName = Object.keys(form.getFieldsValue())
    form.setFields(allFormName.map(x => ({ name: x, errors: [] })))
  }

  const handleSave = async () => {
    const [err, formParams] = await to(form.validateFields());
    if (err) return;
    const { pickUpSwitch, schedulingOrdersMode, quoteTimeMode, quoteTimeCustomMin, quoteTimeCustomHour, approvalMode } = formParams;
    const result = {
      PICK_UP_SWITCH: pickUpSwitch,
      PICK_UP_SCHEDULING_ORDERS: {
        mode: schedulingOrdersMode
      },
      PICK_UP_QUOTE_TIME: {
        mode: quoteTimeMode,
        customMinutes: quoteTimeCustomMin * 60 + quoteTimeCustomHour
      },
      PICK_UP_APPROVAL: {
        mode: approvalMode
      },
      PICK_UP_HOURS: pickupHoursList.map((x => ({
        ...x,
        intervalTimes: x.intervalTimes.map(time => ({ startTime: dayjsToSeconds(time.startTime), endTime: dayjsToSeconds(time.endTime) }))
      })))
    }
    setLoading(true)
    const res = await ApiOnlineOrderConfigSave(result);
    if (res.code === 0) {
      message.success(res.message);
      setIsEdit(false);
      await fetchDetail();
    }
    setLoading(false)
  }

  const handleChangePickupHours = (event, item, field, weekIndex) => {
    const _pickupHoursList = [...pickupHoursList]
    if (field === 'openSwitch') {
      _pickupHoursList.find(x => x.day === item.day).openSwitch = event.target.value;
    } else if (field.includes('intervalTimes')) {
      const _hoursRangeIndex = field.split('_')[1];
      _pickupHoursList.find(x => x.day === item.day).intervalTimes[_hoursRangeIndex] = { startTime: event[0], endTime: event[1] };
    }
    setPickupHoursList(_pickupHoursList)
    form.validateFields([`pickupHours_${ weekIndex }`])
  }

  const handleBlurQuoteTime = (e, field) => {
    setTimeout(() => {
      if (form.getFieldValue(field) === null || form.getFieldValue(field) === '') {
        form.setFieldValue(field, 0);
      }
      form.validateFields(['quoteTimeMode']);
    }, 0)
  }

  const getDisabled = (val) => {
    if (!isEdit) return true;
    return val;
  }

  return (
    <Spin spinning={ loading } wrapperClassName="spin-wrap">
      <div className="online-orders-pickup-wrap">
        <div className="form-wrap">
          <Form form={ form } layout="vertical" requiredMark={ false } disabled={ !isEdit }>
            <Form.Item name={ 'pickUpSwitch' } label={ i18n.t('pc_online_orders_pickup') }>
              <Radio.Group options={ [{ value: true, label: i18n.t('pc_online_orders_on') }, { value: false, label: i18n.t('pc_online_orders_off') }] } style={ { display: 'grid' } }/>
            </Form.Item>
            <Form.Item name={ 'schedulingOrdersMode' } label={ i18n.t('pc_online_orders_scheduling_orders') }>
              <Radio.Group
                options={ [{ value: SCHEDULING_ORDERS_ENUM.ASAP, label: i18n.t(SCHEDULING_ORDERS_ENUM_I18N[SCHEDULING_ORDERS_ENUM.ASAP]) }] }
                style={ { display: 'grid' } }
              />
            </Form.Item>
            <Form.Item name={ 'quoteTimeMode' } label={ i18n.t('pc_online_orders_quote_times') } rootClassName="quote-time-mode-wrap" rules={ formRules.quoteTimeMode }>
              <Radio.Group style={ { display: 'grid' } }>
                <Radio value={ QUOTE_TIME_ENUM.ASAP }>{ i18n.t(QUOTE_TIME_ENUM_I18N[QUOTE_TIME_ENUM.ASAP]) }</Radio>
                <Radio value={ QUOTE_TIME_ENUM.CUSTOM }>{ i18n.t(QUOTE_TIME_ENUM_I18N[QUOTE_TIME_ENUM.CUSTOM]) }</Radio>
                <div className="quote-time-input-wrap">
                  <Form.Item name={ 'quoteTimeCustomMin' }>
                    <InputNumber
                      addonAfter={ i18n.t('pc_online_orders_hour') } min={ 0 } max={ 999 } precision={ 0 } style={ { width: 150 } }
                      disabled={ getDisabled(watchedQuoteTimeMode === QUOTE_TIME_ENUM.ASAP) }
                      onBlur={ (e) => handleBlurQuoteTime(e, 'quoteTimeCustomMin') }
                    />
                  </Form.Item>
                  <Form.Item name={ 'quoteTimeCustomHour' }>
                    <InputNumber
                      addonAfter={ i18n.t('pc_online_orders_min') } min={ 0 } max={ 999 } precision={ 0 } style={ { width: 150 } }
                      disabled={ getDisabled(watchedQuoteTimeMode === QUOTE_TIME_ENUM.ASAP) }
                      onBlur={ (e) => handleBlurQuoteTime(e, 'quoteTimeCustomHour') }
                    />
                  </Form.Item>
                </div>
                <div></div>
              </Radio.Group>
            </Form.Item>
            <Form.Item name={ 'approvalMode' } label={ i18n.t('pc_online_orders_approval') }>
              <Radio.Group
                options={ [{ value: APPROVAL_ENUM.MANUALLY, label: i18n.t(APPROVAL_ENUM_I18N[APPROVAL_ENUM.MANUALLY]) }] }
                style={ { display: 'grid' } }/>
            </Form.Item>
            <Form.Item label={ <span className="pickup-hours-label">{ i18n.t('pc_online_orders_pickup_hours') }</span> }>
              {
                pickupHoursList.map((x: IBusinessHour, weekIndex) => (
                  <Form.Item name={ `pickupHours_${ weekIndex }` } key={ x.day } rules={ formRules.getPickupHoursRules(weekIndex) } rootClassName={ 'pickup-hours-form-item' }>
                    <div className="pickup-hours-item">
                      <span className="week-day">{ i18n.t(WEEK_DAY_I18N[x.day]) }</span>
                      <Radio.Group value={ x.openSwitch } onChange={ (event) => handleChangePickupHours(event, x, 'openSwitch', weekIndex) } options={ [{ value: true, label: i18n.t('pc_online_orders_pickup_hours_open') }, { value: false, label: i18n.t('pc_online_orders_pickup_hours_closed') }] }/>
                      <TimePicker.RangePicker
                        disabled={ getDisabled(!x.openSwitch) }
                        format="hh:mm A"
                        value={ [x.intervalTimes[0].startTime as Dayjs, x.intervalTimes[0].endTime as Dayjs] }
                        onChange={ (value) => handleChangePickupHours(value, x, 'intervalTimes_0', weekIndex) }
                        allowClear={ false }
                        needConfirm={ false }
                        use12Hours
                      />
                    </div>
                  </Form.Item>
                ))
              }
            </Form.Item>
          </Form>
        </div>
        <div className="action-wrap">
          { isEdit ?
            <>
              <Button onClick={ handleCancel }>{ i18n.t('cancel') }</Button>
              <Button type="primary" onClick={ handleSave } loading={ loading }>{ i18n.t('save') }</Button>
            </>
            : <Button type="primary" onClick={ handleEdit } key={ 'edit' }>{ i18n.t('edit') }</Button>
          }
        </div>
      </div>
    </Spin>
  )
}
import './index.scss'
import React, { useEffect, useState } from 'react';
import { Button, Col, Row, message, Divider, Spin } from 'antd';
import { ApiQueryGuestFacingConfig, ApiSaveGuestFacingConfig } from '@/request/api'
import { globalState } from '@/stores';
import { EditOutlined } from '@ant-design/icons';
import UploadImage from '@/Components/UploadImage/UploadImage';
import BgPreview1 from '@/assets/bg-preview1.png';
import BgPreview2 from '@/assets/bg-preview2.png';
import { toRes } from '@/utils';
interface InitDataType {
    standyByImgUrl: string;
    orderImgUrl: string
}
export default function GuestDisplay () {
  const { i18n } = globalState;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [standyByImgUrl, setStandyByImgUrl] = useState<string>();
  const [orderImgUrl, setOrderImgUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [initData, setInitData] = useState<InitDataType>();
  const standyByImgUrlUpload = (imageUrl: any) => {
    setStandyByImgUrl(imageUrl[0].url ? imageUrl[0].url : '')
  };
  const orderImgUrlUpload = (imageUrl: any) => {
    setOrderImgUrl(imageUrl[0].url ? imageUrl[0].url : '')
  };
  const onRemoveorderImgUrl = () => {
    setOrderImgUrl('')
  }
  const onRemovestandyByImgUrl = () => {
    setStandyByImgUrl('')
  }
  const queryGuestFacingConfig = async () => {
    setLoading(true)
    const [error, data] = await toRes(ApiQueryGuestFacingConfig())
    if (!error) {
      init(data)
      setInitData({
        standyByImgUrl: data?.standyByImgUrl,
        orderImgUrl: data?.orderImgUrl,
      })
    }
    setLoading(false)
  }
  const init = (data: any) => {
    setStandyByImgUrl(data?.standyByImgUrl)
    setOrderImgUrl(data?.orderImgUrl)
  }
  const cancelEdit = () => {
    setIsEdit(false)
    init(initData)
  }
  const saveGuestFacingConfig = async () => {
    setLoading(true)

    const params = {
      configs: {
        standyByImgUrl,
        orderImgUrl
      }
    }
    const [error, data] = await toRes(ApiSaveGuestFacingConfig({ params }))
    if (!error && data) {
      message.success('success', 2)
      setIsEdit(false)
    }
    setLoading(false)
  }
  useEffect(() => {
    queryGuestFacingConfig()
  }, [])

  return (
    <Spin spinning={loading}>
      <div className="guest-display">
        <div className="top">
          <div className='title'>{i18n.t('guest_display')}</div>
          <Divider />
          <div className="btn-box" style={{ textAlign: 'right' }}>
            {
              isEdit ? <>
                <Button onClick={() => cancelEdit()} style={{ marginRight: '16px' }}>{i18n.t('cancel')}</Button>
                <Button onClick={() => saveGuestFacingConfig()} type="primary">{i18n.t('save')}</Button>
              </> :
                <Button onClick={() => setIsEdit(true)} type="primary" icon={<EditOutlined />}>{i18n.t('edit_1')}</Button>
            }
          </div>
        </div>
        <Row className='row-box'>
          <Col span={13}>
            <div className="title-2">{i18n.t('standby_image')}</div>
            <div className="text">
              <p>{i18n.t('guest_display_standby_image_desc')}<br></br>
                {i18n.t('guest_display_upload1_desc', { w: 1280, h: 800 }) + ' ' + i18n.t('guest_display_upload1_desc2', { max: 5 })}</p>
            </div>
            <div className="img">
              <div className="img-box">
                <UploadImage
                  onImageUpload={standyByImgUrlUpload}
                  onRemove={onRemovestandyByImgUrl}
                  cropWidth={1280}
                  cropHeight={800}
                  maxCount={1}
                  enableCrop
                  disabled={!isEdit}
                  fileImg={standyByImgUrl}
                />
              </div>
            </div>
          </Col>
          <Col>
            <div className="bg-preview" style={{ backgroundImage: `url(${BgPreview1})` }}>
              <div className="txt">{i18n.t('standby_image_default_text')}</div>
              {standyByImgUrl ? <img className='pre-img' src={standyByImgUrl} alt="" /> : null}
            </div>
          </Col>
        </Row>
        <Row className='row-box'>
          <Col span={13}>
            <div className="title-2">{i18n.t('order_in_progress_image')}</div>
            <div className="text">
              <p>{i18n.t('guest_display_progress_image_desc')}<br></br>
                {i18n.t('guest_display_upload1_desc', { w: 640, h: 480 }) + ' ' + i18n.t('guest_display_upload1_desc3', { a: 4, b: 5 }) + ' ' + i18n.t('guest_display_upload1_desc2', { max: 5 })}</p>
            </div>
            <div className="img-box">
              <UploadImage
                onImageUpload={orderImgUrlUpload}
                onRemove={onRemoveorderImgUrl}
                enableCrop
                cropWidth={480}
                cropHeight={680}
                maxCount={1}
                disabled={!isEdit}
                fileImg={orderImgUrl}
              />
            </div>
          </Col>
          <Col>
            <div className="bg-preview" style={{ backgroundImage: `url(${BgPreview2})` }}>
              <div className="txt" style={{ left: '73%' }}>{i18n.t('order_image_default')}</div>
              {orderImgUrl ? <img className='pre-img order-image' src={orderImgUrl} alt="" /> : null}
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  )
}

import { service } from '@/request/index'

export const batchAddDiningOption: any = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/batchAddDiningOption', { params });

export const queryAllDiningOption: any = params => service.post('rms/overseas/MerchantManagementConsole/FrontOfHouse/queryAllDiningOption', { params });

export const batchArchivedDiningOptionAction: any = params => service.post('rms/overseas/MerchantManagementConsole/FrontOfHouse/batchArchivedDiningOption', { params })

export const sortDiningOptionAction: any = params => service.post('rms/overseas/MerchantManagementConsole/FrontOfHouse/sortDiningOption', { params });

export const queryBizSwitch: any = () => service.post('/rms/overseas/MerchantManagementConsole/MerchantConfig/queryBizSwitch');

export const updateBizSwitch: any = params => service.post('/rms/overseas/MerchantManagementConsole/MerchantConfig/saveBizSwitch', params);
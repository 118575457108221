import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { Provider as BusProvider } from 'react-bus';
import { RouterProvider } from 'react-router-dom';
import router from '@/router/router';
import { fetchLatestData, getSupportLangs } from '@/utils/language';

// import routers from './router';
import { getCookie } from '@/utils';
import './App.css';
import './styles/antd-custom.scss'
import theme from '@/styles/antdTheme';
import store from '@/stores/userInfo'
import { AccountWarning } from '@/Components/AccountWarning';

function App () {
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      const checkUserInfo = () => {
        const { accountId } = store.getUserInfo || {};
        const { nodeId } = store.getShopInfo || {};
        console.log('accountId', accountId, 'nodeId', nodeId);
        if (accountId && nodeId) {
          const cookieUserInfo = `${getCookie('accId')}${getCookie('nodeId')}`;
          const storeUserInfo = `${accountId}${nodeId}`;
          return cookieUserInfo === storeUserInfo;
        }
        return true;
      };
      if (!checkUserInfo()) {
        AccountWarning();
      }
    }
  };

  useEffect(() => {
    fetchLatestData()
    getSupportLangs()
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return (
    <ConfigProvider theme={ theme } componentSize="large">
      <BusProvider>
        <div className="App">
          <RouterProvider router={router} />
        </div>
      </BusProvider>
    </ConfigProvider>
  );
}

export default App;

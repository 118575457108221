import React, { useEffect, useState } from 'react';
import { Tag, Divider, Input, Radio, Space } from 'antd'
import { IRenderConfigProps } from '../../interface';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import printOutDecorationStore from '../../store'
import './RenderRadioGroup.scss';

export default observer(function RenderRadioGroup (props: IRenderConfigProps) {
  const { content, sectionId } = props
  const { defaultValue, attr } = content;

  const [defaultValueOptions, setDefaultValueOptions] = useState<Array<any>>([])
  const [previewOptions, setPreviewOptions] = useState<Array<any>>([]);
  const [checkedValue, setCheckedValue] = useState<string>('');


  const onChange = (e) => {
    setCheckedValue(e.target.value);

    // ***** update ConfigUI *****
    // ***** get previewData *****
    let previewData = toJS(printOutDecorationStore.getPreviewData)
    previewData = previewData.map(item => {
      if (item.id === sectionId) {
        if (sectionId === 'item-info-section') {
          const checkedOption = defaultValueOptions.find(option => option.id === e.target.value);
          item.children[content.id].value = checkedOption.value;
        } else {
          // S20 会下掉这部分逻辑
          previewOptions.forEach(option => {
            if (item.children[option.id]) {
              item.children[option.id].hide = option.id === e.target.value ? false : true;
            }
          })
        }

      }
      return item;
    })

    // ***** update previewData *****
    printOutDecorationStore.savePreviewData(previewData)
  };


  useEffect(() => {
    setDefaultValueOptions(defaultValue);
  }, [defaultValue])

  useEffect(() => {
    // preview 当前值
    const previewData = toJS(printOutDecorationStore.getPreviewData);
    previewData.forEach(item => {
      if (item.id === sectionId) {
        const children = item.children;
        let arr = [];
        let checkedId = '';
        // 取出当前radio group的可选项，从previewData中筛选和defaultValueOptions存在的交集
        if (content.id === 'item-info-section-groupByItem') {
          const checkValue = item.children[content?.id]?.value
          defaultValueOptions.forEach(option => {
            option.checked = option.value === checkValue;
          });

          const checkedOption = defaultValueOptions.find(option => option.checked);
          checkedId = checkedOption ? checkedOption.id : null;
          setCheckedValue(checkedId);

        } else {
          // todo: S20 这个逻辑会整体下掉。
          arr = defaultValueOptions.reduce((result, prev) => {
            if (!!children[prev.id]) {
              result.push({
                ...children[prev.id],
                id: prev.id
              });
            }
            return result;
          }, []);
          checkedId = arr.find(fil => !fil.hide)?.['id'];
          setPreviewOptions(arr);
          setCheckedValue(checkedId);
        }
      }
    })
  }, [printOutDecorationStore.getPreviewData, sectionId])

  return (
    <div className="render-radio-group">

      {attr?.label && <div className='render-radio-group-label'>{attr.label}</div>}

      <Radio.Group
        onChange={onChange}
        value={checkedValue}
      >
        <Space direction={attr?.style.direction}>
          {defaultValueOptions.map(option => (
            <Radio key={option.id} value={option.id}>
              {option.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>


      {
        attr?.dividerBottom && <Divider />
      }
    </div>
  )
})
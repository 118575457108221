import React, { Dispatch, SetStateAction, useEffect, useState, useCallback } from 'react';
import { globalState } from '@/stores';
import { Form, Modal, Select, DatePicker, message, Button } from 'antd';
import { ApiQueryEmployeeList } from '@/request/api';
import { getToday, formatTime } from '@/utils';
import dayjs, { Dayjs } from 'dayjs';
import './addTimeSheet.scss'
import { ApiCreateTimesheet, ApiUpdateTimesheet, ApiDeleteTimesheet, ICreateTimesheet } from '@/request/EmployeeTimeSheets'
export type TimeSheetType = {
  clockIn: string
  clockOut: string
  standardClockIn: string
  standardClockOut: string
  employeeId: number
  firstName: string
  lastName: string
  hours: number
  id: number
  key: number
}
interface IProps {
  openModal: boolean
  timeSheetInfo?: TimeSheetType
  setOpenModal: Dispatch<SetStateAction<boolean>>
  handleOk?: () => void
  handleCancel?: () => void
}
const { confirm } = Modal;

const AddTimeSheet = (props: IProps) => {
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  const i18n = globalState.i18n;
  const [formInstance] = Form.useForm();
  const [employeeAllList, setEmployeeAllList] = useState([])
  const [employeeIdList, setEmployeeIdList] = useState([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [today, setToday] = useState<Dayjs>(null);
  const [formClockIn, setFormClockIn] = useState<Dayjs>(null);

  useEffect(() => {
    if (props.openModal) {
      setIsEdit(!!props.timeSheetInfo);
      initData();
    }
  }, [props.openModal])

  const fetchEmployeeList = async () => {
    const _params = { pageIndex: 1, pageSize: 99999, total: 0, status: 0 }
    const res = await ApiQueryEmployeeList(_params)
    if (res.code !== 0) return;
    const { dataList = [] } = res.data;
    setEmployeeAllList(dataList.map(x => ({ label: x.employee.fullName, value: x.employee.employeeId })))
  }

  const handleEmployeeChange = (val) => {
    console.log('val', val);
  }

  const resetData = () => {
    formInstance.resetFields();
    setFormClockIn(null);
  }

  const initData = async () => {
    const _today = await getToday();
    setToday(_today);
    fetchEmployeeList();
    if (!!props.timeSheetInfo) {
      const { clockIn, clockOut, employeeId } = props.timeSheetInfo
      const clockInTimeStamp = clockIn ? dayjs(new Date(clockIn)) : null;
      const clockOutTimeStamp = clockOut ? dayjs(new Date(clockOut)) : null;
      setFormClockIn(clockInTimeStamp);
      formInstance.setFieldsValue({
        'clockIn': clockInTimeStamp,
        'clockOut': clockOutTimeStamp,
        'employee': employeeId
      })
    }
  }

  const formatRequestParams = async () => {
    try {
      const res = await formInstance.validateFields();
      return res;
    } catch (error) {
      console.log('error', error);
      return false;
    }
  }

  const handleOk = async () => {
    const formatStr = 'YYYY-MM-DD HH:mm:ss';
    const params = await formatRequestParams();
    if (!params) {
      return;
    }
    const { clockIn, clockOut, employee } = params;
    const requestParams: ICreateTimesheet = {
      ...(isEdit ? { id: props.timeSheetInfo.id } : { employeeId: employee }),
      clockInTime: clockIn.format(formatStr),
      clockOutTime: clockOut.format(formatStr),
    }
    let res = null;
    if (isEdit) {
      res = await ApiUpdateTimesheet(requestParams)
    } else {
      res = await ApiCreateTimesheet(requestParams)
    }
    if (res.code === 0) {
      resetData();
      props.setOpenModal(false);
      props.handleOk && props.handleOk();
    }
  };

  const handleDelete = async () => {
    confirm({
      icon: <></>,
      title: `${i18n?.t('frontOfHouse_pc_delete')} ${i18n?.t('time_sheet')}`,
      content: i18n?.t('delete_the_time_sheet'),
      okText: i18n?.t('rms_overseas_transaction_order_HasRefund_YES'),
      cancelText: <>{i18n?.t('rms_overseas_transaction_order_HasRefund_NO')}</>,
      onOk: async () => {
        const res = await ApiDeleteTimesheet({ ids: [props.timeSheetInfo.id] });
        if (res.code === 0) {
          resetData();
          props.setOpenModal(false);
          props.handleOk && props.handleOk();
        }
      }
    })
  }

  const handleCancel = () => {
    resetData();
    props.setOpenModal(false);
    props.handleCancel && props.handleCancel();
  };

  const clockInDisabledDate = (current) => {
    if (today) {
      const _tomorrow = today.add(1, 'day').endOf('day');
      return current.isAfter(_tomorrow);
    }
    return true;
  };

  const clockInDisabledTime = (currentTime) => {
    const currentTimeDate = currentTime.startOf().valueOf();
    const todayDate = today.startOf('day').valueOf();
    if (today) {
      // 如果日期选择为明天那只能选四点之前的时间，今天或昨天可以选择四点之后的时间
      const _today = currentTimeDate > todayDate ? today.startOf('day') : currentTime.startOf('day');
      const _tomorrow = currentTimeDate > todayDate ? today.add(1, 'day').endOf('day') : currentTime.add(1, 'day').endOf('day');
      if (currentTime.isSame(_tomorrow, 'day')) {
        return {
          disabledHours: () => Array.from({ length: 24 }, (_, i) => (i < 4 ? null : i)).filter(i => i !== null), // 禁用4点之后
        }
      }
    }
    return {};
  }

  const clockOutDisabledDate = (current) => {
    const clockInTime = formInstance.getFieldValue('clockIn');
    if (!clockInTime) {
      return clockInDisabledDate(current);
    }
    const clockInTimeDate = clockInTime.startOf('day').valueOf();
    const todayDate = today.startOf('day').valueOf();
    const currentDate = current.startOf('day').valueOf();
    if (clockInTimeDate > todayDate) { // 如果clockIn选择的明天，那么clock out打卡时间只能选择明天
      return currentDate !== clockInTimeDate;
    } else {
      const clockInHour = clockInTime.hour();
      const _tomorrow = clockInTime.add(1, 'day').endOf('day');
      const _today = clockInTime.startOf('day');
      if (clockInHour < 4) {
        return currentDate !== clockInTimeDate;
      }
      return current.isBefore(_today) || current.isAfter(_tomorrow);
    }
  }

  const clockOutDisabledTime = (currentTime) => {
    const clockInTime = formClockIn;
    const todayDate = today.startOf('day').valueOf();
    if (!clockInTime) {
      return clockInDisabledTime(currentTime);
    }
    const currentTimeDate = currentTime.startOf('day').valueOf();
    const clockInDate = clockInTime.startOf('day').valueOf();
    const clockInHour = clockInTime.hour();
    const beforeFourClock = Array.from({ length: 24 }, (_, i) => (i < 4 ? null : i)).filter(i => i !== null); // 禁用四点及之后
    const afterFourClock = Array.from({ length: 4 }, (_, i) => i); // 禁用0点到4点
    if (currentTimeDate === clockInDate && clockInDate <= todayDate) {
      return {
        disabledHours: () => clockInHour < 4 ? beforeFourClock : afterFourClock,
      } ;
    } else {
      return {
        disabledHours: () => beforeFourClock, // 禁用4点之后
      }
    }
  }

  const dateCellRender = (current, info) => {
    if (info.type !== 'date') {
      return info.originNode;
    }
    if (typeof current === 'number' || typeof current === 'string') {
      return <div className="ant-picker-cell-inner">{current}</div>;
    }
    return (
      <div className={['ant-picker-cell-inner', current.isSame(today, 'day') ? 'ant-picker-cell-inner-today' : ''].join(' ')}>
        {current.date()}
      </div>
    );
  }

  const clockInValidate = () => {
    formInstance.validateFields(['clockIn', 'clockOut']);
  }

  const clockOutValidate = () => {
    formInstance.validateFields(['clockIn', 'clockOut']);
  }

  return (
    <div className='add-time-sheet'>
      <Modal
        open={props.openModal}
        title={i18n?.t(isEdit ? 'sales_categories_edit_x' : 'sales_categories_add_x', { msg: i18n?.t('time_sheet') })}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Cancel"
        okText="OK"
        width={700}
        className='add-time-sheet-modal'
      >
        <Form
          {...layout}
          form={formInstance}
          validateTrigger="onSubmit"
          layout="vertical"
        >
          <Form.Item
            label={
              <div>
                <span>{i18n?.t('employee')}</span>
                <span style={{ 'color': '#ff4d4f' }}> * </span>
              </div>
            }
            name="employee"
            rules={[
              () => ({
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(i18n.t('please_select_x', { msg: i18n?.t('employee') }))
                  }
                  return Promise.resolve()
                }
              }),
            ]}
          >
            <Select
              disabled={isEdit}
              className="employee-select"
              options={employeeAllList}
              value={employeeIdList}
              style={{ width: 200 }}
              allowClear
              placeholder={i18n.t('pc_employee_report_all_employee')}
              onChange={handleEmployeeChange}
              onBlur={() => { formInstance.validateFields(['employee']) }} />
          </Form.Item>

          {/* clock in */}
          <Form.Item
            label={
              <div>
                <span>{i18n?.t('timesheets_pc_clock_in')}</span>
                <span style={{ 'color': '#ff4d4f' }}> * </span>
              </div>
            }
            name="clockIn"
            rules={[
              () => ({
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(i18n.t('please_select_x', { msg: i18n?.t('timesheets_pc_clock_in') }))
                  }
                  const clockInTimeStamp = value.valueOf() || 0;
                  const clockOut = formInstance.getFieldValue('clockOut');
                  const clockOutTimeStamp = clockOut?.valueOf();
                  const duration = Math.abs(clockOutTimeStamp - clockInTimeStamp);
                  const hours24InMilliseconds = 24 * 60 * 60 * 1000;
                  if (clockInTimeStamp > clockOutTimeStamp) {
                    return Promise.reject(i18n?.t('employee_time_sheets_clock_in_time'));
                  }
                  if (duration > hours24InMilliseconds && !!clockOutTimeStamp) {
                    return Promise.reject(i18n?.t('time_sheet_cross_business_day'))
                  }
                  return Promise.resolve()
                }
              }),
            ]}
          >
            {
              today && <DatePicker
                showTime={{ format: 'hh:mm' }}
                format="MM/DD/YYYY hh:mm A"
                disabledDate={clockInDisabledDate}
                disabledTime={clockInDisabledTime}
                onBlur={clockInValidate}
                placeholder={i18n?.t('employee_time_sheets_start_time')}
                cellRender={dateCellRender}
                popupClassName="time-sheet-date-picker-popup"
                showNow={false}
                onChange={(value) => {
                  setFormClockIn(value);
                }}
              />
            }
          </Form.Item>

          {/* clock out */}
          <Form.Item
            label={
              <div>
                <span>{i18n?.t('timesheets_pc_clock_out')}</span>
                <span style={{ 'color': '#ff4d4f' }}> * </span>
              </div>
            }
            name="clockOut"
            rules={[
              () => ({
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(i18n.t('please_select_x', { msg: i18n?.t('timesheets_pc_clock_out') }))
                  }
                  const clockOutTimeStamp = value.valueOf();
                  const clockIn = formInstance.getFieldValue('clockIn');
                  const clockInTimeStamp = clockIn?.valueOf() || 0;
                  const duration = Math.abs(clockOutTimeStamp - clockInTimeStamp);
                  const hours24InMilliseconds = 24 * 60 * 60 * 1000;
                  if (clockOutTimeStamp < clockInTimeStamp) {
                    return Promise.reject(i18n?.t('employee_time_sheets_clock_out_time'));
                  }
                  if (duration > hours24InMilliseconds && !!clockInTimeStamp) {
                    return Promise.reject(i18n?.t('time_sheet_cross_business_day'));
                  }
                  const clockInValue = clockIn?.startOf('day')?.valueOf();
                  const clockOutValue = value.startOf('day').valueOf();
                  const clockInHour = clockIn?.hour();
                  const clockOutHour = value.hour();
                  const todayValue = today.startOf('day').valueOf();
                  if (clockInValue <= todayValue && (clockInHour < 4 && clockOutHour >= 4)) { // 如果clockIn是今天及之前的时间，并且clockIn四点前，clockOut四点后则判断为跨营业日
                    return Promise.reject(i18n?.t('time_sheet_cross_business_day'));
                  }
                  if ((clockOutValue > clockInValue && clockInHour < 4)) { // 如果clockOut大于clockIn，并且clockIn四点前，则clockOut也只能选四点前的时间，不允许跨天
                    return Promise.reject(i18n?.t('time_sheet_cross_business_day'));
                  }
                  return Promise.resolve()
                }
              }),
            ]}
          >
            {
              today && <DatePicker
                showTime={{ format: 'hh:mm' }}
                format="MM/DD/YYYY hh:mm A"
                disabledDate={clockOutDisabledDate}
                disabledTime={clockOutDisabledTime}
                onBlur={clockOutValidate}
                placeholder={i18n?.t('employee_time_sheets_end_time')}
                cellRender={dateCellRender}
                popupClassName="time-sheet-date-picker-popup"
                showNow={false}
              />
            }
          </Form.Item>

          {isEdit && <Form.Item>
            <Button type="primary" onClick={handleDelete}>
              {i18n?.t('frontOfHouse_pc_delete')}
            </Button>
          </Form.Item>}
        </Form>
      </Modal>
    </div>
  )
}
export default AddTimeSheet;
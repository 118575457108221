import React, { useEffect, useState } from 'react';
import { Card, Descriptions, DescriptionsProps, Spin, Table, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons'
import { globalState } from '@/stores';
import { ApiGetOrderDetail } from '@/request/api';
import { formatBackI18n, formatFullPrice, formatTimestampWithoutTimeZone, toRes } from '@/utils';
import useGetState from '@/hooks/useGetState';
import { TableEmptyContent } from '@/Components/EmptyContent';
import VerticalItems, { RenderNotes } from '../component/VerticalItems';
import './index.scss'

export default function OrderDetail (props) {
  const { i18n } = globalState;
  const { orderItem, close } = props

  const [loading, setLoading] = useState(true)
  const [detailInfo, setDetailInfo, getDetailInfo] = useGetState<any>({})
  const [itemsTableData, setItemsTableData] = useState([])
  const [paymentsTableData, setPaymentsTableData] = useState([])
  const [serviceChargeTableData, setServiceChargeTableData] = useState([])
  const [refundTableData, setRefundTableData] = useState([])
  const [orderRemark, setOrderRemark] = useState([]);
  const [orderContentLeftItems, setOrderContentLeftItems] = useState<DescriptionsProps['items']>([])
  const [orderContentRightItems, setOrderContentRightItems] = useState<DescriptionsProps['items']>([])
  const [paymentInfo, setPaymentInfo] = useState<DescriptionsProps['items']>([])
  const [posOrderCheckDetailView, setPosOrderCheckDetailView] = useState({})

  const init = async () => {
    await fetchDetail();
    initOrderContent();
  }

  useEffect(() => {
    init()
  }, []);

  const handleClose = () => {
    close();
  }

  const fetchDetail = async () => {
    const params = { params: { req: { orderCheckNo: orderItem.checkNo } } }
    const [err, data] = await toRes(ApiGetOrderDetail(params))
    setLoading(false);
    if (err) return;
    setDetailInfo(data ?? {});
    setPosOrderCheckDetailView(data.posOrderCheckDetailView)
    // 处理表格数据
    let _itemsTableData = data.posOrderCheckDetailView?.orderItemDetailViews;
    _itemsTableData = _itemsTableData.reduce((acc, parent) => {
      parent.modifiers = _itemsTableData.filter(item => item.parentUuid === parent.uuid);
      if (!parent.parentUuid) {
        acc.push(parent);
      }
      return acc;
    }, []);
    setOrderRemark(data.posOrderCheckDetailView?.orderRemarkTO || [])
    setItemsTableData(_itemsTableData)
    setPaymentsTableData(data.posOrderCheckDetailView?.orderPayViews)
    setServiceChargeTableData(data?.posOrderCheckDetailView?.checkServiceChargeList ?? [])
    const refundList = (data.refundInfo?.refundOrders ?? []).reduce((result, item) => {
      (item?.refundPayments ?? []).forEach(x => result.push(x))
      return result
    }, [])
    setRefundTableData(refundList)
  }

  const initOrderContent = () => {
    const _detailInfo = getDetailInfo();
    const detailView = _detailInfo?.posOrderCheckDetailView;
    const baseTO = _detailInfo?.posOrderBaseTO;
    setOrderContentLeftItems([
      { label: i18n.t('trans_check_no'), children: detailView?.posDisplayCheckNo ?? '-' },
      { label: i18n.t('posrn_ordershub_Dining_Option'), children: formatBackI18n(detailView?.diningOptionName, i18n) },
      { label: i18n.t('posrn_ordershub_Server'), children: detailView?.serverName ?? '-' },
    ])
    const bizTypeValue = baseTO?.orderBizType?.value;
    const bizTypeDisplayName = baseTO?.orderBizType?.displayName
    const salesChannelType = baseTO.orderType.value === '2' ? `POS - ${bizTypeValue === '2' ? `${i18n?.t('table')} ${baseTO.tableName}` : bizTypeValue === '1' ? i18n?.t('pc_product_mix_sales_quick_service') : bizTypeDisplayName}` : bizTypeDisplayName
    if (orderItem?.orderStatus.value === '400') {
      setOrderContentRightItems([
        { label: i18n.t('orders_table_orderCreated'), children: detailView?.createTime ? formatTimestampWithoutTimeZone(detailView?.createTime, { format: 'MM/DD/YYYY hh:mm:ss A' }) : '-' },
        { label: i18n.t('pc_sales_summary_sales_channel'), children: baseTO?.orderBizType ? salesChannelType : '-' },
        { label: i18n.t('orders_table_orderCanceled'), children: orderItem.standardCancelTime ? formatTimestampWithoutTimeZone(orderItem.standardCancelTime, { format: 'MM/DD/YYYY hh:mm:ss A' }) : '-' },
      ].filter(x => x))
    } else {
      setOrderContentRightItems([
        { label: i18n.t('orders_table_orderCreated'), children: detailView?.createTime ? formatTimestampWithoutTimeZone(detailView?.createTime, { format: 'MM/DD/YYYY hh:mm:ss A' }) : '-' },
        { label: i18n.t('pc_sales_summary_sales_channel'), children: baseTO?.orderBizType ? salesChannelType : '-' },
        { label: i18n.t('orders_table_orderClosed'), children: detailView?.closeTime ? formatTimestampWithoutTimeZone(detailView?.closeTime, { format: 'MM/DD/YYYY hh:mm:ss A' }) : '-' },
      ].filter(x => x))
    }
    setPaymentInfo([
      { label: i18n.t('pos_shopping_cart_footer_subtotal_title'), children: formatFullPrice(detailView?.originalSubTotal) },
      (Number(detailView?.cashDiscountAmount) !== 0) && { label: <span className="payment-info-red">{ i18n.t('pc_employee_report_tabs_cash_discount') }</span>, children: <span className="payment-info-red">{ formatFullPrice('-' + detailView?.cashDiscountAmount) }</span> },
      ((detailView?.checkServiceChargeList?.length > 0) || (+detailView?.serviceChargeAmount) > 0) && { label: i18n.t('orderDetail_pc_service_charge'), children: formatFullPrice(detailView?.serviceChargeAmount) },
      (Number(detailView?.discountAmount) !== 0) && { label: <span className="payment-info-red">{ i18n.t('pc_employee_report_tabs_discount') }</span>, children: <span className="payment-info-red">{ formatFullPrice('-' + detailView?.discountAmount) }</span> },
      {
        label: <>{i18n.t('pc_orders_Tax')} {detailView?.taxExempt && <div className='payment-info-tax-exempt'>Exempt</div>}</>,
        children: formatFullPrice(detailView?.taxTotal)
      },
      { label: i18n.t('pc_transaction_tip'), children: formatFullPrice(detailView?.tipAmount) },
      { label: i18n.t('pos_shopping_cart_footer_total_title'), children: formatFullPrice(detailView?.receivable) }
    ].filter(x => x))
  }

  const itemsTableColumns = [
    { dataIndex: 'itemName', key: 'itemName', title: i18n.t('orderDetail_menuItem'), width: 130 },
    {
      dataIndex: 'modifiers', key: 'modifiers', title: i18n.t('orderDetail_modifiers'),
      onCell: () => ({ style: { maxWidth: 300, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' } }),
      render: (_, row) => {
        const text = row?.modifiers?.length > 0 && row?.modifiers?.map(item => `${item.modifierName + ' x ' + item.quantity} (${formatFullPrice(item.actualTotal)})`).join('、');
        return text ? <Tooltip placement="topLeft" title={text}>{text}</Tooltip> : '-'
      }
    },
    { dataIndex: 'preDiscountSubTotal', key: 'preDiscountSubTotal', title: i18n.t('orderDetail_price'), render: (val) => formatFullPrice(val), width: 130 },
    { dataIndex: 'discountAmount', key: 'discountAmount', title: i18n.t('orderDetail_pc_discount'), render: (val) => formatFullPrice(val), width: 130 },
    { dataIndex: 'quantity', key: 'quantity', title: i18n.t('orderDetail_qty'), width: 130 },
    { dataIndex: 'status', key: 'status', title: i18n.t('orderDetail_pc_voided'), render: (_, row) => row?.status?.value == 4 ? 'voided' : '-', width: 130 },
    { dataIndex: 'displayActualSubTotal', key: 'displayActualSubTotal', title: i18n.t('orderDetail_net'), render: (val) => val ? formatFullPrice(val) : '-', width: 130 },
    { dataIndex: 'taxTotal', key: 'taxTotal', title: i18n.t('orderDetail_tax'), render: (val) => formatFullPrice(val), width: 130 },
    { dataIndex: 'actualTotal', key: 'actualTotal', title: i18n.t('orderDetail_total'), render: (val) => formatFullPrice(val), width: 130 },
    {
      dataIndex: 'orderRemarkTO', key: 'orderRemarkTO', title: i18n.t('orderDetail_remarks'),
      onCell: () => ({ style: { maxWidth: 300, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' } }),
      render: (_, row) => {
        const remark = row?.orderRemarkTO?.length > 0 && row?.orderRemarkTO?.[0]?.choiceRemarks?.map(item => item.name).join('、');
        const customRemark = row?.orderRemarkTO?.[0]?.customRemark;
        if (!remark && !customRemark) {
          return '-'
        }
        const remarkList = [remark, customRemark].filter(x => x)
        return <Tooltip placement="topLeft" title={remarkList.join('、')}>{remarkList.join('、')}</Tooltip>
      }
    },
    { dataIndex: 'refundQuantity', key: 'refundQuantity', title: i18n.t('orderDetail_refund_qty'), width: 130 },
    { dataIndex: 'refundTotal', key: 'refundTotal', title: i18n.t('orderDetail_pc_refund'), render: (val) => formatFullPrice(val), width: 130 },
  ]

  const paymentsTableColumns = [
    { dataIndex: 'payType', key: 'payType', title: i18n.t('orderDetail_payment_method'), render: (val) => formatBackI18n(val?.displayName, i18n) || '-' },
    { dataIndex: 'standardCreatedTime', key: 'standardCreatedTime', title: i18n.t('orderDetail_date'), render: (val) => formatTimestampWithoutTimeZone(val, { format: 'MM/DD/YYYY hh:mm:ss A' }) },
    { dataIndex: 'tipAmount', key: 'tipAmount', title: i18n.t('orderDetail_tip'), render: (val) => formatFullPrice(val) },
    { dataIndex: 'receivable', key: 'receivable', title: i18n.t('orderDetail_total'), render: (val) => formatFullPrice(val) },
    { dataIndex: 'refundAmount', key: 'refundAmount', title: i18n.t('pc_employee_report_tabs_refund'), render: (val) => formatFullPrice(val) },
  ]

  const refundTableColumns = [
    { dataIndex: 'payType', key: 'payType', title: i18n.t('orderDetail_refund_type'), render: (_, row) => formatBackI18n(row?.payType?.displayName, i18n) },
    { dataIndex: 'standardPayTime', key: 'standardPayTime', title: i18n.t('orderDetail_refund_time'), render: (val) => formatTimestampWithoutTimeZone(val) },
    { dataIndex: 'refundAmount', key: 'refundAmount', title: i18n.t('orderDetail_refund_amount'), render: (_, row) => formatFullPrice(row?.refundAmount?.slice(1)) },
    { dataIndex: 'refundReason', key: 'refundReason', title: i18n.t('orderDetail_refund_reason'), render: (_, row) => [row.refundReason, row.refundReasonComment].filter((x) => (x || '').trim()).join(', ') || '-' },
  ]

  const serviceChargeColumns = [
    { dataIndex: 'serviceChargeName', key: 'serviceChargeName', title: i18n.t('orderDetail_service_charge_name') },
    { dataIndex: 'gratuity', key: 'gratuity', title: i18n.t('orderDetail_service_charge_gratuity'), render: val => val ? i18n.t('yes') : i18n.t('no') },
    { dataIndex: 'serviceChargeAmount', key: 'serviceChargeAmount', title: i18n.t('orderDetail_service_charge_amount'), render: val => formatFullPrice(val) },
    { dataIndex: 'serviceChargeTaxAmount', key: 'serviceChargeTaxAmount', title: i18n.t('orderDetail_service_charge_tax'), render: (val, row) => row.taxed ? formatFullPrice(val) : '-' },
    { dataIndex: 'totalAmount', key: 'totalAmount', title: i18n.t('orderDetail_service_charge_total'), render: val => formatFullPrice(val) },
    { dataIndex: 'refundAmount', key: 'refundAmount', title: i18n.t('orderDetail_service_charge_refund'), render: (val) => formatFullPrice(val) },
  ]

  const Line = ()=>(
    <svg width="700" height="1">
      <line x1="0" y1="0" x2="700" y2="0" stroke="#BEBEBE" strokeWidth={ 2 } strokeDasharray="5, 5"/>
    </svg>
  )

  return (
    <Spin spinning={loading} wrapperClassName='orderReportDetail'>
      <div className='titleWrap'>
        <div className='title'>Check detail</div>
        <div className='closeWrap' onClick={handleClose}>
          <CloseOutlined />
        </div>
      </div>
      <div className='contentWrap'>
        <Card className='orderContentWrap' title={`${i18n.t('orders_table_order#')}${detailInfo?.posOrderBaseTO?.posDisplayNo ?? ''}`} bordered={true}>
          <div className='orderContentBody'>
            <div className='leftWrap'>
              <Descriptions layout="vertical" items={orderContentLeftItems} column={1} colon={false} />
            </div>
            <div className='rightWrap'>
              <Descriptions layout="vertical" items={orderContentRightItems} column={1} colon={false} />
            </div>
          </div>
        </Card>
        <Card className='orderContentWrap' title={i18n.t('posrn_pendingOrders_items')} bordered={true}>
          <VerticalItems itemsTableData={ itemsTableData } posOrderCheckDetailView={ posOrderCheckDetailView } detailInfo={ detailInfo }/>
          {!!orderRemark.length && <>
            <div style={{ marginTop: '10px' }}>
              {i18n?.t('orderDetail_remarks')}: {RenderNotes(orderRemark)}
            </div>
            <Line/></>
          }
          <Descriptions items={paymentInfo} column={1}
            contentStyle={{
              display: 'block',
              width: '100%',
              textAlign: 'right'
            }}
            labelStyle={{
              color: '#252525'
            }}
            colon={false}
            className='orderContentPaymentInfo' />
        </Card>
        <Card className='orderContentWrap' title={i18n.t('payment')} bordered={true}>
          <Table
            columns={paymentsTableColumns}
            dataSource={paymentsTableData.map((x, i) => ({ ...x, key: i }))}
            pagination={false}
            rowKey={'key'}
            scroll={{ x: 'max-content' }}
            locale={{ emptyText: TableEmptyContent }}
          />
        </Card>
        <Card className='orderContentWrap' title={i18n.t('orderDetail_pc_service_charge')} bordered={true}>
          <Table
            columns={serviceChargeColumns}
            dataSource={serviceChargeTableData.map((x, i) => ({ ...x, key: i }))}
            pagination={false}
            rowKey={'key'}
            scroll={{ x: 'max-content' }}
            locale={{ emptyText: TableEmptyContent }}
          />
        </Card>
        {refundTableData?.length > 0 && <Card className='orderContentWrap' title={i18n.t('orderDetail_pc_refund')} bordered={true}>
          <Table
            columns={refundTableColumns}
            dataSource={refundTableData.map((x, i) => ({ ...x, key: i }))}
            pagination={false}
            rowKey={'key'}
            scroll={{ x: 'max-content' }}
            locale={{ emptyText: TableEmptyContent }}
          />
        </Card>}
      </div>
    </Spin>
  )
}
export enum OperationType {
    EDIT = 0,
    ADD = 1,
}

export enum ModelType {
    ADYEN = 1,
    INGENICO = 3
}

export const ModelTypeList = [
  {
    label: 'pc_adyen',
    value: ModelType.ADYEN
  },
  {
    label: 'pc_ingenic',
    value: ModelType.INGENICO
  }
]

export enum PosType {
  STATION = 1,
  TABLET = 4
}

export const PosTypeList = [
  {
    label: 'Station',
    value: PosType.STATION
  },
  {
    label: 'Tablet',
    value: PosType.TABLET
  }
]
import React, { useEffect, useState } from 'react';
import './index.scss'
import TextLineNew from '../component/TextLineNew';
import printOutDecorationStore from '../../store';
import { toJS } from 'mobx';
import { ConfigTypeValue } from '../../interface';
interface IProps{
  sectionData: any
}
const HeaderSection = (props: any) => {
  const defaultValue = 'This is header';
  const [text, setText] = useState(defaultValue);
  const [type, setType] = useState<ConfigTypeValue>('Check');

  useEffect(() => {
    if (props && props.children) {
      setText(props.children['header-section-text']?.value || defaultValue)
    }
    setType(toJS(printOutDecorationStore.getUrlParamsType));
  }, [props])

  return (
    <div
      className='header-section'
      key={props?.id}
    >
      { text &&
      <TextLineNew
        text={text}
        style={{
          color: text === 'This is header' ? 'gray' : 'black',
          fontStyle: text === 'This is header' ? 'italic' : 'normal',
          fontSize: 'md',
          fontWeight: type === 'ZReport' || type === 'ShiftReport' ? 'bold' : 'default'
        }}
      />
      }
    </div>)
}
export default HeaderSection;
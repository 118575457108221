import { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#2563EB',
  },
  components: {
    Layout: {
      siderBg: '#0D1F2D',
      bodyBg: '#0D1F2D'
    },
    Button: {
      algorithm: true, // 启用算法
      primaryShadow: 'none',
      colorPrimaryHover: '#93abeb',
      defaultHoverColor: '#93abeb',
      colorLink: '#2563EB',
      defaultColor: '#2563EB',
      defaultBorderColor: '#2563EB',
    },
    Menu: {
      colorPrimary: '#fff',
      subMenuItemBorderRadius: 8,
      fontSize: 16,
      darkItemColor: '#ffffff',
      darkItemBg: '#0D1F2D',
      darkItemHoverBg: 'rgba(255, 255, 255, 0.07)',
      darkItemSelectedBg: '#222f3b',
      popupBg: '#FBFBFF',
      itemSelectedBg: '#EEF0FF',
      itemActiveBg: '#EEF0FF',
      itemHoverBg: '#EEF0FF',
      itemSelectedColor: '#252525',
      itemColor: '#252525'
    },
    Tabs: {
      titleFontSize: 18
    }
  },
}

export default theme;

import React, { useState, useEffect, useRef } from 'react';
import './index.scss'
import { EditOutlined } from '@ant-design/icons'
import RestaurantInfoForm from './component/RestaurantInfoForm';
import { ApiRestaurantUpdate, ApiQueryByNodeId } from '@/request/api'
import { globalState } from '@/stores';
import { message, Button } from 'antd'
export default function RestaurantInfo () {
  const i18n = globalState.i18n;
  const [editState, setEditState] = useState<boolean>(false);
  const [requestForm, setRequestForm] = useState({});
  const [formData, setFormData] = useState<IFormData>({});
  const [isValidate, setIsValidate] = useState<boolean>(true);
  const childRef = useRef<any>({});
  const handleEdit = () => {
    setEditState(!editState);
  }

  const handleCancel = () => {
    ApiQueryByNodeId({}).then(res => {
      const { data } = res;
      setRequestForm(data);
      setFormData(data);
      setEditState(!editState);
    })
  }

  const handleSave = () => {
    childRef?.current?.validateFields(['orgId', 'name', 'streetAddress', 'city', 'state', 'zipcode']).then(res => {
      ApiRestaurantUpdate(requestForm).then(res => {
        const { code, message: info } = res as any;
        code === 0 ? message.success(info) : message.error(info);
        setEditState(!editState);
      })
    })
    // if(isValidate){
    //     ApiRestaurantUpdate(requestForm).then(res => {
    //         const { code, message: info } = res as any;
    //         code === 0 ? message.success(info) : message.error(info);
    //         setEditState(!editState);
    //     })
    // } else {
    //     // message.info('检测必填项');
    // }
  }

  const onFormValidate = (validateObj) => {
    const keys = Object.keys(validateObj);
    const flag = keys.filter(key => !validateObj[key]).length === keys.length;
    setIsValidate(keys.length === 0 || flag);
  }

  useEffect(() => {
    ApiQueryByNodeId({}).then(res => {
      const { data } = res;
      setRequestForm(data);
      setFormData(data);
    })
  }, [])

  return (
    <div className="info-container">
      <span className="info-container-title">
                Restaurant
      </span>
      <div className="info-container-divider"></div>
      <div className="info-container-form">
        <div className="info-container-setting">
          <span className="typography">
            { i18n?.t('restaurantInfo_pc_restaurant_info') }
          </span>
          <div>
            {
              !editState ?
                (
                  <Button
                    size="middle"
                    icon={<EditOutlined/>}
                    type="primary"
                    onClick={ handleEdit }
                  >
                    { i18n?.t('edit_1') }
                  </Button>
                )
                :
                (
                  <>
                    <Button
                      size="middle"
                      type="primary"
                      onClick={handleCancel}
                    >
                      { i18n?.t('cancel') }
                    </Button>
                    <Button
                      size="middle"
                      type="primary"
                      style={{ marginLeft: '12px' }}
                      onClick={ handleSave }
                    >
                      { i18n?.t('save') }
                    </Button>
                  </>
                )
            }
          </div>
        </div>
        <div className="info-container-description">
          {
            !!Object.keys(formData).length && <RestaurantInfoForm
              ref={childRef}
              editState={editState}
              formData={formData}
              onSetRequestForm={setRequestForm}
              onFormValidate={onFormValidate}/>
          }
        </div>
      </div>
    </div>
  )
}
import React, { useEffect, useState } from 'react';
import printOutDecorationStore from '../store';
import { observer } from 'mobx-react';
import { toJS, autorun } from 'mobx';
import { previewComponentList } from '../mapData/previewComponentTypeMapData';
export default observer(function PreviewUIContainer () {
  // ** get store 中 previewData **
  const [previewComponents, setPreviewComponents] = useState(
    toJS(printOutDecorationStore.getPreviewData)
  );

  // 实时监听preview变化的值
  useEffect(() => {
    const dispose = autorun(() => {
      setPreviewComponents(toJS(printOutDecorationStore.getPreviewData));
    });

    return () => dispose();
  }, []);

  return (
    <>
      {previewComponents.map((item, index) => {
        const { Component } =
          previewComponentList.find((fin) => fin.type === item.id) || {};
        return (
          <div key={index}>
            {item.hide === false && Component && (
              <Component {...item}></Component>
            )}
          </div>
        );
      })}
    </>
  );
});

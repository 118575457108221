import './index.scss'
import { globalState } from '@/stores';
import { Divider, Button, Popover, Table, Modal } from 'antd';
import { PlusCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { TableEmptyContent } from '@/Components/EmptyContent';
import React, { useEffect, useState } from 'react';
import PosDrawer from './components/PosDrawer';
import { getPrintSetting, deletePosDevice } from '@/request/PosMonitor'
import { OperationType, PosTypeList } from './enum'
import { isEmpty, toRes } from '@/utils';
const { confirm } = Modal;

export default function PosMonitor (props) {
  const i18n = globalState.i18n;
  const [loading, setLoading] = useState(false);
  const [drawerLoading, setDrawerLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<OperationType>(OperationType.EDIT); // 0代表编辑 1代表新增
  const tableColumns: any = [
    {
      dataIndex: 'possn', key: 'possn', title: <>{ i18n?.t('pos_monitor_pos_sn') }</>, width: 100, align: 'center',
      render: (_, row) => <>
        { row?.posDevice?.sn }
      </>
    },
    {
      dataIndex: 'nickname', key: 'nickname', title: <>{ i18n?.t('pos_monitor_pos_nickname') }</>, width: 100, align: 'center',
      render: (_, row) => <>
        { row?.posDevice?.name }
      </>
    },
    {
      dataIndex: 'type', key: 'type', title: <>{ i18n?.t('pc_service_charge_type') }</>, width: 100, align: 'center',
      render: (_, row) => <>
        { PosTypeList.find(ele => ele.value === row?.posDevice.type).label }
      </>
    },
    {
      dataIndex: 'cardReaderSN', key: 'cardReaderSN', title: <>{ i18n?.t('pos_card_reader_sn') }</>, width: 100, align: 'center',
      render: (_, row) => <>
        { !isEmpty(row?.cardReaders) && row?.cardReaders.map(ele => ele.sn).join(',') }
      </>
    },
    {
      dataIndex: 'printer', key: 'printer', title: <>{ i18n?.t('printer') }</>, width: 100, align: 'center',
      render: (_, row) => <>
        { !isEmpty(row?.printers) && row?.printers.map(ele => ele.name).join(', ') }
      </>
    },
    {
      key: 'actions', title: i18n.t('table_actions'), width: 50, align: 'center',
      render: (_, row) => {
        return (
          <div >
            <Popover zIndex={999} content={
              <>
                <Button type="link" onClick={(event) => {
                  confirm({
                    icon: <></>,
                    title: i18n?.t('pos_monitor_delete_title'),
                    content: i18n?.t('pos_monitor_delete_context'),
                    okText: i18n?.t('pc_modal_confirm'),
                    cancelText: <>{ i18n?.t('cancel') }</>,
                    cancelButtonProps: { type: 'primary', style: { backgroundColor: '#ffffff', border: '1px solid #1d77f8', color: '#1d77f8' } },
                    onOk: () => {
                      deletePos(row);
                    }
                  })
                }}>
                  {
                    i18n?.t('frontOfHouse_pc_delete')
                  }
                </Button>
              </>
            }>
              <Button type="link" icon={<MoreOutlined />}></Button>
            </Popover>
          </div>
        )
      }
    }
  ]
  const [tableData, setTableData] = useState([]);
  const [detailInfo, setDetailInfo] = useState({});
  const [open, setOpen] = useState<boolean>(false);

  const deletePos = async (row) => {
    const [, res] = await toRes(deletePosDevice({
      deviceId: row?.posDevice?.id
    }), { showErrMsg: false })
    if (res) {
      fetchData();
    }

  }

  const edit = (row) => {
    setDetailInfo(row);
    setOpen(prevState => !prevState);
    setIsEdit(OperationType.EDIT);
  }
  const addNew = () => {
    setDetailInfo(null);
    setOpen(prevState => !prevState);
    setIsEdit(OperationType.ADD);
  }
  const onClose = () => {
    console.log('on close');
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const printListRes = await getPrintSetting()
      setTableData(printListRes?.data ?? []);
    } finally {
      setLoading(false);
    }
  }

  const reloadTable = () => {
    setOpen(prevState => !prevState);
    fetchData();
  }

  useEffect(() => {
    fetchData();
  }, [])

  return <div className="pos-monitor-content">
    <div className="title-wrap">
      <div className="title">{ i18n.t('pos_device') }</div>
      <Button type="primary" onClick={ addNew }>{ i18n?.t('pc_service_charge_add_new') }</Button>
    </div>
    <Table
      className="table-wrap"
      loading={ loading }
      rowKey={ record => record.posDevice.id }
      columns={ tableColumns }
      dataSource={ tableData }
      locale={ { emptyText: TableEmptyContent } }
      pagination={ false }
      onRow={ (record) => {
        return {
          onClick: (event: any) => {
            console.log('event', event);
            if (typeof event.target.className !== 'string') {
              return;
            }
            if (event.target.className && event.target.className.includes('ant-table-cell')) {
              edit(record);
            }
          }
        }
      } }
    />
    <PosDrawer open={ open } setOpen={ setOpen } type={ isEdit } detailInfo={ detailInfo } reloadTable={ reloadTable }/>
  </div>
}
import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Button, message, Popover } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ArchivedChange from '@/Pages/Menus/component/ArchivedChange';
import Drawer from '@/utils/GlobalDrawer';
import { TableEmptyContent } from '@/Components/EmptyContent';
import DragTable, { DragHandle } from '@/Components/DragTable';
import { arrayMove } from '@dnd-kit/sortable';
import { formatPrice, formatTimestampWithoutTimeZone } from '@/utils';
import PromotionEdit from '@/Pages/Promotion/PromotionEdit';
import { ApiBatchDiscountArchivedActivity, ApiDiscountSortActivity, ApiQueryDiscountActivityLists } from '@/request/api';
import { DISCOUNT_TYPE } from '@/Pages/Promotion/enum';
import './index.scss'
import NP from '@/utils/NP';

export default function Promotion () {
  const { i18n } = globalState;

  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [filteredInfo, setFilteredInfo] = useState({ archived: [true, false] });

  useEffect(() => {
    fetchTableData();
  }, []);

  const tableColumns = [
    { key: 'sort', align: 'center', width: 50, render: () => <DragHandle/> },
    { dataIndex: 'name', key: 'name', title: i18n.t('name') },
    {
      dataIndex: 'discountAmount', key: 'discountAmount', title: i18n.t('savings'),
      render: (_, row) => ({
        [DISCOUNT_TYPE.PERCENT]: `-${ NP.times(row.discountPercent || 0, 100) }%`,
        [DISCOUNT_TYPE.FIXED]: `-$${ formatPrice(row.discountAmount) }`,
        [DISCOUNT_TYPE.OPEN_DISCOUNT_PERCENTAGE]: i18n.t('open_price'),
        [DISCOUNT_TYPE.OPEN_DISCOUNT_FIXED_AMOUNT]: i18n.t('open_price')
      }[row?.discountType?.value])
    },
    { dataIndex: 'standardStartTime', key: 'standardStartTime', title: i18n.t('offers_start_date'), render: val => val ? formatTimestampWithoutTimeZone(val) : 'N/A' },
    { dataIndex: 'standardEndTime', key: 'standardEndTime', title: i18n.t('offers_end_date'), render: val => val ? formatTimestampWithoutTimeZone(val) : 'N/A' },
    {
      dataIndex: 'archived', key: 'archived', title: i18n.t('amount_pc_status'), width: 100,
      filters: [{ text: i18n.t('active'), value: false }, { text: i18n.t('archived'), value: true }],
      filteredValue: filteredInfo.archived || null,
      defaultFilteredValue: [true, false],
      filterResetToDefaultFilteredValue: true,
      render: (data, row) => {
        return <ArchivedChange
          data={ data }
          onChange={ (val) => handleEditStatus(val, row) }
          canEdit={ ![DISCOUNT_TYPE.OPEN_DISCOUNT_PERCENTAGE, DISCOUNT_TYPE.OPEN_DISCOUNT_FIXED_AMOUNT].includes(row.discountType.value) }
        />
      }

    },
    {
      key: 'actions', title: i18n.t('amount_pc_actions'), align: 'center', width: 100,
      render: (_, row) => {
        return (
          <Popover content={
            <div className="table-actions-wrap">
              <Button
                type="link"
                onClick={ () => handleEdit(row) }
                disabled={ row?.systemBuilt || [DISCOUNT_TYPE.OPEN_DISCOUNT_PERCENTAGE, DISCOUNT_TYPE.OPEN_DISCOUNT_FIXED_AMOUNT].includes(row.discountType.value) }
              >{ i18n.t('edit') }</Button>
            </div>
          }>
            <div className="iconfont icon-ellipsis actions-btn"/>
          </Popover>
        )
      }
    }
  ];

  const handleEdit = async (row?) => {
    Drawer.open({ component: <PromotionEdit editItem={ row } fetchTableData={ fetchTableData }/>, config: { width: 600 } });
  }

  const handleEditStatus = async (val, row) => {
    if (val === row.archived) return;
    const params = {
      uuids: [row.uuid],
      archived: val
    }
    setLoading(true)
    const resp: any = await ApiBatchDiscountArchivedActivity(params)
    if (resp.code === 0) {
      await fetchTableData();
      message.success(i18n.t('message_success'))
    }
    setLoading(false)
  }

  const handleTableChange = (_pagination, _filters) => {
    setFilteredInfo(_filters)
  }

  const fetchTableData = async () => {
    setLoading(true)
    const resp: any = await ApiQueryDiscountActivityLists();
    setLoading(false)
    if (resp.code === 0) {
      setTableData(resp.data)
    }
  }

  const onDragEnd = async ({ active, over }) => {
    if (!over) return;
    if (active.id !== over.id) {
      const sortItem = tableData.find(x => x.uuid === active.id);
      const targetSort = tableData.find(x => x.uuid === over.id).displayOrder;
      setTableData((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.uuid === active.id);
        const overIndex = prevState.findIndex((record) => record.uuid === over.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
      setLoading(true)
      const resp: any = await ApiDiscountSortActivity({ uuid: sortItem.uuid, targetSort });
      if (resp.code === 0) {
        message.success(i18n.t('message_success'));
      }
      fetchTableData();
    }
  };

  return (
    <div className="promotion-wrap">
      <div className="header">
        <div className="title">{ i18n.t('discountManage_pc_offers') }</div>
        <div className="actions">
          <Button type="primary" onClick={ () => handleEdit() }>{ i18n.t('discountManage_pc_create_new') }</Button>
        </div>
      </div>
      <div className="table-wrap">
        <DragTable
          onDragEnd={ onDragEnd }
          loading={ loading }
          rowKey={ 'uuid' }
          columns={ tableColumns as ColumnsType }
          dataSource={ tableData.filter(x => (filteredInfo.archived || []).includes(x.archived)) }
          onChange={ handleTableChange }
          pagination={ false }
          locale={ { emptyText: TableEmptyContent } }
        />
      </div>
    </div>
  )
}
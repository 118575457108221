import React, { useEffect, useState } from 'react'
import './index.scss'
import { addPrepStation, savePrepStation, queryPrepStationDetail, updatePrepStation, createPrepStation } from '@/request/PrepStations'
import { isEmpty, isStrictlyEmptyString, toRes } from '@/utils'
import { Button, Spin, Col, Row, Form, Input, Radio, Select, message, Popover, Checkbox } from 'antd'
import { globalState } from '@/stores';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useForm } from 'antd/es/form/Form'
import { GroupTypeList, PrintTypeList, PrintedReceiptKitchen, SelectOptions, PrintedReceiptTypeList, MerchantReceiptList, CustomerReceiptList, PrintType, PrintedReceiptType } from './enum'
interface IProps {
  selectedId: string, // 左侧列表当前选中的item
  isEdit: boolean, // 判断左侧列表项是点击编辑还是点击新增
  isFOH: boolean,
  handleChangeSelectedId: (id: string) => any,
  handleCancel: () => any,
  setLastSelectedId: (id: string) => any
}
export default function InfoDetail (props: IProps) {
  const i18n = globalState.i18n;
  const [infoDetail, setInfoDetail] = useState<any>({});
  const [prepStationVO, setPrepStationVO] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [infoDetailForm] = useForm();
  const [printedReceiptTypeForm] = useForm();
  const [editType, setEditType] = useState<boolean>(false);
  const [disabledInput, setDisabledInput] = useState<boolean>(false);
  const [disabledKitchen, setDisabledKitchen] = useState<boolean>(false);
  const fetchDetailData = () => {
    if (props?.selectedId) {
      getInfo(props?.selectedId);
    }
  }

  const getInfo = async (id: string) => {
    setLoading(true);
    const { data } = await queryPrepStationDetail({
      prepStationId: id
    })
    setLoading(false);
    setPrepStationVO(data);
    setDisabledInput(data.stationType === 2);
    infoDetailForm.setFieldValue('printType', data.printType);
    if (data.stationType === 1 && data.ticketStrategyDTO) {
      const { kitchenTicket } = data.ticketStrategyDTO || {};
      const state = !!kitchenTicket.kitchenTicketNum;
      // 设置kitchen的内容
      printedReceiptTypeForm.setFieldValue('selectedKitchen', [!!kitchenTicket.kitchenTicketNum ? '1' : '0'])
      printedReceiptTypeForm.setFieldValue('kitchenTicketNum', kitchenTicket.kitchenTicketNum)
      // 设置其他checkbox
      PrintedReceiptTypeList.forEach(ele => {
        printedReceiptTypeForm.setFieldValue(ele.value, [String(kitchenTicket[ele.value])])
      })
      setDisabledKitchen(state);
    } else if (data.stationType === 2 && data.ticketStrategyDTO) {
      const { merchantReceipt, customerReceipt } = data.ticketStrategyDTO || {};
      const mList = merchantReceipt.filter(f => !!f.receiptOrderNum).map(m => String(m.receiptOrderCode));
      const cList = customerReceipt.filter(f => !!f.receiptOrderNum).map(m => String(m.receiptOrderCode));
      printedReceiptTypeForm.setFieldValue('merchantReceipt', mList);
      printedReceiptTypeForm.setFieldValue('customerReceipt', cList);
    } else {
      printedReceiptTypeForm.resetFields();
      setDisabledKitchen(false);
    }
  }

  const handleEdit = () => {
    setEditType(true);
    if (props.isEdit) {
      infoDetailForm.setFieldsValue({
        name: prepStationVO?.name,
        printType: prepStationVO?.printType
      })
    }
  }

  const formatRequest = async () => {
    const [baseInfo, printedReceiptInfo] = await Promise.all([infoDetailForm.validateFields(), printedReceiptTypeForm.validateFields()]);
    let request = {}
    if (disabledInput) {
      const { customerReceipt: cList, merchantReceipt: mList } = printedReceiptInfo;
      const merchantReceipt = MerchantReceiptList.map(ele => ({
        receiptOrderNum: (mList || []).includes(ele.value) ? '1' : '0',
        receiptOrderCode: ele.value
      }))
      const customerReceipt = CustomerReceiptList.map(ele => ({
        receiptOrderNum: (cList || []).includes(ele.value) ? '1' : '0',
        receiptOrderCode: ele.value
      }))
      request = {
        ...(props.isEdit ? { id: prepStationVO.id } : {}),
        ['name']: baseInfo.name,
        ['stationType']: disabledInput ? 2 : 1,
        ticketStrategyDTO: {
          merchantReceipt,
          customerReceipt
        }
      }
    } else {
      console.log('编辑厨打');
      console.log('props.isEdit', props.isEdit);
      const voidKitchenTicketNum = printedReceiptInfo.voidKitchenTicketNum;
      const transferTicketNum = printedReceiptInfo.transferTicketNum;
      request = {
        ...(props.isEdit ? { id: prepStationVO.id } : {}),
        ['name']: baseInfo.name,
        ['printType']: baseInfo.printType,
        ['stationType']: disabledInput ? 2 : 1,
        ticketStrategyDTO: {
          kitchenTicket: {
            kitchenTicketNum: disabledKitchen ? printedReceiptInfo.kitchenTicketNum : 0,
            voidKitchenTicketNum: isEmpty(voidKitchenTicketNum) || voidKitchenTicketNum[0] === '0' ? 0 : 1,
            transferTicketNum: isEmpty(transferTicketNum) || transferTicketNum[0] === '0' ? 0 : 1
          }
        }
      }
    }
    return request;
  }

  const handleSave = async () => {
    const req = await formatRequest();
    let searchId = '';
    let state = false;
    let msgInfo = i18n?.t('message_success');
    console.log('req', req);
    if (props.isEdit) {
      const { message: msg, success } = await updatePrepStation(req);
      searchId = String(prepStationVO.id);
      msgInfo = msg;
      state = success;
    } else {
      const { data, message: msg, success } = await createPrepStation(req);
      searchId = String(data);
      msgInfo = msg;
      state = success;
    }
    if (state) {
      setEditType(false);
      props.handleChangeSelectedId(searchId);
      props.setLastSelectedId(searchId);
      message.success(msgInfo);
    }
    if (props.isEdit) {
      getInfo(searchId);
    }
  }

  const handleCancel = () => {
    setEditType(false);
    props.handleCancel();
  }

  const handleChangeSelectedKitchen = (e) => {
    setDisabledKitchen(e.target.checked)
    if (e.target.checked) {
      printedReceiptTypeForm.setFieldValue(PrintedReceiptType.PRODUCTION, 1);
    }
  }

  useEffect(() => {
    infoDetailForm.resetFields();
    const isEdit = props.isEdit;
    setDisabledInput(props.isFOH);
    if (isEdit) {
      setEditType(false);
      console.log('props.isFOH', props.isFOH);
      if (props.isFOH) {
        printedReceiptTypeForm.setFieldValue('merchantReceipt', MerchantReceiptList.map(m => m.value));
        printedReceiptTypeForm.setFieldValue('customerReceipt', CustomerReceiptList.map(m => m.value));
      }
      fetchDetailData();
    } else {
      setDisabledInput(false);
      setEditType(true);
      infoDetailForm.setFieldsValue({
        name: '',
        printType: PrintType.PRINT_SEPARATE,
      })
      printedReceiptTypeForm.resetFields();
      setDisabledKitchen(false);
    }
  }, [props.selectedId, props.isEdit, props.isFOH])

  return (
    <Spin spinning={loading}>
      <div className="info-detail">
        <div className="info-detail-head">
          <div className="info-detail-head-rightwrapper">
            {
              editType
                ? <>
                  <Button style={{ 'marginRight': '16px' }} onClick={() => {
                    handleCancel();
                  }}>{i18n?.t('cancel')}</Button>
                  <Button type="primary" onClick={() => {
                    handleSave();
                  }}>{i18n?.t('save')}</Button>
                </>
                : props?.selectedId && <Button type="link" icon={<EditOutlined />} onClick={() => {
                  handleEdit();
                }}>{i18n.t('edit_1')}</Button>
            }
          </div>
        </div>
        <div className="info-detail-body">
          <div className="info-detail-body-descriptions">
            <Form
              form={infoDetailForm}
              validateTrigger="onSubmit"
              layout="vertical"
            >
              <div className="info-detail-body-descriptions-row">
                <Form.Item
                  name="name"
                  label={<>{i18n.t('name')}</>}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator: (_, value) => {
                        if (isStrictlyEmptyString(value)) {
                          return Promise.reject(i18n?.t('frontOfHouse_pc_the_input'));
                        }
                        if (value.length > 100) {
                          return Promise.reject(i18n?.t('frontOfHouse_pc_maximum', { length: 20 }))
                        }
                        return Promise.resolve()
                      }
                    })
                  ]}>
                  {
                    !editType ? <span style={{ 'fontSize': '14px' }}>{ prepStationVO?.name }</span> ?? '-' :
                      <Input required disabled={disabledInput} onBlur={() => { infoDetailForm.validateFields() }} />
                  }
                </Form.Item>
              </div>
              {
                !disabledInput && <div className="info-detail-body-descriptions-row">
                  <Form.Item
                    name="printType"
                    label={
                      <>
                        {i18n?.t('prep_stations_print_mode')}
                        <Popover style={{ 'width': '50px' }} content={
                          <div className='lorem-line-break'>
                            {i18n?.t('prep_stations_lorem')}
                          </div>
                        } trigger="hover">
                          <ExclamationCircleOutlined style={{ 'marginLeft': '10px' }} />
                        </Popover>
                      </>
                    }>
                    <Radio.Group disabled={!editType}>
                      {
                        PrintTypeList.map(m => (
                          <Radio value={m.value} key={m.value}>{i18n?.t(m.label)}</Radio>
                        ))
                      }
                    </Radio.Group>
                  </Form.Item>
                </div>
              }
            </Form>
          </div>
          <div className="info-detail-body-descriptions">
            <div className="info-detail-body-descriptions-title">{i18n?.t('prep_stations_print_the_following_tickets')}</div>
            <div className="info-detail-body-descriptions-material">
              <Form
                form={printedReceiptTypeForm}
                validateTrigger="onSubmit"
              >
                {
                  !disabledInput ? <>
                    <div className='info-detail-body-descriptions-material-item'>
                      <Row>
                        <Col xxl={2} xl={4} md={4}>
                          <Form.Item name="selectedKitchen">
                            <Checkbox.Group>
                              <Checkbox onChange={handleChangeSelectedKitchen} disabled={!editType} value='1'>
                                {i18n?.t(PrintedReceiptKitchen.label)}
                              </Checkbox>
                            </Checkbox.Group>
                          </Form.Item>
                        </Col>
                        {
                          disabledKitchen && <Col xxl={4} xl={7} md={7}>
                            <Form.Item name={PrintedReceiptKitchen.value}>
                              <Select
                                style={{ width: 160, height: 35 }}
                                options={SelectOptions}
                                disabled={!editType}
                              />
                            </Form.Item>
                          </Col>
                        }
                        {
                          PrintedReceiptTypeList.map((ele, index) =>
                            <Col xxl={4} xl={6} md={8} key={index}>
                              <Form.Item name={ele.value}>
                                <Checkbox.Group disabled={!editType}>
                                  <Checkbox value='1'>
                                    {i18n?.t(ele.label)}
                                  </Checkbox>
                                </Checkbox.Group>
                              </Form.Item>
                            </Col>
                          )
                        }
                      </Row>
                    </div>
                  </> : <>
                    <div className='info-detail-body-descriptions-material-item'>
                      <div className='info-detail-body-descriptions-material-subtitle'>
                        {i18n?.t('prep_stations_merchant_receipt')}
                      </div>
                      <Form.Item name="merchantReceipt">
                        <Checkbox.Group>
                          <Row>
                            {
                              MerchantReceiptList.map((ele, index) =>
                                <Col xxl={3} xl={6} xs={6} key={index}>
                                  <Checkbox value={ele.value} disabled={!editType}>
                                    {i18n?.t(ele.label)}
                                  </Checkbox>
                                </Col>
                              )
                            }
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                    <div className='info-detail-body-descriptions-material-item'>
                      <div className='info-detail-body-descriptions-material-subtitle'>
                        {i18n?.t('prep_stations_customer_receipt')}
                      </div>
                      <Form.Item name="customerReceipt">
                        <Checkbox.Group>
                          <Row>
                            {
                              CustomerReceiptList.map((ele, index) =>
                                <Col xxl={3} xl={6} xs={6} key={index}>
                                  <Checkbox value={ele.value} disabled={!editType}>
                                    {i18n?.t(ele.label)}
                                  </Checkbox>
                                </Col>
                              )
                            }
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  </>
                }
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  )
}
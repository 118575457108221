import React, { useEffect, useState } from 'react';
import './index.scss'
import TextLineNew from '../component/TextLineNew';
import DashedLine from '../component/DashedLine'
interface IProps{
  text: string
}
const FooterSection = (props: any) => {
  const defaultValue = 'This is footer';
  const [text, setText] = useState(defaultValue)
  useEffect(() => {
    if (props && props.children) {
      setText(props.children['footer-section-text']?.value || defaultValue)
    }
  }, [props])
  return <div className='preview-ui-footer'>
    { text &&
      <>
        <DashedLine/>
        <TextLineNew
          text={text}
          style={{
            color: text === 'This is footer' ? 'gray' : 'black',
            fontStyle: text === 'This is footer' ? 'italic' : 'normal'
          }}
        />
      </>
    }
  </div>
}
export default FooterSection;
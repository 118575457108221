import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';

const DiningOptionsSection = () => {
  return <div className='dining-options-section'>
    <div className='dining-options-section-item'>
      <TextLineNew text='DINING OPTIONS' style={{
        fontWeight: 'bold',
      }}/>
      <TextLineNew text='Qty'/>
      <TextLineNew text='Sales'/>
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }}/>
    <div className='dining-options-section-item'>
      <TextLineNew text='Dine in'/>
      <TextLineNew text='1'/>
      <TextLineNew text='$0.00'/>
    </div>
  </div>
}
export default DiningOptionsSection;
import React, { useState, useEffect, forwardRef } from 'react';
import dayjs from 'dayjs';
import { Input, Radio, Form, TimePicker } from 'antd';
import './index.scss'
import { globalState } from '@/stores';

interface IProps {
    editState: boolean
    formData: IFormData
    onSetRequestForm: (obj: IFormData)=>void,
    onFormValidate: (obj: any)=>void,
}

export default forwardRef((props: IProps, ref: any) => {
  // let form: FormInstance;
  const i18n = globalState.i18n;
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<IFormData>({});
  const [dailyClearTime, setDailyClearTime] = useState<any>(null);
  const [manualDailyClear, setManualDailyClear] = useState<number>(0);
  const [validateList, setValidateList] = useState({});
  const layout = {
    labelCol: {
      span: 3
    },
    wrapperCol: {
      span: 14
    }
  }
  const getInitial = () => {
    form.resetFields();
    return new Promise((resolve) => {
      const obj = props.formData;
      const time = obj.dailyClearTime ? new Date(obj.dailyClearTime * 1000) : new Date()
      const str = `${time.getHours()}:${time.getMinutes()}`;
      const dailyClearTime = dayjs(str, 'HH:mm');
      setDailyClearTime(dailyClearTime);
      setFormData(obj);
      setManualDailyClear(+obj.manualDailyClear);
      resolve({
        orgId: obj.orgId,
        nodeId: obj.nodeId,
        name: obj.name,
        contactPhone: obj.contactPhone,
        streetAddress: obj.streetAddress,
        city: obj.city,
        state: obj.state,
        zipcode: obj.zipcode,
        manualDailyClear: +obj.manualDailyClear,
        dailyClearTime: dailyClearTime
      });
    })
  }
  useEffect(() => {
    getInitial().then(res => {
      form.setFieldsValue(res);
    })
  }, [props.formData])
  const handleDataChange = (obj: IFormData) => {
    setFormData(obj)
    props.onSetRequestForm(obj);
  }

  const handleFormChange = (value) => {
    const key = Object.keys(value)[0];
    handleDataChange({
      ...formData,
      [key]: value[key]
    });
  }

  const handleManualDailyClear = (e: any) => {
    const { target } = e;
    setManualDailyClear(+target.value);
    handleDataChange({
      ...formData,
      manualDailyClear: !!target.value
    });
  }

  const handleTimePickerChange = (time: any) => {
    setDailyClearTime(time);
    const timeStamp = Math.floor(+new Date(time.format()) / 1000);
    handleDataChange({
      ...formData,
      dailyClearTime: timeStamp
    });
  }

  const showTips = (message: string) => {
    return (_, v) =>  {
      if (!v) {
        const list = {
          ...validateList,
          [message]: message
        };
        setValidateList(list);
        props.onFormValidate(list);
        return Promise.reject(message);
      } else {
        const list = {
          ...validateList,
          [message]: ''
        };
        setValidateList(list);
        props.onFormValidate(list);
      }
      return Promise.resolve();
    }
  }

  const handleFormInputBlur = (form, key) => {
    form.validateFields([key]);
  }

  const starLabel = () => {
    return <>
      {
        props.editState ? <span style={{ 'color': '#ff4d4f' }}>*</span> : <></>
      }
    </>;
  }

  return (
    <div className="resturant-info-form">
      <Form
        { ...layout }
        form={form}
        // setAsyncInitialValues={async () => await getInitial()}
        onValuesChange={ handleFormChange }
        ref={ref}
      >
        <Form.Item label={
          <div>
            <span style={{ 'color': '#999' }}>{ i18n?.t('restaurantInfo_pc_merchant_id') }</span>
            { starLabel() }
          </div>
        } name="orgId"
        >
          { props.editState ? <Input style={{ 'width': 350 }} disabled={props.editState} /> : formData.orgId }
        </Form.Item>

        <Form.Item label={
          <div><span style={{ 'color': '#999' }}>{ 'Name' }</span>  { starLabel() }</div>
        } name="name"
        rules={[
          () => ({
            validator: showTips('Please enter Name')
          }),
        ]}
        >
          { props.editState ? <Input onBlur={ () => { handleFormInputBlur(form, 'name') } } style={{ 'width': 350 }} /> : formData.name || '-' }
        </Form.Item>

        <Form.Item label={
          <div> <span style={{ 'color': '#999' }}>{ i18n?.t('phone') }</span> </div>
        } name="contactPhone">
          { props.editState ? <Input onBlur={ () => { handleFormInputBlur(form, 'contactPhone') } } style={{ 'width': 350 }} /> : formData.contactPhone || '-' }
        </Form.Item>

        <Form.Item label={
          <div> <span style={{ 'color': '#999' }}>{ i18n?.t('street_address') }</span> { starLabel() }</div>
        } name="streetAddress"
        rules={[
          () => ({
            validator: showTips('Please enter Address')
          }),
        ]}
        >
          { props.editState ? <Input onBlur={ () => { handleFormInputBlur(form, 'streetAddress') } } style={{ 'width': 350 }} /> : formData.streetAddress || '-' }
        </Form.Item>

        <Form.Item label={
          <div> <span style={{ 'color': '#999' }}>{ i18n?.t('city') }</span> { starLabel() }</div>
        } name="city"
        rules={[
          () => ({
            validator: showTips('Please enter City')
          }),
        ]}
        >
          { props.editState ? <Input onBlur={ () => { handleFormInputBlur(form, 'city') } } style={{ 'width': 350 }} /> : formData.city || '-' }
        </Form.Item>

        <Form.Item label={
          <div> <span style={{ 'color': '#999' }}>{ i18n?.t('state') }</span> { starLabel() }</div>
        } name="state"
        rules={[
          () => ({
            validator: showTips('Please enter State')
          }),
        ]}
        >
          { props.editState ? <Input onBlur={ () => { handleFormInputBlur(form, 'state') } } style={{ 'width': 350 }} /> : formData.state || '-' }
        </Form.Item>

        <Form.Item label={
          <div> <span style={{ 'color': '#999' }}>{ i18n?.t('zipCode') }</span> { starLabel() }</div>
        } name="zipcode"
        rules={[
          () => ({
            validator: showTips('Please enter Zip Code')
          }),
        ]}
        >
          { props.editState ? <Input onBlur={ () => { handleFormInputBlur(form, 'zipcode') } } style={{ 'width': 350 }} /> : formData.zipcode || '-' }
        </Form.Item>
        {
          false && <Form.Item label={
            <div><span style={{ 'color': '#999' }}>{ i18n?.t('restaurantInfo_pc_settlement_methods') }</span><span style={{ 'color': '#ff4d4f' }}>*</span></div>
          } name="manualDailyClear">
            {
              props.editState ?
                <>
                  <Radio.Group onChange={ handleManualDailyClear } value={ manualDailyClear }>
                    <Radio value={0} style={{ 'marginTop': '5px' }}>
                      <span>{ i18n?.t('restaurantInfo_pc_daily_settlement_automatic') }</span>
                      <div className="resturant-info-form-manualDailyClear">{ i18n?.t('restaurantInfo_pc_daily_settlement_automatic_tips') }</div>
                    </Radio>
                    <Radio value={1}>
                      <span>{ i18n?.t('restaurantInfo_pc_daily_settlement_manual') } </span>
                      <div className="resturant-info-form-manualDailyClear">{ i18n?.t('restaurantInfo_pc_daily_settlement_manual_tips') }</div>
                    </Radio>
                  </Radio.Group>
                </> :
                <>
                  <div style={{ 'marginTop': '5px' }}>
                    { formData.manualDailyClear ? i18n?.t('restaurantInfo_pc_daily_settlement_manual') : i18n?.t('restaurantInfo_pc_daily_settlement_automatic') }
                  </div>
                  <span className="resturant-info-form-manualDailyClear">
                    { formData.manualDailyClear ? i18n?.t('restaurantInfo_pc_daily_settlement_manual_tips') : i18n?.t('restaurantInfo_pc_daily_settlement_automatic_tips') }
                  </span>
                </>
            }
          </Form.Item>
        }
        {
          false && manualDailyClear === 0 &&
                    <Form.Item label={
                      <div><span style={{ 'color': '#999' }}>{ i18n?.t('restaurantInfo_pc_settlement_time_1') }</span> <span style={{ 'color': '#ff4d4f' }}>*</span></div>
                    } name="dailyClearTime">
                      {
                        props.editState ?
                          <>
                            <TimePicker
                              style={{ 'width': 350 }}
                              placeholder=""
                              onChange={ handleTimePickerChange }
                              format="HH:mm"
                            />
                          </> :
                          <div>
                            {/* { formData?.dailyClearTime }<br/> */}
                            { formData.dailyClearTime ? dayjs(formData?.dailyClearTime * 1000).format('HH:mm') : '' }
                          </div>
                      }
                    </Form.Item>
        }
      </Form>
    </div>
  )
})

/**
 * api接口统一管理
 */
import axios from 'axios';
import { pepprService, service, toResService } from './index'

export const apiArticleEdit = params => service.post('/api/v1/articleEdit', params);
// apiArticleEdit(params).then(res => {
//     // 获取数据成功后的其他操作
//     //.....
//     console.log(res)
// })

// get day order detail
export const ApiQueryOrder = parmas => service.post('/rms/overseas/InStorePOS/OrderHub/getOrderDailyOverview', parmas);

// get employee menus list
// export const ApigetEmpEntrances = params => service.post('/rms/overseas/MerchantManagementConsole/Authentication/getEmpEntrances', params);

// export const ApiGetLoginInfo = params => service.post('/rms/overseas/MerchantManagementConsole/Authentication/getLoginInfo', params);

export const ApiChooseShop = params => service.post('/rms/overseas/MerchantManagementConsole/Authentication/chooseShop', params);

// export const ApiLogout = params => service.post('/rms/overseas/MerchantManagementConsole/Authentication/logoutByToken', params);

const prefix_org = '/api/peppr-org'
const prefix_employee = '/api/peppr-employee';
const prefix_pay = '/api/pay-batch'

export const ApiQueryByNodeId: any = params => pepprService.post(`${prefix_org}/restaurant/queryByOrgId`, params);

export const ApiRestaurantUpdate = params => pepprService.post(`${ prefix_org }/restaurant/update`, params);

// region Permission
export const ApiPermissionQueryPage = params => toResService.post(`${ prefix_employee }/permission/getPermissionList`, params);
export const ApiPermissionCategoryReferData: any = params => toResService.post(`${ prefix_employee }/category/getAllCategoryIdentity`, params);
// endregion

// region Entrances
export const ApiQueryEntrancePage = params => toResService.post(`${ prefix_employee }/entrance/getEntranceList`, params);
// endregion

// region Category
export const ApiListQueryCategory = params => toResService.post(`${ prefix_employee }/category/getCategoryList`, params);
// endregion

// region Roles
export const ApiRolesQuery = params => toResService.post(`${ prefix_employee }/tool/role/getToolRoleList`, params);
// endregion

// region SettleIn
// 获取餐厅入驻元数据
export const ApiRestaurantSettleInMetadata: any = params => toResService.post(`${ prefix_org }/restaurant/settleIn/metadata`, params);
// 餐厅入驻
export const ApiRestaurantSettleIn = params => toResService.post(`${ prefix_org }/restaurant/settleIn`, params);
// endregion

// region Employees
// Employees List
export const ApiQueryEmployeeList: any = params => toResService.post(`${ prefix_employee }/employee/queryEmployeeList`, params);
export const ApiSendCreateAccountUrl = params => toResService.post(`${ prefix_employee }/employee/${ params.employeeId }/sendCreateAccountUrl`);
export const ApiArchiveEmployee = params => toResService.post(`${ prefix_employee }/employee/${ params.employeeId }/archive`);
export const ApiUnArchiveEmployee = params => toResService.post(`${ prefix_employee }/employee/${ params.employeeId }/unarchive`);

// Jobs List
export const ApiRoleListQuery = params => toResService.post(`${ prefix_employee }/role/getRoleList`, params);
export const ApiGetRoleRelateEmployeeList = params => toResService.post(`${ prefix_employee }/role/getRoleRelateEmployeeList`, params);
export const ApiUnbindRoleRelateEmployee = params => toResService.post(`${ prefix_employee }/role/unbindRoleRelateEmployee`, params);
export const ApiRoleArchive = params => toResService.post(`${ prefix_employee }/role/archive`, params);
export const ApiRoleUnarchived = params => toResService.post(`${ prefix_employee }/role/unarchived`, params);
// endregion

// Layout Page
export const ApiGetEmployeeEntranceTree: any = params => pepprService.post(`${ prefix_employee }/entrance/getEmployeeEntranceTree`, params);


// 获取accesscode
// export const ApiAccessCode = params => service.post('/rms/overseas/MerchantManagementConsole/Employee/generateAccessCode', params)

// export const ApiJobListQuery = params => service.post('/rms/overseas/MerchantManagementConsole/Authentication/roleListQuery', params);

export const ApiServiceArea = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/listServiceAreas', params);

export const ApiQueryTableList = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryTableListByArea', params);

export const ApiAddTableInfo = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/batchAddTableInfo', params);
// Reports
// Sales Summary
export const ApiQuerySaleReport: any = params => service.post('/rms/overseas/MerchantManagementConsole/reports/querySaleReport', params);
// Payout Report
export const ApiQueryPayoutSummary = params => toResService.post(`${ prefix_pay }/payoutReport/queryPayoutSummary`, params);
export const ApiExportPayoutReport = params => toResService.post(`${ prefix_pay }/export/exportPayoutReport`, params);
export const ApiQueryExportResult = params => toResService.post(`${ prefix_pay }/export/queryExportResult`, params);
export const ApiQuerySalesDay = params => toResService.post(`${ prefix_pay }/payoutReport/querySalesDay`, params);
export const ApiQueryPayoutDetail = params => toResService.post(`${ prefix_pay }/payoutReport/queryPayoutDetail`, params);
export const ApiQueryCalendarDay = params => service.post('/rms/overseas/MerchantManagementConsole/reports/queryCalendarDay', params);


// Fee
export const ApiQueryFeeSummary = params => toResService.post('/api/pay-batch/feeReport/queryFeeSummary', params);
export const ApiPageQueryCreditCardStatementsRequest = params => toResService.post('/api/pay-batch/feeReport/pageQueryCreditCardStatementsRequest', params);
export const ApiDownloadCreditCardStatement = params => toResService.post('/api/pay-batch/feeReport/downloadCreditCardStatement', params);

// Employee Report
export const ApiQueryOperationLog = (params, config) => service.post('/rms/overseas/MerchantManagementConsole/reports/queryOperationLog', params, config);
export const ApiQueryTimesheet: any = params => toResService.post(`${ prefix_employee }/employee/queryTimesheet`, params);
// export const ApiQueryEmployeeList = params => service.post('/rms/overseas/MerchantManagementConsole/Employee/queryEmployeeList', params);
// front of house notes
export const ApiQueryRemarkVO = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryRemarkVO', params);
export const ApiChangeSwitch = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/changeSwitch', params);
export const ApiBatchSaveNotes = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/batchSaveNotes', params);
export const ApiSortNotes = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/sortNotes', params);
export const ApiRemarkEdit = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/remarkEdit', params);
export const ApiDeleteAndReorder = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/deleteAndReorder', params);
export const ApiQueryGuestFacingConfig: any = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryGuestFacingConfig', params);
export const ApiSaveGuestFacingConfig = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/saveGuestFacingConfig', params);
export const ApiQueryTipConfigVO: any = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryTipConfigVO', params);
export const ApiSaveTipConfigVO = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/saveTipConfigVO', params);

// Service Charge
export const ApiQueryServiceChargeList: any = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryServiceChargeList', params);
export const ApiQueryServiceChargeDetail: any = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryServiceChargeDetail', params);
export const ApiSaveServiceCharge: any = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/saveServiceCharge', params);
export const ApiArchiveServiceCharge = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/archiveServiceCharge', params);
export const ApiSortServiceCharge = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/sortServiceCharge', params);
export const ApiQueryTaxes: any = params => service.post('/rms/overseas/MerchantManagementConsole/Menu/queryTaxes', params);

// labor Summary
export const ApiQueryLaborReport: any = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/queryLaborReport', params);
export const ApiQueryLaborSummary: any = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/queryLaborSummary', params);
export const ApiExportLaborSummary: any = (params) => service.post('/rms/overseas/MerchantManagementConsole/reports/exportLaborSummary', params);

// upload
// ********* UploadImage *********
// 获取图片上传信息
export const ApiGetToken = async (params, appUri = 'rmsmmc',) => {
  try {
    const url = '/apaas/api/' + appUri + '/image/getToken'
    return await toResService.post(url, params)
  } catch (e) {
    return e
  }
};
// 获取原图url
export const getOriginalImage = async (params) => {
  try {
    const { url, formData } = params
    console.log('API:::', formData)
    const config = {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        'Content-Type': formData.type,
      },

    }
    return await axios.put(url, formData, config)
  } catch (e) {
    console.error(e)
    return false
  }
};

// Orders Report
export const ApiQueryAllDiningOptions: any = params => toResService.post('/apaas/api/rms/overseas/operation/configuration/frontofhouse/FrontOfHouse/queryAllDiningOptions', params);
export const ApiGetAllEmployees = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/Employee/getAllEmployees', params);
export const ApiQueryOrderReportViewList = params => toResService.post('/apaas/api/rms/overseas/InStorePOS/OrderHub/queryOrderReportViewList', params);
export const ApiGetOrderDetail = params => toResService.post('/apaas/api/rms/overseas/InStorePOS/OrderHub/getOrderDetail', params);
// Transactions Report
export const ApiQueryTransactionList = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/queryTransactionList', params);
export const ApiQueryPaymentTransactionReport = (params, config) => service.post('/rms/overseas/MerchantManagementConsole/reports/queryPaymentTransactionReport', params, config);
export const ApiExportPaymentTransactionReport = params => toResService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/exportPaymentTransactionReport', params);
export const ApiApaasQueryExportResult = params => toResService.post('/apaas/api/rms/overseas/InStorePOS/pay/queryExportResult', params);

// Payment Methods
export const ApiQueryPaymentMethodList = () => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryPaymentMethodList');
export const ApiBatchArchivedPayments = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/batchArchivedPayments', { params });
export const ApiQueryPaymentMethodByUuids: any = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryPaymentMethodByUuids', { params });
export const ApiBatchSavePayMethod = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/batchSavePayMethod', { params });
// Tax Rates
export const ApiQueryTaxesList: any = () => service.post('/rms/overseas/MerchantManagementConsole/Menu/queryTaxes');
export const ApiBatchSaveTax: any = params => service.post('/rms/overseas/MerchantManagementConsole/Menu/batchSaveTax', { params });
export const ApiBatchArchivedTax: any = params => service.post('/rms/overseas/MerchantManagementConsole/Menu/batchArchivedTax', { params });
export const ApiBatchQueryDetail: any = params => service.post('/rms/overseas/MerchantManagementConsole/Menu/batchQueryDetail', { params });
// DiscountManage
export const ApiQueryDiscountActivityLists = () => service.post('/rms/overseas/operation/configuration/discount/Promotion/queryActivityLists', { params: { req: {} } });
export const ApiDiscountSortActivity = params => service.post('/rms/overseas/operation/configuration/discount/Promotion/sortActivity', { params });
export const ApiQueryDiscountDetails: any = params => service.post('/rms/overseas/operation/configuration/discount/Promotion/queryDetails', { params });
export const ApiBatchDiscountArchivedActivity = params => service.post('/rms/overseas/operation/configuration/discount/Promotion/batchArchivedActivity', { params });
export const ApiBatchSaveDiscountActivitys = params => service.post('/rms/overseas/operation/configuration/discount/Promotion/batchSaveActivitys', { params });
// Product Mix Report
export const ApiQueryProductMixReport: any = params => service.post('/rms/overseas/MerchantManagementConsole/reports/queryProductMixReport', params);
// Cash Management
export const ApiQueryCashManagement: any = (params, config) => service.post('/rms/overseas/MerchantManagementConsole/reports/queryCashDrawerTransDetail', params, config);
export const ApiSortPaymentMethod: any = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/sortPaymentMethod', { params });

// OnlineOrders
export const ApiOnlineOrderConfigSave: any = params => service.post('/rms/overseas/MerchantManagementConsole/OnlineOrderConfig/save', params);
export const ApiOnlineOrderConfigQuery: any = () => service.post('/rms/overseas/MerchantManagementConsole/OnlineOrderConfig/query');

// Net Sales Summary
export const ApiQuerySalesCompareReport: any = params => service.post('/rms/overseas/MerchantManagementConsole/reports/querySalesCompareReport', params);

// CashDiscountsProgramReport
export const ApiQueryCashDiscountReport: any = params => service.post('/rms/overseas/MerchantManagementConsole/reports/queryCashDiscountReport', params);
export const ApiExportCashDiscountReport: any = params => service.post('/rms/overseas/MerchantManagementConsole/reports/exportCashDiscountReport', params);

import React, { useState, useEffect } from 'react';
import { Button, Modal, Tabs } from 'antd'
import printOutDecorationStore from '../store';
import { globalState } from '@/stores';
import { checkPreviewMockData } from '../mockData/checkPreviewData';
import { receiptPreviewMockData } from '../mockData/receiptPreviewData';
import './Tab.scss'
import { ConfigType, ConfigTypeValue, IPreviewSectionAttributes } from '../interface';
import { toJS } from 'mobx';



interface ITabOption {
  label: ConfigType,
  value: ConfigTypeValue,
  active: boolean
}

interface IProps {
  tabConfigType: ConfigTypeValue,
  reloadPrintConfig: (type: ConfigTypeValue) => void
}
export default function Tab (props: IProps) {
  const { i18n, history } = globalState
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  // const [activeIndex, setActiveIndex] = useState<number>(0);
  const [tabValue, setChangeTabValue] = useState<ConfigTypeValue>('Check');
  const printConfigList: Array<IPreviewSectionAttributes[]> = [checkPreviewMockData, receiptPreviewMockData];
  const [tabList, setTabList] = useState<Array<ITabOption>>([{
    label: i18n?.t('print_decoration_check'),
    value: 'Check',
    active: false,
  }, {
    label: i18n?.t('print_decoration_receipt'),
    value: 'Receipt',
    active: false,
  }, {
    label: i18n?.t('print_decoration_kitchen'),
    value: 'Kitchen',
    active: false,
  }, {
    label: i18n?.t('print_decoration_zReport'),
    value: 'ZReport',
    active: false,
  }, {
    label: i18n?.t('print_decoration_shiftReport'),
    value: 'ShiftReport',
    active: false,
  }, {
    label: i18n?.t('print_decoration_refund'),
    value: 'Refund',
    active: false,
  }])

  const handleCancel = () => {
    setIsModalOpen(false);
  }
  const updateQueryParam = (paramName, paramValue) => {
    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;
    searchParams.set(paramName, paramValue);
    window.history.pushState({}, '', currentUrl.toString());
  }
  const handleOk = () => {
    // 关闭弹窗
    setIsModalOpen(false);

    // 切换之后请求操作
    changeTabAction(tabValue)
  }

  const loadTabList = (tabValue: ConfigTypeValue) => {
    const newTabList = tabList.map((item) => ({
      ...item,
      active: item.value === tabValue,
    }));
    setTabList(newTabList);
  }

  const changeTabAction = (tabCurrentValue: ConfigTypeValue) => {
    // 存路由的tab
    printOutDecorationStore.saveUrlParamsType(tabCurrentValue)
    // 更新tab的样式
    loadTabList(tabCurrentValue);
    // 更新路由的参数
    updateQueryParam('type', tabCurrentValue);
    // 回调
    props.reloadPrintConfig(tabCurrentValue);
  }

  const onChangeTabActive = (tabValue: ConfigTypeValue) => {
    setChangeTabValue(tabValue);

    // 如果config有变更才会出这个弹窗
    if (toJS(printOutDecorationStore.getIsEditPrintOutConfig)) {
      setIsModalOpen(true);
    } else {
      changeTabAction(tabValue)
    }
  }


  useEffect(() => {
    const { tabConfigType } = props
    if (tabConfigType) {
      loadTabList(props.tabConfigType)
    }
  }, [props])

  return (
    <div className='print-out-tab'>
      {tabList.map((tab) => (
        <div
          className="tab-item"
          style={{
            color: tab.active && '#2563EB',
            background: tab.active && '#EEF0FF',
            cursor: 'pointer'
          }}
          key={tab.value}
          onClick={() => onChangeTabActive(tab.value)}
        >
          {tab.label}
        </div>
      ))}
      <Modal
        title='Unsaved Changes'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Discard'}
        cancelText={'Cancel'}>
        <p>Any changes you made will be lost.</p>
      </Modal>
    </div>
  )
}
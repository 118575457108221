import React, { useState, useEffect, forwardRef, ForwardRefRenderFunction } from 'react';
import './index.scss'
import Block from './component/Block';
import { defaultCheckMapData } from '../defaultJsonData/defaultCheck';
import { ConfigTypeValue, IBaseDefaultChildrenData, IBaseDefaultMapData, ITag } from '../interface';
import { observer } from 'mobx-react';
import { toJS, autorun } from 'mobx';
import printOutDecorationStore from '../store';
import { configDataOptions } from '../mapData/typeMapConfigDataOptions';
import { renderChildComponent } from '../mapData/configTagChildComponent';


interface ConfigUIContainerProps {}

const ConfigUIContainer: ForwardRefRenderFunction<HTMLDivElement, ConfigUIContainerProps> = (_, ref) => {
  const [configData, setConfigData] = useState<Array<IBaseDefaultMapData<ITag>>>(defaultCheckMapData)

  const renderChild = (sectionChildren: Array<IBaseDefaultChildrenData<ITag>>, sectionId?: string) => {
    return sectionChildren.map((child, index) => {
      const ChildComponent = renderChildComponent[child.tag];
      return ChildComponent ? <ChildComponent key={index} content={child} sectionId={sectionId}/> : null;
    });
  };


  // 实时监听config变化的值
  useEffect(() => {
    let typePramsInUrl: ConfigTypeValue = 'Check'
    const dispose = autorun(() => {
      typePramsInUrl = toJS(printOutDecorationStore.getUrlParamsType)
      setConfigData(configDataOptions[typePramsInUrl])
    });

    return () => dispose();
  }, []);


  return (
    <div className='config-ui-container' ref={ref}>
      {
        configData.map(sectionItem => (
          <div key={sectionItem.id}>
            {!sectionItem?.attr?.isHideConfig &&
            <Block content={sectionItem}>
              {sectionItem.children && renderChild(sectionItem.children, sectionItem.id)}
            </Block>
            }
          </div>
        ))
      }
    </div>
  )
}

export default observer(forwardRef(ConfigUIContainer))
import React, { useState, useEffect } from 'react';
import DashedLine from '../component/DashedLine';
import { ArgumentsEnum } from './enum';
import './index.scss';
import TextLineNew from '../component/TextLineNew';
import printOutDecorationStore from '../../store';
import { toJS } from 'mobx';
import { ConfigTypeValue } from '../../interface';

interface IItemInfoType {
  dishLineSpacing?: number,
  itemNotes?: string,
  groupByItem?: 'Yes' | 'No',
}

const ItemInfoSection = (props: any) => {
  const { children, sort } = props
  const [type, setType] = useState<ConfigTypeValue>('Check');
  const [itemInfo, setItemInfo] = useState<IItemInfoType>({});
  const [sortList, setSortList] = useState<Array<string[]>>([[]]);

  const topDashedLineList: Array<ConfigTypeValue> = ['Kitchen']


  useEffect(() => {
    setItemInfo({
      dishLineSpacing: children[ArgumentsEnum['ITEM-INFO-SECTION-DISHLINESPACING']]?.value,
      itemNotes: children[ArgumentsEnum['ITEM-INFO-SECTION-ITEMNOTES']]?.value,
      groupByItem: children[ArgumentsEnum['ITEM-INFO-SECTION-GROUPBYITEM']]?.value,
    })
    setType(toJS(printOutDecorationStore.getUrlParamsType));
    // 存 顺序 信息
    setSortList(sort)
  }, [props])


  const ItemNode = (props: { text: string }) => {
    return (
      <div className='item-info-comp'>
        <div className='item-info-comp-column'>
          <TextLineNew text={props.text} style={{
            fontSize: type === 'Kitchen' ? 'md' : 'sm'
          }}/>
        </div>
      </div>
    )
  }

  const ItemPrice = () => {
    return (
      <TextLineNew
        text={type === 'Kitchen' ? '' : type === 'Refund' ? '-$0.00' : '$0.00'}
        style={{
          fontWeight: type === 'Kitchen' ? 'bold' : 'default',
          fontSize: type === 'Kitchen' ? 'md' : 'sm'
        }}/>
    )
  }

  const ItemInfoWrap = ({ id, name, isMarginBottom = true }: { id: string, name: string, isMarginBottom?: boolean }) => (
    <div className='item-info-wrap' style={{ marginBottom: `${isMarginBottom ? itemInfo.dishLineSpacing : 0}px` }}>
      <div className='item-info-wrap-item'>
        <div className='item-info-wrap-item-id'>
          <TextLineNew text={id} style={{
            fontWeight: type === 'Kitchen' ? 'bold' : 'default',
            fontSize: type === 'Kitchen' ? 'md' : 'sm'
          }}/>
        </div>
        <div className='item-info-wrap-item-info'>
          <TextLineNew text={name} style={{
            fontWeight: type === 'Kitchen' ? 'bold' : 'default',
            fontSize: type === 'Kitchen' ? 'md' : 'sm'
          }}/>
        </div>
        <div className='item-info-wrap-item-price'>
          <ItemPrice />
        </div>
      </div>
    </div>
  );

  return (
    <div className='item-info' style={(topDashedLineList.includes(type)) ? { 'marginTop': '16px' } : {}}>
      {(!topDashedLineList.includes(type)) && <DashedLine />}

      {
        type === 'Kitchen' && itemInfo.groupByItem === 'Yes' &&
        <ItemInfoWrap id='2' name='Item name 1' />
      }

      {
        type === 'Kitchen' && itemInfo.groupByItem === 'No' &&
        <>
          <ItemInfoWrap id='1' name='Item name 1' />
          <ItemInfoWrap id='1' name='Item name 1' />
        </>
      }

      {
        type !== 'Kitchen' &&
        <ItemInfoWrap id='1' name='Item name 1' />
      }

      <ItemInfoWrap id='1' name='Item name 2' />
      <ItemInfoWrap id='1' name='Item name 3' isMarginBottom={false}/>

      {(sortList || []).map(([id]) => {
        // 检查id是否在list中，如果在，则渲染对应的TextLine
        if (ArgumentsEnum['ITEM-INFO-SECTION-ITEMNOTES'] === id) {
          return <ItemNode key={id} text={itemInfo.itemNotes} />;
        }
        return null;
      })}
    </div>
  )
}
export default ItemInfoSection;
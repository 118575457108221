import React, { useEffect, useState } from 'react'
import './index.scss'
import DashedLine from '../component/DashedLine';
import TextLineNew from '../component/TextLineNew';

export default function PrepStationSettingSection (props) {
  const [hideSection, setHideSection] = useState<boolean>(true);
  useEffect(() => {
    setHideSection(props['children']['prep-station-setting-section-check'].hide);
  }, [props])
  return !hideSection && <div className='prep-station-setting-section'>
    <DashedLine />
    <TextLineNew text='Other Prep Stations' style={{ fontSize: 'sm' }} />
    <DashedLine />
    <TextLineNew text='Prep station name 1' style={{ fontSize: 'sm', fontWeight: 'bold' }} />
    <TextLineNew text='2x Item name 4' style={{ fontSize: 'sm' }} />
    <TextLineNew text='Item name 5' style={{ fontSize: 'sm' }} />
  </div>
}
import React from 'react'
import TextLineNew from '../component/TextLineNew'
import './index.scss'
const TipAreaSection = () => {
  return <div className='tip-area'>
    <TextLineNew
      text='Tips'
      style={{
        fontSize: 'md',
      }}
    />
    <div className='tip-area-item'>
      {/* <div className='tip-area-item-square'></div> */}
      {/* 对勾暂时注释，后续放开 */}
      {/* <svg width='20' height='20' viewBox='0 0 20 20' className='tip-area-item-check'>
        <path d='M5 10 L8 13 L15 6' stroke='black' strokeWidth={2} fill='none' />
      </svg> */}
      <div className='tip-area-item-bracket'>[&nbsp;&nbsp;]</div>
      <TextLineNew text='15% (Tips $0.00, You pay $0.00)'/>
    </div>
    <div className='tip-area-item'>
      <div className='tip-area-item-bracket'>[&nbsp;&nbsp;]</div>
      <TextLineNew text='18% (Tips $0.00, You pay $0.00)'/>
    </div>
    <div className='tip-area-item'>
      <div className='tip-area-item-bracket'>[&nbsp;&nbsp;]</div>
      <TextLineNew text='20% (Tips $0.00, You pay $0.00)'/>
    </div>
    <div className='tip-area-item'>
      <div className='tip-area-item-bracket'>[&nbsp;&nbsp;]</div>
      <div className='tip-area-item-custom-tips'>
        <div className='tip-area-item-custom-tips-block'>
          <div className='tip-area-item-custom-tips-block-top-line'></div>
          <TextLineNew text='Custom Tips'/>
        </div>
        <div className='tip-area-item-custom-tips-block'>
          <div className='tip-area-item-custom-tips-block-top-line'></div>
          <TextLineNew text='Total'/>
        </div>
      </div>
    </div>
  </div>
}
export default TipAreaSection;
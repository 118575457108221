import { Button, Form, Input, Table, message } from 'antd';
import { SearchOutlined, CheckOutlined, ReloadOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { PlusCircleOutlined } from '@ant-design/icons'
import { TableEmptyContent } from '@/Components/EmptyContent';
import ModalEmployeeArchive from './ModalEmployeeArchive'
import React, { useEffect, useRef, useState } from 'react';
import { toRes } from '@/utils';
import { ApiQueryEmployeeList, ApiSendCreateAccountUrl } from '@/request/api';
import { ReactComponent as SearchIcon } from '@/assets/svg/search.svg'
import { ReactComponent as ArchiveIcon } from '@/assets/svg/archive.svg'
import Loading from '@/utils/loading';
import { globalState } from '@/stores';
import { useListener } from 'react-bus'
import { EDIT_EMPLOYEES } from '@/utils/BUS_TYPE';
import './index.scss'
import { useNavigate } from 'react-router-dom';
import store from '@/stores/userInfo';

export default function Employees (props) {
  const { i18n } = globalState;
  const userInfo = store.getUserInfo
  const navigate = useNavigate();
  const { curTab, tabKey } = props;

  const [form] = Form.useForm()
  const paramsRef = useRef({ keyword: '' })

  const modalEmployeeArchiveRef = useRef({ open: ({ props, success }) => '' });

  const [tableData, setTableData] = useState([])
  const [filteredInfo, setFilteredInfo] = useState({ status: null })
  const [pager, setPager] = useState({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => i18n.t('table_total_items', { num: total }),
  })

  useListener(EDIT_EMPLOYEES, () => {
    fetchTableData()
  })

  useEffect(() => {
    if (curTab !== tabKey) return;
    fetchTableData();
  }, [curTab]);

  const tableColumns = [
    {
      dataIndex: 'firstName', key: 'firstName', title: i18n.t('employeeAdd_pc_first_name'), width: 100,
      render: (_, row) => {
        const isAdmin = row.employee.tag === 1 || row.employee.tag === 2
        return (
          <div className='colFirstName'>
            { isAdmin && false && <UserOutlined/> }<span className='text'>{ row.employee.firstName }</span>
          </div>
        )
      }
    },
    { dataIndex: 'lastName', key: 'lastName', title: i18n.t('last_name'), width: 100, render: (_, row) => row.employee.lastName || '-' },
    { dataIndex: 'email', key: 'email', title: i18n.t('email_address'), width: 120, render: (_, row) => row.employee.email || '-' },
    {
      dataIndex: 'jobs', key: 'jobs', title: i18n.t('menu_Roles'), width: 50,
      render: (_, row) => {
        if (!row.employee || !row.roles) {
          return '';
        }
        if (!row.employee.tag && !row.roles.length) {
          return '-';
        }
        return row?.roles?.map(x => x?.roleName).join(',') || i18n?.t('pc_owner')
      }
    },
    {
      dataIndex: 'status', key: 'status', title: i18n.t('status'), width: 50, filteredValue: filteredInfo.status || null, filterMultiple: false,
      filters: [{ text: i18n.t('active'), value: 0 }, { text: i18n.t('archived'), value: 1 }],
      render: (_, row) => {
        const _isActive = !row?.employee?.archived
        return (<div className='colStatus'>
          <div className={ _isActive ? 'greenPoint' : 'greyPoint' }></div>
          { _isActive ? i18n.t('active') : i18n.t('archived') }
        </div>)
      }
    },
    {
      dataIndex: 'accountCreated', key: 'accountCreated', title: i18n.t('account_created'), width: 150,
      render: (_, row) => {
        return (row?.employee?.email && <div className='col-account-created'>
          { row?.employee?.isCreateAccount ? (<span><CheckOutlined/></span>) : (<Button type="link" onClick={ () => handleAccountCreatedResend(row) }><ReloadOutlined/><span className='text'>{ i18n.t('resend') }</span></Button>) }
        </div>)
      }
    },
    {
      key: 'actions', title: i18n.t('table_actions'), width: 50,
      render: (_, row) => {
        const disabledEdit = (row.employee.tag === 1 || row.employee.tag === 2) && userInfo?.tag !== 2 && userInfo?.tag !== 1;
        const disabledArchive = row.employee.tag === 1 || row.employee.tag === 2;
        const _isArchived = row?.employee?.archived
        return (<div className='colActions'>
          <Button type="link" className='btnEdit' onClick={ () => handleEdit(row) } disabled={ disabledEdit }><EditOutlined/><span className='text'>{ i18n.t('edit') }</span></Button>
          {
            _isArchived ?
              <Button type="link" onClick={ () => handleArchive(row) } disabled={ disabledArchive }><ArchiveIcon/><span className='text'>{ i18n.t('unarchived') }</span></Button> :
              <Button type="link" onClick={ () => handleArchive(row) } disabled={ disabledArchive }><i className="iconfont icon-unarchived"></i><span className='text'>{ i18n.t('archive') }</span></Button>
          }
        </div>)
      }
    },
  ]

  const fetchTableData = async ({ current, pageSize, filters }: { current?: number, pageSize?: number, filters?: object } = {}) => {
    const filterObj = Object.entries(filters ?? filteredInfo).reduce((result, [key, value]) => {
      result[key] = value?.[0];
      return result
    }, {})
    const params = {
      keyword: paramsRef.current.keyword ?? '',
      ...filterObj,
      pageIndex: current ?? pager.current,
      pageSize: pageSize ?? pager.pageSize
    }
    console.log('params', params);
    Loading.show();
    const [_, data] = await toRes(ApiQueryEmployeeList(params));
    Loading.hide();
    setTableData(data?.dataList ?? [])
    setPager((prev) => ({
      ...prev,
      pageSize: data?.pageSize ?? 10,
      pageIndex: data?.pageIndex ?? 1,
      total: data?.total ?? 0,
      current: current ?? prev.current
    }))
  }

  const handleAccountCreatedResend = async (row) => {
    Loading.show();
    const [err] = await toRes(ApiSendCreateAccountUrl({ employeeId: row.employee.employeeId }))
    if (!err) message.success(i18n.t('message_success'));
    Loading.hide();
  }

  const handleEdit = (row?) => {
    if (row) {
      navigate(`#/employeeCreate?id=${ row.employee.employeeId }&isCreateAccount=${ row?.employee.isCreateAccount }&isArchived=${ row?.employee?.archived }`);
    } else {
      navigate('#/employeeCreate');
    }
  }

  const handleArchive = (row) => {
    modalEmployeeArchiveRef.current?.open({
      props: row.employee,
      success: () => {
        fetchTableData();
      }
    });
  }

  const handleTableChange = (_pagination, filters) => {
    setFilteredInfo(filters)
    fetchTableData({ current: _pagination.current, pageSize: _pagination.pageSize, filters });
  }

  const handleSearch = () => {
    updateParamsRef();
    fetchTableData({ current: 1 });
  }

  const updateParamsRef = () => {
    paramsRef.current = form.getFieldsValue();
  }

  return (
    <div className='employeeListEmployees'>
      <div className='filterWrap'>
        <Form form={ form } layout="inline">
          <Form.Item name={ 'keyword' }>
            <Input prefix={ <SearchIcon/> } placeholder={ i18n.t('employees_search_placeholder') } className='inputName'/>
          </Form.Item>
          <Button type="primary" icon={ <SearchOutlined/> } onClick={ handleSearch }>{ i18n.t('search') }</Button>
        </Form>
        <Button type="primary" icon={ <PlusCircleOutlined/> } onClick={ () => handleEdit() }>{ i18n.t('add_new_employee') }</Button>
      </div>
      <div className='tableWrap'>
        <Table
          columns={ tableColumns }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          rowKey={'key'}
          pagination={ pager }
          scroll={ { x: 'max-content' } }
          onChange={ handleTableChange }
          locale={ { emptyText: TableEmptyContent } }
        />
      </div>
      <ModalEmployeeArchive ref={ modalEmployeeArchiveRef }/>
    </div>
  )
}
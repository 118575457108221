import { IBaseDefaultMapData, ITag } from '../interface';

export const defaultKitchenMapData: Array<IBaseDefaultMapData<ITag>> = [
  {
    'id': 'prep-station-name',
    'title': '',
    'defaultValue': false,
    'disabled': false,
    'attr': {
      'isHideConfig': true
    },
    'children': [],
  },
  {
    'id': 'service-detail-section',
    'title': '',
    'defaultValue': false,
    'disabled': false,
    'attr': {
      'isHideConfig': true
    },
    'children': [],
  },
  {
    'id': 'check-info-section',
    'title': 'print_decoration_check_info',
    'defaultValue': false,
    'disabled': true,
    'children': [{
      'tag': 'checkboxGroup',
      'defaultSort': [
        ['check-info-section-checkNumber', 'check-info-section-tableNumber'],
        ['check-info-section-serverName', 'check-info-section-guestNumber'],
        ['check-info-section-date', 'check-info-section-salesChannel'],
      ],
      'defaultValue': [
        {
          'id': 'check-info-section-salesChannel',
          'label': 'Sales Channel',
          'value': 'check-info-section-salesChannel',
          'checked': true,
          'disabled': false,
        },
        {
          'id': 'check-info-section-serverName',
          'label': 'Server name',
          'value': 'check-info-section-serverName',
          'checked': true,
          'disabled': false,
        },
        {
          'id': 'check-info-section-guestNumber',
          'label': 'Guest Number',
          'value': 'check-info-section-guestNumber',
          'checked': true,
          'disabled': false,
        }
      ],
    }]
  },
  {
    'id': 'guest-info-section',
    'title': 'print_decoration_guest_info',
    'defaultValue': false,
    'disabled': false,
    'children': [{
      'tag': 'checkboxGroup',
      'defaultSort': [
        ['guest-info-section-guestName', 'guest-info-section-guestPhoneNumber'],
        ['guest-info-section-guestNotes']
      ],
      'defaultValue': [
        {
          'id': 'guest-info-section-guestName',
          'label': 'Guest Name',
          'value': 'guest-info-section-guestName',
          'checked': true,
          'disabled': false,
        },
        {
          'id': 'guest-info-section-guestPhoneNumber',
          'label': 'Guest phone number',
          'value': 'guest-info-section-guestPhoneNumber',
          'checked': true,
          'disabled': false,
        },
        {
          'id': 'guest-info-section-guestNotes',
          'label': 'Guest Notes',
          'value': 'guest-info-section-guestNotes',
          'checked': true,
          'disabled': false,
        },
      ]
    }]
  },
  {
    'id': 'item-info-section',
    'title': 'print_decoration_item_info',
    'defaultValue': false,
    'disabled': true,
    'children': [
      {
        'tag': 'radioGroup',
        'id': 'item-info-section-groupByItem',
        'hide': false,
        'attr': {
          'label': 'Group by Item',
          'style': {
            'direction': 'vertical'
          },
          'dividerBottom': true,
        },
        'defaultValue': [{
          'id': 'item-info-section-groupByItem-yes',
          'label': 'Yes',
          'value': 'Yes',
          'checked': false,
          'disabled': false,
        },
        {
          'id': 'item-info-section-groupByItem-no',
          'label': 'No',
          'value': 'No',
          'checked': true,
          'disabled': false,
        }]
      },
      {
        'tag': 'spaceTag',
        'defaultValue': [{
          'id': 'item-info-section-narrow',
          'label': 'Narrow',
          'value': '5',
          'checked': false,
          'disabled': false
        },
        {
          'id': 'item-info-section-mid',
          'label': 'Mid',
          'value': '15',
          'checked': true,
          'disabled': false,
        },
        {
          'id': 'item-info-section-wide',
          'label': 'Wide',
          'value': '30',
          'checked': false,
          'disabled': false,
        }],
      },
      {
        'tag': 'checkboxGroup',
        'defaultSort': [['item-info-section-itemNotes']],
        'defaultValue': [{
          'id': 'item-info-section-itemNotes',
          'label': 'Item notes',
          'value': 'item-info-section-itemNotes',
          'disabled': false,
        }],
      }
    ]
  },
  {
    'id': 'check-notes-section',
    'title': 'print_decoration_check_notes',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'prep-station-setting-section',
    'title': 'print_decoration_prep_station_setting',
    'defaultValue': false,
    'disabled': true,
    'children': [{
      'tag': 'radioGroup',
      'attr': {
        'style': {
          'direction': 'vertical'
        },
        'dividerBottom': false
      },
      'defaultValue': [{
        'id': 'prep-station-setting-section-check',
        'label': 'List other prep station item(s) on ticket.',
        'value': 'prep-station-setting-section-check',
        'checked': false,
        'disabled': false,
      },
      {
        'id': 'prep-station-setting-section-uncheck',
        'label': 'Do not list other prep station item(s) on ticket.',
        'value': 'prep-station-setting-section-uncheck',
        'checked': true,
        'disabled': false,
      }]
    }]
  }
]
import React, { FC, useState } from 'react'
import './index.scss';
import { globalState } from '@/stores';
import { EyeInvisibleOutlined } from '@ant-design/icons'
import { ReactComponent as EyeTwoTone } from '@/assets/svg/EyeTwoTone.svg'
import loginLogo from '@/assets/svg/loginLogo.svg';
import { Input, Button, Form, FormInstance } from 'antd';
import { login, ILoginParams, ILoginRes, switchOrg } from '@/request/Login/index'
import { ShopList } from '../ShopList';
import { checkEmail } from '@/utils/index'
import { useI18n } from '@/hooks/useI18n'
export interface SignUpComponentProps {
  info: string;
}
export const LoginCommponent: FC = () => {
  const { i18n } = useI18n();
  const [step, setStep] = useState<number>(0);
  const [shopInfo, setShopInfo] = useState<ILoginRes | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const layout = {
    labelCol: {
      span: 3
    },
    wrapperCol: {
      span: 14
    }
  }
  let [form] = Form.useForm();
  const handleLogin = async () => {
    sessionStorage.removeItem('loginToken');
    sessionStorage.removeItem('accId');
    const params = await form.validateFields(['email', 'password']) as ILoginParams;
    setLoading(true);
    const r = await login({
      email: params.email,
      password: params.password
    })
    if (!r) {
      setLoading(false);
      return;
    }
    sessionStorage.setItem('loginToken', r.token);
    sessionStorage.setItem('accId', '' + r.accountId);
    if (!!r && r.orgList.length > 1) {
      setShopInfo(r);
      setStep(step + 1);
    } else {
      const orgInfo = r?.orgList[0];
      const tenantId = orgInfo?.tenantId;
      const orgId = orgInfo?.orgId;
      sessionStorage.setItem('tenantId', String(tenantId));
      sessionStorage.setItem('nodeId', String(orgId));
      const res = await switchOrg({
        tenantId: tenantId,
        orgId: orgId
      })
      res && setTimeout(() => {
        window.location.replace('/');
      })
    }
    setLoading(false);
  }
  return !step ? <div className="login-comp">
    <div className="login-comp-logo">
      <div style={{ 'width': '166px', 'height': '48px' }}>
        {/* <img src="https://overseasbucket.s3.us-west-1.amazonaws.com/image/PC/peppr_red_logo.png" /> */}
        <img src={loginLogo} />
      </div>
    </div>
    <div className="mterial-form">
      <Form
        {...layout}
        form={form}
        ref={(ref: FormInstance) => {
          form = ref;
        }}
        initialValues={{
          email: '',
          password: '',
        }}
        validateTrigger="onSubmit"
      >
        <Form.Item
          name="email"
          rules={[
            () => ({
              validator: (_, value) => checkEmail(value, i18n),
            }),
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          required
          rules={[
            () => ({
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject(i18n.t('please_enter_x', { msg: i18n.t('password') }));
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input.Password required placeholder={i18n?.t('password')} iconRender={(visible: any) => visible ? <EyeTwoTone style={{ 'color': 'black' }} /> : <EyeInvisibleOutlined style={{ 'color': 'black' }} />} />
        </Form.Item>
      </Form>
      <Button type="primary" style={{ 'borderRadius': '4px', 'marginTop': '12px', 'width': '350px', 'height': '48px' }} onClick={handleLogin} loading={loading}>
        {i18n?.t('login_pc_sign_in')}
      </Button>
      <div style={{ 'textAlign': 'right', 'marginTop': '12px' }}>
        <Button type="link" style={{ 'paddingRight': '0px' }} onClick={() => {
          window.location.replace('/forgetPassword');
        }}>
          {i18n?.t('login_pc_forgot_password')}
        </Button>
      </div>
    </div>
  </div> : <ShopList orgList={shopInfo?.orgList || []} />
}
import React, { useEffect, useRef, useState } from 'react';
import { useBus } from 'react-bus';
import { Layout, Menu, Popover, MenuProps, Flex } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import './index.scss';
import Logo from './Logo'

// @assets
import '@/assets/MenuIcon/icon.js';

// @request
import { ApiLogout } from '@/request/Login/index'
import { getLoginInfo, switchOrg } from '@/request/Login/index'
import { ApiGetEmployeeEntranceTree } from '@/request/api';

// @stores
import { globalState } from '@/stores';
import store from '@/stores/userInfo'

// @utils
import { parseHash } from '@/utils';
import GlobalDrawer from '@/utils/GlobalDrawer';
import { _i18n, setCurrentLang, getCurrentLang, getSupportLangs } from '@/utils/language';
// @router
import { routes } from '@/router/routes';
import { useNavigate, Outlet } from 'react-router-dom';

import UserActions from './UserActions';
const { Header, Sider, Content } = Layout;
let employeeData = null;
let empInfoData = null;

type MenuItem = Required<MenuProps | any>['items'][number];

type Language = {
  code: string;
  locale: string;
}

function getItem (
  label: string,
  key: string,
  id?: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    id,
    children,
    label,
    type,
    theme: 'light',
  } as MenuItem;
}
// @withRouter
export default function LayoutCom (props: any) {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [employeeMenus, setEmployeeMenus] = useState<any>(employeeData);
  const [curOrgInfo, setCurOrgInfo] = useState<any>({});
  const [orgList, setOrgList] = useState<any>([]);
  const [path, setPath] = useState<string>(window.location.pathname);
  const [languageList, setLanguageList] = useState<Language[]>([]);
  const [language, setLanguageItem] = useState<any>({});
  const [isLanguage, setIsLanguage] = useState<boolean>(true);

  const [PageList, setPageList] = useState<any>();
  const bus = useBus();
  const employeeMenusRes = []


  const toggle = () => {
    setCollapsed(!collapsed)
    goLink({ item: '', key: path, keyPath: '', domEvent: '' })
  };

  const handleMenu = (key, pageList) => {
    if (!pageList) return [];

    const _data = JSON.parse(JSON.stringify(pageList));
    const menuList: MenuItem[] = [];

    const findOrCreateParent = (parentId, label, id, icon) => {
      let parent = menuList.find(x => x.id === parentId);
      if (!parent) {
        parent = getItem(label, id, id, icon, []);
        menuList.push(parent);
      } else if (!parent.children) {
        parent.children = [];
      }
      return parent;
    };

    _data.forEach((_menuItem: any) => {
      const key = _menuItem.url;
      const label = globalState.i18n?.t(_menuItem.name) ?? '';
      const id = _menuItem.id;
      const icon = <i className={classNames('iconfont', key === _menuItem.url ? (_menuItem.icon + ' fill' ?? 'icon-home fill') : (_menuItem.icon ?? 'icon-home'))} aria-hidden="true"></i>;

      if (!_menuItem.parentId) {
        menuList.push(getItem(label, key, id, icon));
      } else {
        const parent = findOrCreateParent(_menuItem.parentId, label, id, icon);
        parent.children.push(getItem(label, key, id));
      }
    });

    return menuList;
  }

  const goLink = (options: { item: string; key: string; keyPath: string; domEvent: string;}) => {
    setPath(options.key)
    navigate(options.key)
    if (!!options.key && !!PageList) {
      setEmployeeMenus(handleMenu(options.key, PageList));
    }
  }


  const goToMyAccount = () => {
    // 跳转至账号页面
    navigate('/myAccount')
  }

  const goToLogout = () => {
    // 退出登录
    employeeData = null;
    empInfoData = null;
    ApiLogout().then(res => {
      sessionStorage.removeItem('accId');
      sessionStorage.removeItem('tenantId');
      sessionStorage.removeItem('loginToken');
      sessionStorage.removeItem('nodeId');
      // localStorage.removeItem('userInfo');
      localStorage.removeItem('shopInfo');
      store.clearUserInfo()
      store.clearShopInfo()
      res && window.location.assign(window.location.origin + '/login')
    })
  }

  const queryShopInfo = async (orgId: any, tenantId: any) => {
    console.log(orgId)
    const res = await switchOrg({
      tenantId,
      orgId
    })
    if (res) {
      sessionStorage.setItem('tenantId', tenantId)
      sessionStorage.setItem('nodeId', orgId)
      res && window.location.assign(window.location.origin)
    }
  }

  const setLanguage = (code: any) => {
    setLanguageItem(code);
    // setLocale(code.code);
    setCurrentLang(code.locale)
    setIsLanguage(true);
    setTimeout(() => {
      document.location.reload()
    }, 300)
  }
  const findRouteItem = (path) => {
    for (const route of routes) {
      if (route.path === path) return route;
      if (route.children) {
        for (const child of route.children) {
          if (child.path === path) return child;
        }
      }
    }
    return null;
  };
  const handleRouteChangeDrawerControl = () => {
    const hash = window.location.hash;
    if (!hash && GlobalDrawer.drawerContainer) {
      GlobalDrawer.close();
      return;
    }
    const { route, params } = parseHash(hash)
    const matchRoute = findRouteItem(route);
    // routers.ts 文件
    // const matchRoutes = routers.find(x => x.url === route);
    if (!matchRoute) return;
    GlobalDrawer.open({ component: matchRoute.element, props: { ...params, bus }, navigate })
  }
  const flattenMenus = (arr) => {
    (Array.isArray(arr) ? arr : []).forEach(item => {
      employeeMenusRes.push(item);
      if (item.subEntrances && item.subEntrances.length > 0) {
        flattenMenus(item.subEntrances);
      }
    });
  }
  const getUserInfo = async () => {
    const res = await getLoginInfo()
    if (res) {
      const { curOrgInfo, empInfo, orgList  } = res;
      const { timeZone } = curOrgInfo
      const shopInfo = {
        timeZoneId : timeZone ?? 'America/Los_Angeles',
        createdTime: empInfo?.createTime,
        name: curOrgInfo?.orgName,
        modifiedTime: empInfo?.modifiedTime,
        nodeId: curOrgInfo?.orgId,
        ...curOrgInfo
      }
      setCurOrgInfo(shopInfo);
      setOrgList(orgList);
      empInfoData = empInfo;
      // globalState.userInfo = empInfo;
      // globalState.shopInfo = shopInfo;
      // localStorage.setItem('userInfo', JSON.stringify(empInfo))
      store.setUserInfo(empInfo)
      localStorage.setItem('shopInfo', JSON.stringify(shopInfo))
      store.setShopInfo(shopInfo)
    }
  }

  const intervalRef = useRef<number>();
  const numRef = useRef<number>(0);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (numRef.current >= 20) {
        clearInterval(intervalRef.current);
      }
      const settings = localStorage.getItem('i18n/supportLangs');
      numRef.current++;
      if (!!settings) {
        clearInterval(intervalRef.current);
        globalState.i18n = _i18n
      }
    }, 100) as unknown as number
    return () => {
      clearInterval(intervalRef.current);
    }
  }, [])

  useEffect(() => {
    if ((!employeeData && !empInfoData) || isLanguage) {
      // 用户点击刷新时候进行重新获取数据
      setIsLanguage(false);
      globalState.i18n = _i18n
      ApiGetEmployeeEntranceTree().then(res => {
        if (res?.data && res?.data?.length > 0) {
          employeeMenusRes.length = 0;
          flattenMenus(res.data)
          const _data = JSON.parse(JSON.stringify(employeeMenusRes));
          setEmployeeMenus(handleMenu(path, employeeMenusRes));
          setPageList(_data);
          localStorage.setItem('employeeMenusRes', JSON.stringify(_data))
          employeeData = handleMenu(path, employeeMenusRes);
        }
      })
      getUserInfo()
    }

  }, [path, language])

  useEffect(() => {
    dealWithLanguage()
    // globalState.history = navigate
  }, [window.location.pathname])

  const dealWithLanguage = async ()=>{
    setPath(window.location.pathname)
    // 加载语言包
    const languageList = await getSupportLangs()
    setLanguageList(languageList);
    setLanguageItem(languageList.find((x) => x.locale === getCurrentLang()));
    if (getCurrentLang()) {
      globalState.i18n = _i18n
    }
  }

  useEffect(() => {
    handleRouteChangeDrawerControl();
  }, [window.location.hash]);


  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Logo collapsed={collapsed} onClick={() => {navigate('/')}} />
        <Menu
          mode="vertical"
          defaultSelectedKeys={['/']}
          selectedKeys={[path]}
          // @ts-expect-error golink
          onClick={goLink}
          theme="dark"
          items={employeeMenus} />
        <div className="MenuUnfoldOutlined" onClick={toggle}>
          {collapsed ? (<MenuUnfoldOutlined />) :
            (<MenuFoldOutlined />)
          }
        </div>
      </Sider>
      <Layout className="cardContent">
        <Header style={{ background: '#fff', padding:'16px 0', lineHeight: '32px', boxShadow: '0 0 10px rgba(0,0,0,0.08)' }}>
          <Flex justify='space-between'>
            {
              orgList?.length > 1 ?
                <Popover content={
                  (
                    <div className="menuList">
                      {
                        orgList?.map((x) => {
                          return <div className="menuListBox" key={x.orgId} onClick={() => { queryShopInfo(x.orgId, x.tenantId) }}>{x.orgName}</div>
                        })
                      }
                    </div>
                  )
                }
                placement="bottomLeft" trigger="click" arrow={false}>
                  <div style={{ margin: '0 4px', cursor: 'pointer' }} className="home-name">{curOrgInfo?.orgName} <i className="iconfont icon-DownOutlined"></i></div>
                </Popover> : <span style={{ margin: '0 4px', display: orgList?.length <= 1 ? 'block' : 'none' }} className="home-name">{curOrgInfo?.orgName}</span>
            }
            <div className="header-userName">
              <a href='https://help.peppr.com/userguide/?l=en' target="_blank" rel="noopener noreferrer" style={{ color: '#252525' }}>Help</a>
              <Popover content={(<div>
                {
                  languageList.length && languageList?.map((x, i) => {
                    return <div className="menuListBox" key={i} onClick={(e) => { e.stopPropagation(); setLanguage(x) }}>
                      {
                        x?.code?.includes('zh') ? '简体中文' : 'English (US)'
                      }
                    </div>
                  })
                }
              </div>)}
              placement="bottomRight" trigger="click" arrow={false}>
                <span style={{ margin: '0 4px', cursor: 'pointer' }}>
                  {
                    language?.code?.includes('zh') ? '简体中文' : 'English (US)'
                  }
                </span>
                <i className="iconfont icon-DownOutlined"></i>
              </Popover>
              <UserActions onMyAccount={goToMyAccount} onLogout={goToLogout} i18n={globalState.i18n} />
            </div>
          </Flex>
        </Header>
        <Content
          style={{
            margin: '10px 0 14px 0',
            padding: 24,
            background: '#fff',
            minHeight: 280,
          }}
        >
          {!!globalState.i18n && <Outlet />}
        </Content>
      </Layout>
    </Layout>
  )
}

import React, { useEffect, useState } from 'react';
import './index.scss'
import TextLineNew from '../component/TextLineNew';
import printOutDecorationStore from '../../store';
import { toJS } from 'mobx';
interface IProps{
  text: string
}
const CheckNotesSection = (props: any) => {
  const [text, setText] = useState('')
  const [type, setType] = useState<string>('');

  useEffect(() => {
    setType(toJS(printOutDecorationStore.getUrlParamsType));
    if (props && props.children) {
      setText(props.children['check-section-text']?.value || '')
    }
  }, [props])
  return <div className='preview-ui-check-notes'>
    <TextLineNew text={text} style={{
      fontSize: type === 'Kitchen' ? 'md' : 'sm',
      fontWeight: type === 'Kitchen' ? 'bold' : 'default'
    }}/>
  </div>
}
export default CheckNotesSection;
import './index.scss'
import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import { Button, Input, Select, Form, Spin } from 'antd';
import { getActiveRoles } from '@/request/Role';
import { isEmpty, verifyEmail } from '@/utils/index'
import { createEmployee, editEmployee, getAccessCode, getEmployeeDetail, ICreateEmployee, IEmployeeDetail, IEmployee } from '@/request/EmployeeCreate'
import { message, Checkbox, InputNumber } from 'antd';
import { EDIT_EMPLOYEES } from '@/utils/BUS_TYPE';
const { Option } = Select

export default function EmployeeCreate (props) {
  const [employeeForm] = Form.useForm();
  const [jobForm] = Form.useForm();
  const [jobList, setJobList] = useState([]);
  // const [selectJobList, setSelectJobList] = useState([]);
  const [employeeDetail, setEmployeeDetail] = useState<IEmployeeDetail>();
  const [dashboardAccess, setDashboardAccess] = useState<boolean>(false);
  const [] = useState<any>({});
  const i18n = globalState.i18n;
  const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 4 },
  };

  const getEmployeeFormInitial = async () => {
    let obj = {};
    if (!!props.id) {
      const detail = await getEmployeeDetail(props.id) || {};
      setDashboardAccess(!!detail?.employee?.dashboardAccess);
      setEmployeeDetail(detail);
      const { employee, roles } = detail;
      obj = {
        employee,
        roles
      }
    }
    return obj;
  }

  const handleCreateAccessCode = async () => {
    const data = await getAccessCode();
    jobForm.setFieldValue('accessCode', data);
  }

  useEffect(() => {
    getActiveRoles().then(res => {
      const jobList = res.map(m => (
        {
          label: m.roleName,
          id: m.roleId
        }
      ))
      setJobList(jobList);
    })
    if (props.id) {
      getEmployeeFormInitial().then((res: any) => {
        const { employee, roles } = res;
        console.log('employee, roles', employee, roles);
        employeeForm.setFieldsValue(employee)
        if (employee.tag === 1 || employee.tag === 2) {
          setJobList([
            ...jobList,
            ...roles.map(m => ({
              label: m?.roleName,
              id: m?.roleId
            }))
          ])
        }
        jobForm.setFieldValue('accessCode', employee.accessCode)
      })
    }
  }, [])

  useEffect(() => {
    employeeDetail?.roles?.map(m => m.roleId)
    jobForm.setFieldValue('roles', employeeDetail?.roles?.map(m => m.roleId));
    jobForm.setFieldValue('dashboardAccess', !!employeeDetail?.employee?.dashboardAccess);
    jobForm.setFieldValue('hourlyRate', employeeDetail?.employee?.hourlyRate)
  }, [
    jobForm,
    employeeDetail
  ])

  const handleCancel = () => {
    props.close();
  }

  const formatRequestParams = async () => {
    const promiseArr = [
      employeeForm.validateFields(['firstName', 'lastName', 'phoneNumber', 'email'])
    ]
    if (isEmpty(employeeDetail) || employeeDetail.employee.tag !== 2 && !!props.id) {
      promiseArr.push(jobForm.validateFields(['accessCode', 'roles']));
    } else {
      promiseArr.push(jobForm.validateFields(['accessCode']));
    }
    try {
      const params = await Promise.all(promiseArr)
      return params;
    } catch (err) {
      return false;
    }
  }

  const handleSave = async () => {
    const params = await formatRequestParams();
    if (!params) {
      return;
    }
    const createParams = {
      employee: {
        employeeId: props.id,
        ...params[0],
        accessCode: params[1].accessCode,
        dashboardAccess: +dashboardAccess,
        hourlyRate: jobForm.getFieldValue('hourlyRate')
      },
      roles: !!params[1].roles ? params[1].roles.map(roleId => ({ roleId })) : employeeDetail.roles
    } as ICreateEmployee;
    let res = {}
    if (props.id) {
      res = await editEmployee(createParams)
    } else {
      res = await createEmployee(createParams);
    }
    if (res) {
      message.success(i18n?.t('menuTabList_pc_success'));
      props.close();
      props.bus.emit(EDIT_EMPLOYEES);
    }
  }

  const handleFormInputBlur = (form, key) => {
    form.validateFields([key]);
  }
  return (
    <Spin spinning={!!props.id && !(employeeDetail?.employee.firstName)}>
      <div className='employee-create-content'>
        <div className='employee-create-content-box'>
          <span style={{ 'fontSize': '24px', 'fontWeight': '500', 'color': '#3d3d3d' }}>
            {!!props.id ? i18n?.t('employeeAdd_pc_edit_employee') : 'Add New employee'}
          </span>
          <div>
            <Button onClick={handleCancel}>{i18n?.t('cancel')}</Button>
            <Button type="primary" disabled={employeeDetail?.employee?.tag === 1} style={{ 'marginLeft': '12px' }} onClick={handleSave}>{i18n?.t('save')}</Button>
          </div>
        </div>
        <div className='employee-create-content-wrap'>
          <div style={{ 'fontSize': '16px', 'fontWeight': '500' }}>{i18n?.t('personal_info')}</div>
          <div className='employee-create-content-employeeForm'>
            <Form
              {...layout}
              form={employeeForm}
              validateTrigger="onSubmit"
            >
              <Form.Item
                label={
                  <div>
                    <span>{i18n?.t('employeeAdd_pc_first_name')}</span>
                    <span style={{ 'color': '#ff4d4f' }}> * </span>
                  </div>
                }
                name="firstName"
                rules={[
                  () => ({
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(i18n.t('please_enter_x', { msg: i18n?.t('employeeAdd_pc_first_name') }))
                      }
                      return Promise.resolve()
                    }
                  }),
                ]}
              >
                {<Input required onBlur={() => { handleFormInputBlur(employeeForm, 'firstName') }} />}
              </Form.Item>

              <Form.Item
                label={
                  <div>
                    <span>{i18n?.t('last_name')}</span>
                    <span style={{ 'color': '#ff4d4f' }}> * </span>
                  </div>
                }
                name="lastName"
                rules={[
                  () => ({
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(i18n.t('please_enter_x', { msg: i18n?.t('last_name') }))
                      }
                      return Promise.resolve()
                    }
                  }),
                ]}
              >
                {<Input required onBlur={() => { handleFormInputBlur(employeeForm, 'lastName') }} />}
              </Form.Item>

              <Form.Item
                label={
                  <div>
                    <span>{i18n?.t('email_address')}</span>
                    {
                      dashboardAccess && <span style={{ 'color': '#ff4d4f' }}> * </span>
                    }
                  </div>
                }
                name="email"
                rules={
                  [
                    {
                      validator (rule, value) {
                        if (!dashboardAccess && !value) {
                          return Promise.resolve();
                        }
                        if (!value) {
                          return Promise.reject(i18n.t('please_enter_x', { msg: i18n?.t('myAccount_pc_new_email') }))
                        }
                        if (!verifyEmail(value)) {
                          return Promise.reject(i18n?.t('pc_login_valid_email'));
                        }
                        return Promise.resolve();
                      }
                    }
                  ]
                }

              >
                {<Input disabled={!!employeeDetail?.employee?.accountId} onBlur={() => { handleFormInputBlur(employeeForm, 'email') }} />}
              </Form.Item>

              <Form.Item
                label={
                  <div>
                    <span>{i18n?.t('employeeAdd_pc_phone_number')}</span>
                  </div>
                }
                name="phoneNumber"
              >
                {<Input required />}
              </Form.Item>
            </Form>
          </div>
        </div>
        <div>
        </div>
        <div className='employee-create-content-wrap'>
          <div style={{ 'fontSize': '16px', 'fontWeight': '500' }}>{i18n?.t('employeeAdd_pc_job_info')}</div>
          <div className='employee-create-content-jobForm'>
            <Form
              {...layout}
              form={jobForm}
              validateTrigger="onSubmit"
            >
              <Form.Item
                label={
                  <div>
                    <span>Requires dashboard</span><br /> <span>access</span>
                  </div>
                }
                name="dashboardAccess"
              >
                {/* <Switch></Switch> */}
                <Checkbox checked={dashboardAccess} onChange={(val) => {
                  if (!val.target?.checked && employeeForm.getFieldValue('email')) {
                    handleFormInputBlur(employeeForm, 'email')
                  }
                  setDashboardAccess(val?.target?.checked);
                  handleFormInputBlur(employeeForm, 'email')
                }} />
              </Form.Item>
              <Form.Item
                label={
                  <div>
                    <span>{'Role'}</span>
                    <span style={{ 'color': '#ff4d4f' }}> * </span>
                  </div>
                }
                name="roles"
                rules={[
                  () => ({
                    validator: (_, value) => {
                      console.log('employeeDetail', employeeDetail);
                      if (!!props.id && ([1, 2].includes(employeeDetail?.employee?.tag))) {
                        return Promise.resolve();
                      }
                      if (!value || value.length === 0) {
                        return Promise.reject('Please enter Role');
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  optionLabelProp="label"
                  filterOption={(inputValue, option: any) => option?.label?.includes(inputValue)}
                  disabled={employeeDetail?.employee?.tag === 2 || employeeDetail?.employee?.tag === 1}
                  placeholder={(employeeDetail?.employee?.tag === 2 || employeeDetail?.employee?.tag === 1) && i18n?.t('pc_owner')}
                  allowClear
                  onBlur={() => { handleFormInputBlur(jobForm, 'roles') }}
                >
                  {
                    employeeDetail?.employee?.tag === 2 || employeeDetail?.employee?.tag === 1
                      ? <Option label={i18n?.t('pc_owner')} value={jobList[0]?.id}>
                        <div>
                          {i18n?.t('pc_owner')}
                        </div>
                      </Option>
                      : jobList.map((ele, index) => (
                        <Option value={ele.id} label={ele.label} key={index}>
                          <div>
                            {ele.label}
                          </div>
                        </Option>
                      ))
                  }
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <div>
                    <span>{i18n?.t('employeeAdd_pc_pos_access')}</span>
                    <span style={{ 'color': '#ff4d4f' }}> * </span>
                  </div>
                }
                name="accessCode"
                rules={[
                  () => ({
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(i18n?.t('please_enter_x', { msg: i18n?.t('employeeAdd_pc_pos_access') }));
                      }
                      if (!/^\d{4}$/.test(value)) {
                        return Promise.reject(i18n?.t('employee_create_numeric_code'));
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                {
                  <Input required onBlur={() => { handleFormInputBlur(jobForm, 'accessCode') }} addonAfter={
                    <div onClick={handleCreateAccessCode} className='access-code-addon-after'>
                      {i18n?.t('employeeAdd_pc_generate_code')}
                    </div>
                  } />
                }
              </Form.Item>
              <Form.Item>
                <p className='employee-create-content-employeeForm-tips'>
                  {i18n?.t('employeeAdd_pc_passcode_tips')}
                </p>
              </Form.Item>

              <Form.Item
                label={
                  <div>
                    <span>Hourly rate</span>
                  </div>
                }
                name="hourlyRate"
              >
                <InputNumber addonBefore="$" addonAfter="/hour" min={0} style={{ 'width': '100%' }} />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Spin>
  )
}
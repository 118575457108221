import React, { useEffect, useState } from 'react';
import './index.scss'
import { globalState } from '@/stores';
import { RangeValueType } from '@/types';
import Legend from '@/Pages/NetSalesSummary/components/Legend';
import { TableEmptyContent } from '@/Components/EmptyContent';
import ChartTooltip from '@/Pages/NetSalesSummary/components/ChartTooltip';
import { INetSalesByDay } from '@/Pages/NetSalesSummary/interface';
import { formatFullPrice } from '@/utils';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { echarts } from '@/utils/echarts'
import ReactDOMServer from 'react-dom/server';

interface IProps {
  dateFirst: RangeValueType;
  dateSecond: RangeValueType;
  dataList: Array<INetSalesByDay>
}

export default function NetSalesByDate (props: IProps) {
  const { i18n } = globalState;
  const { dateFirst, dateSecond, dataList } = props;
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    const _chartData = dataList.map(x => {
      return ({ ...x, dateDisplay: x.date.format('MM/DD') + '##' + x.date.format('YYYY/MM/DD'), netSales: Number(x.netSales) });
    });
    setChartData(_chartData)
  }, [dataList]);

  const chartConfig = () => ({
    legend: { show: false },
    tooltip: {
      trigger: 'axis',
      formatter: (params, ticket, callback) => {
        const _title = params[0].axisValue;
        const _data = params[0].data;
        return ReactDOMServer.renderToString(<ChartTooltip title={ _title } data={ _data }/>);
      }
    },
    grid: {
      left: '7%',
      top: 10,
      right: 50,
      bottom: 30
    },
    dataset: {
      dimensions: ['dateDisplay', 'currentNetSales', 'compareNetSales'],
      source: chartData
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        formatter: (datum) => `${ datum.split('##')[0] }`
      }
    },
    yAxis: {
      axisLabel: {
        formatter: (datum) => `${ formatFullPrice(datum) }`
      }
    },
    series: [
      {
        type: 'line',
        symbol: () => chartData.length === 1 ? 'circle' : 'none',
        symbolSize: () => chartData.length === 1 ? 8 : 4.5,
        showSymbol: true,
        itemStyle: { color: '#2563EB' },
        lineStyle: { width: 4.5 },
        emphasis: {
          lineStyle: { color: '#2563EB' },
          itemStyle: { color: '#2563EB' },
          disabled: false, scale: false, focus: false
        }
      },
      {
        type: 'line',
        symbol: () => chartData.length === 1 ? 'circle' : 'none',
        symbolSize: () => chartData.length === 1 ? 8 : 4.5,
        showSymbol: true,
        itemStyle: { color: '#BEBEBE', borderRadius: 10 },
        lineStyle: { width: 4.5 },
        emphasis: {
          itemStyle: { color: '#BEBEBE' },
          lineStyle: { color: '#BEBEBE' },
          disabled: false, scale: false, focus: false
        }
      },
    ]
  })

  return (
    <div className="net-sales-summary-by-date">
      <div className="net-sales-summary-by-date-header-wrap">
        <div className="net-sales-summary-by-date-title-wrap">
          <span>{ i18n.t('pc_net_sales_summary_net_sales_by_date') }</span>
        </div>
      </div>
      <Legend dateFirst={ dateFirst } dateSecond={ dateSecond }/>
      <div className="charts-wrap">
        { chartData.length > 0 ?
          <ReactEChartsCore echarts={ echarts } option={ chartConfig() }/> :
          <div className="no-data">{ TableEmptyContent }</div>
        }
      </div>
    </div>
  )
}
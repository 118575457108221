import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import './index.scss'
import PepprDatePicker, { DATE_RANGE_TYPE } from '@/Components/DatePicker';
import { Button, Select, Table, message } from 'antd';
import useGetState from '@/hooks/useGetState';
import dayjs, { Dayjs } from 'dayjs';
import { getToday, removeNullUndefinedFields, formatTimestampWithoutTimeZone, errorOutput, downloadFile } from '@/utils';
import { ApiQueryEmployeeList, ApiQueryLaborReport, ApiQueryLaborSummary, ApiExportLaborSummary, ApiApaasQueryExportResult } from '@/request/api';
import { TableEmptyContent } from '@/Components/EmptyContent';
import { ColumnsType } from 'antd/es/table';
import Columns from '@/Components/Columns/Columns';
import { SyncOutlined } from '@ant-design/icons';
import { RangeValueType } from '@/types';

interface IPage {
  current: number;
  pageSize: number;
  pageIndex: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

interface IParamsState {
  date: RangeValueType,
  employeeIdList: Array<number>,
  sortByDescTime: boolean,
  dateRangeType: string
}

interface ILaborSummary {
  totalLaborHours: string,
  totalHourlyRate: string,
  totalEstimatedTotalPay: string,
  totalCashTips: string,
  totalCardTips: string,
  totalTips: string,
}

export default function LaborSummary () {
  const { i18n } = globalState;

  const [isInit, setIsInit] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [today, setToday] = useState<Dayjs>(dayjs())
  const [rangePresets, setRangePresets] = useState([])
  const [employeeAllList, setEmployeeAllList] = useState([])
  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)
  const [tableData, setTableData] = useState([])
  const [laborSummary, setLaborSummary] = useState<ILaborSummary>(null)
  const [showColumnsList, setShowColumnsList] = useState([])
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1, pageIndex: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const [params, setParams, getParams] = useGetState<IParamsState>({
    date: [dayjs(), dayjs()],
    employeeIdList: [],
    sortByDescTime: false,
    dateRangeType: DATE_RANGE_TYPE.TODAY
  })
  const [isExporting, setIsExporting] = useState(false)
  const [intervalId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  let exportTaskId;
  const desiredElements = ['firstName', 'lastName', 'clockIn', 'clockOut', 'hourlyRate'];

  const tableColumns = [
    {
      dataIndex: 'firstName', key: 'firstName', title: i18n?.t('timesheets_pc_first_name'), width: 200
    },
    {
      dataIndex: 'lastName', key: 'lastName', title: i18n?.t('timesheets_pc_last_name'), width: 150
    },
    {
      dataIndex: 'clockIn', key: 'clockIn', title: i18n?.t('timesheets_pc_clock_in'), width: 200,
      render: (val) => !!val ? formatTimestampWithoutTimeZone(val) : 'N/A'
    },
    {
      dataIndex: 'clockOut', key: 'clockOut', title: i18n?.t('timesheets_pc_clock_out'), width: 200,
      render: (val) => !!val ? formatTimestampWithoutTimeZone(val) : 'N/A'
    },
    {
      dataIndex: 'hourlyRate', key: 'hourlyRate', title: i18n?.t('laborSummary_hourlyRate'), width: 200,
      render: (val) => !!val ? `$${parseFloat(val).toFixed(2)}` : 'N/A'
    },
    {
      dataIndex: 'laborHours', key: 'laborHours', title: i18n?.t('laborSummary_totalLaborHours'), width: 200,
      render: (val) => !!val ? `${parseFloat(val).toFixed(2)} hrs` : 'N/A'
    },
    {
      dataIndex: 'estimatedTotalPay', key: 'estimatedTotalPay', title: i18n?.t('laborSummary_estimatedTotalPay'), width: 200,
      render: (val) => !!val ? `$${parseFloat(val).toFixed(2)}` : 'N/A'
    },
    {
      dataIndex: 'cashTips', key: 'cashTips', title: i18n?.t('laborSummary_cashTips'), width: 200,
      render: (val) => !!val ? `$${parseFloat(val).toFixed(2)}` : 'N/A'
    },
    {
      dataIndex: 'cardTips', key: 'cardTips', title: i18n?.t('laborSummary_cardTips'), width: 200,
      render: (val) => !!val ? `$${parseFloat(val).toFixed(2)}` : 'N/A'
    },
    {
      dataIndex: 'totalTips', key: 'totalTips', title: i18n?.t('laborSummary_totalTips'), width: 200,
      render: (val) => !!val ? `$${parseFloat(val).toFixed(2)}` : 'N/A'
    }
  ]

  useEffect(() => {
    initPage();
  }, []);

  const initPage = async () => {
    setShowColumnsList(tableColumns.map(x => x.dataIndex))
    fetchEmployeeList();
    const _today = await getToday();
    setToday(_today);
    setParams({ ...params, date: [_today, _today] });
    await fetchLaborSummary();
    await fetchTableData();
    setIsInit(true);
  }

  const fetchEmployeeList = async () => {
    const _params = { pageIndex: 1, pageSize: 99999, total: 0 }
    const res = await ApiQueryEmployeeList(_params)
    if (res.code !== 0) return;
    const { dataList = [] } = res.data;
    setEmployeeAllList(dataList.map(x => ({ label: x.employee.fullName, value: x.employee.employeeId })))
  }

  const fetchTableData = async () => {
    const _pager = getPager()
    const { date, ...curParams } = getParams();
    const reqParams = {
      pageNo: _pager.pageIndex,
      pageSize: _pager.pageSize,
      startTime: date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: date[1].format('YYYY-MM-DD 23:59:59'),
      employeeId: !curParams.employeeIdList || curParams.employeeIdList?.length === 0 ? '' : curParams.employeeIdList[0],
    }
    setIsLoading(true);
    const res = await ApiQueryLaborReport(removeNullUndefinedFields(reqParams))
    setIsLoading(false);
    if (res.code !== 0) return;
    const { datas = [], page } = res.data;
    const { pageSize, pageNo: pageIndex, total } = page;
    setTableData(datas);
    setPager({ ..._pager, pageSize, pageIndex, total, current: pageIndex })
  }

  const fetchLaborSummary = async () => {
    const { date, ...curParams } = getParams();
    const reqParams = {
      startTime: date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: date[1].format('YYYY-MM-DD 23:59:59'),
      employeeId: !curParams.employeeIdList || curParams.employeeIdList?.length === 0 ? '' : curParams.employeeIdList[0],
    }
    const res = await ApiQueryLaborSummary(reqParams)
    if (res.code !== 0) return;
    const { data } = res;
    setLaborSummary(data);
  }

  const onDateRangeChange = (dates) => {
    const dateRangeType = rangePresets.find((v) => {
      return (v.value[0].isSame(dates[0])) && (v.value[1].isSame(dates[1]))
    })?.key ?? DATE_RANGE_TYPE.CUSTOM
    setParams(({ ...params, date: dates, dateRangeType }))
    fetchTableData();
    fetchLaborSummary();
  };

  const handleEmployeeChange = (val) => {
    setParams({ ...params, employeeIdList: val ? [val] : undefined });
    setPager({ ...pager, pageIndex: 1 })
    fetchTableData();
    fetchLaborSummary();
  }

  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0)
    setShowColumnsList(list);
  }

  const handleTableChange = (_pagination, _, sorter) => {
    setPager({ ...pager, ..._pagination, pageIndex: _pagination.current });
    fetchTableData();
  }

  const reload = () => {
    fetchTableData();
    fetchLaborSummary();
  }
  // region 导出相关
  const handleExport = async () => {
    setIsExporting(true)
    const { date, ...curParams } = getParams();
    const reqParams = {
      startTime: date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: date[1].format('YYYY-MM-DD 23:59:59'),
      employeeId: !curParams.employeeIdList || curParams.employeeIdList?.length === 0 ? '' : curParams.employeeIdList[0],
    }
    try {
      const resp: any = await ApiExportLaborSummary(reqParams);
      exportTaskId = resp?.data?.exportTaskId ?? null;
      if (resp?.code !== 0 || !exportTaskId) {
        errorOutput(resp, i18n.t('pc_payout_report_download_failed'), i18n)
        setIsExporting(false)
        return;
      }
      getExportResult();
    } catch (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'), i18n)
      setIsExporting(false)
    }
  }
  const getExportResult = async () => {
    try {
      const resp: any = await ApiApaasQueryExportResult({ params: { req: { exportTaskId } } });
      if (resp.code !== 0) {
        errorOutput(resp, i18n.t('pc_payout_report_download_failed'), i18n)
        setIsExporting(false)
        stopExport();
        return;
      }
      const data = resp.data;
      if (data?.result && data?.url) {
        // 开启下载
        downloadFile(data.url);
        message.success(i18n.t('pc_payout_report_successfully_downloaded'))
        setIsExporting(false);
        exportTaskId = null;
      } else {
        const id = setTimeout(async () => {
          getExportResult();
        }, 2000);
        setTimeoutId(id);
      }
    } catch (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'), i18n)
      setIsExporting(false)
      stopExport();
    }
  }
  const stopExport = () => {
    if (intervalId !== null) {
      clearTimeout(intervalId);
      setTimeoutId(null);
    }
  }
  return (
    <div className="labor-summary-wrap">
      <div className="main-top-wrap">
        <div className="main-title">{ i18n?.t('overseas_peppr_entrance_laborsummary') }</div>
        <div className="action-wrap">
          <Button type="primary" disabled={tableData.length === 0} loading={ isExporting } onClick={ () => handleExport() }>{ i18n.t('export_pc') }</Button>
        </div>
      </div>
      <div className="filter-wrap">
        <div className="left-wrap">
          <PepprDatePicker value={params.date} onChange={onDateRangeChange} today={today} setPresets={setRangePresets} />
          <Select
            className="employee-select"
            showSearch
            optionFilterProp="label"
            options={employeeAllList}
            value={params.employeeIdList}
            onChange={handleEmployeeChange}
            style={{ width: 200 }}
            allowClear
            placeholder={i18n.t('pc_employee_report_all_employee')}
          />
        </div>
        <div className="right-wrap">
          <Button type="link" onClick={reload}><SyncOutlined /></Button>
          <Columns value={showColumnsList} options={tableColumns.map(item => ({ label: item.title, value: item.dataIndex }))} onChange={handleChangeColumns} />
        </div>
      </div>
      <div className="table-wrap">
        <Table
          className={isShowEmptyTable && 'empty-table'}
          columns={tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType}
          dataSource={tableData}
          rowKey={'id'}
          loading={isLoading}
          pagination={pager}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: TableEmptyContent }}
          summary={() => (
            <>
              {
                !!tableData.length && laborSummary && !isShowEmptyTable &&
                <Table.Summary.Row className='summary-row-gray-bgc'>
                  <Table.Summary.Cell index={0} colSpan={showColumnsList.filter(element => desiredElements.includes(element)).length}>
                    <div style={{ textAlign: 'center' }}>{i18n?.t('laborSummary_grandTotals')}</div>
                  </Table.Summary.Cell>
                  { showColumnsList.includes('laborHours') && <Table.Summary.Cell index={1} colSpan={1}>{laborSummary.totalLaborHours ? `${parseFloat(laborSummary.totalLaborHours).toFixed(2)} hrs` : 'N/A'}</Table.Summary.Cell> }
                  { showColumnsList.includes('estimatedTotalPay') && <Table.Summary.Cell index={3} colSpan={1}>{laborSummary.totalEstimatedTotalPay ? `$${parseFloat(laborSummary.totalEstimatedTotalPay).toFixed(2)}` : 'N/A'}</Table.Summary.Cell> }
                  { showColumnsList.includes('cashTips') && <Table.Summary.Cell index={4} colSpan={1}>{laborSummary.totalCashTips ? `$${parseFloat(laborSummary.totalCashTips).toFixed(2)}` : 'N/A'}</Table.Summary.Cell> }
                  { showColumnsList.includes('cardTips') && <Table.Summary.Cell index={3} colSpan={1}>{laborSummary.totalCardTips ? `$${parseFloat(laborSummary.totalCardTips).toFixed(2)}` : 'N/A'}</Table.Summary.Cell> }
                  { showColumnsList.includes('totalTips') && <Table.Summary.Cell index={4} colSpan={1}>{laborSummary.totalTips ? `$${parseFloat(laborSummary.totalTips).toFixed(2)}` : 'N/A'}</Table.Summary.Cell> }
                </Table.Summary.Row>
              }
              {
                isShowEmptyTable && <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={999}>
                    {TableEmptyContent}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              }
            </>
          )}
        />
      </div>
    </div>
  )
}
import React, { useEffect, useState } from 'react';
import './index.scss'
import TextLineNew from '../component/TextLineNew';
import DashedLine from '../component/DashedLine';
import { ConfigTypeValue, IPreviewSectionAttributes } from '../../interface';
import printOutDecorationStore from '../../store';
import { toJS } from 'mobx';

export default function PrepStationName (props) {
  const [type, setType] = useState<ConfigTypeValue>('Check');
  const [hideSection, setHideSection] = useState<boolean>(true);
  const setMarginTop = () => {
    switch (type) {
    case 'Kitchen':
      return '80px';

    default:
      return '0'
    }
  }

  useEffect(() => {
    const previewData: Array<IPreviewSectionAttributes>  = toJS(printOutDecorationStore.getPreviewData)
    const type = toJS(printOutDecorationStore.getUrlParamsType);
    setType(type);
    if (type === 'Kitchen') {
      const setting = previewData.find(fin => fin.id === 'prep-station-setting-section')?.['children'];
      setHideSection((setting?.['prep-station-setting-section-check'] || {}).hide);
    }
  }, [props])

  return <div className='preview-ui-prep-station-name' style={{
    marginTop: setMarginTop()
  }}>
    {
      !hideSection &&
        <TextLineNew text={'Prep station name'} style={{ fontSize: 'md' }}/>
    }
  </div>
}
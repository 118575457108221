import './index.scss'
import { globalState } from '@/stores';
import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd'
import DiningOptionsTable from './DiningOptionsTable';
import PosModuleDisplay from './PosModuleDisplay';
import TabPane from 'antd/es/tabs/TabPane';
import { TabType } from './enum'

export default function DiningOptions () {
  const { i18n } = globalState;
  const [activeKey, setActiveKey] = useState(TabType.DININGOPTION);
  const tabsItems = [
    {
      key: TabType.DININGOPTION,
      label: i18n.t('pc_product_mix_dining_option'),
    },
    {
      key: TabType.POSMODULEDISPLAY,
      label: i18n.t('dining_option_pos_module_display'),
    }
  ]
  const changeTab = (value) => {
    setActiveKey(value);
  }

  const tabBarExtraContent = {
    left: <div className="main-tab-title">{ i18n.t('pc_product_mix_dining_option') }</div>,
  }

  return (
    <div className="dining-options-content">
      <Tabs tabBarExtraContent={ tabBarExtraContent } activeKey={activeKey} onChange={changeTab}>
        <TabPane tab={i18n.t('pc_product_mix_dining_option')} key={TabType.DININGOPTION}>
          <DiningOptionsTable activeKey={activeKey}/>
        </TabPane>
        <TabPane tab={i18n.t('dining_option_pos_module_display')} key={TabType.POSMODULEDISPLAY}>
          <PosModuleDisplay activeKey={activeKey}/>
        </TabPane>
      </Tabs>
    </div>
  )
}
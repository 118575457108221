import React, { useEffect, useRef, useState } from 'react'
import { Button, message } from 'antd'
import './index.scss'
import BreadCrumb, { ICustomTitleItem } from '@/Pages/Menus/component/BreadCrumb'
import ItemInfo from './ItemInfo'
import Prices from './Prices'
import TaxRates from '../component/TaxRates'
import PrepStations from '../component/PrepStations'
import Report from '../component/Report'
import ChannelAvailability from '../component/ChannelAvailability'
import { globalState } from '@/stores'
import { IDefaultAndInheritsRes } from '../interface'
import { getQuerys } from '@/utils'
import { ApiBatchQueryMenuItemDetails, ApiQueryDefaultAndInherits, ApiSaveAndAssociatedWithMenuGroup } from '@/request/Menus/CreateItem'
import Usage from './Usage'
import menuStore from '../store'
import ModifierGroups from '../component/ModifierGroups'
import { defaultAndInheritsData } from '../enum'
import { useNavigate } from 'react-router-dom';



export default function CreateItem (props) {
  const { i18n } = globalState
  const navigate = useNavigate();
  const {
    subMenuId,
    itemId,
    itemName,
    subMenuName = menuStore.getActiveSubMenu.name,
    menuId = menuStore.getActiveMenu.uuid,
    menuName = menuStore.getActiveMenu.name
  } = getQuerys(location.href);
  const customTitle: Array<ICustomTitleItem> = [
    {
      title: i18n.t('overseas_peppr_entrance_menu_builder'),
      router: '/menus/menuBuilder'
    }, {
      title: subMenuName,
      router: `/menus/createSubMenu?subMenuId=${subMenuId}&subMenuName=${subMenuName}&menuId=${menuId}&menuName=${menuName}`
    }
  ]

  // ** others definition **
  const [editDetail, setEditDetail] = useState<any>({});
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);

  // ** default data **
  const [defaultAndInherits, setDefaultAndInherits] = useState<IDefaultAndInheritsRes>(defaultAndInheritsData);

  // ** ref definition **
  const refItemInfo = useRef(null);
  const refPrice = useRef(null);
  const refTaxRates = useRef(null);
  const refModifierGroups = useRef(null);
  const refPrepStations = useRef(null);
  const refChannelAvailability = useRef(null);
  const refReport = useRef(null);

  // ** Online Ordering
  const [pricingType, setPricingType] = useState<{ value: string, displayName: string }>(null);

  const baseObj = { value: 'default', displayName: 'Base price' }
  const openObj = { value: 'openPrice', displayName: 'Open price' }

  const getQueryDefaultAndInherits = async () => {
    const editParams = {
      params: {
        node: {
          sourceId: subMenuId,
          sourceType: 'MenuGroup'
        }
      }
    }
    const data = await ApiQueryDefaultAndInherits(editParams);
    if (data.code === 0) {
      setDefaultAndInherits({ ...defaultAndInherits, ...data.data })
    }
  }

  const getItemDetail = async () => {
    const data = await ApiBatchQueryMenuItemDetails({
      params: {
        groupId: subMenuId,
        guids: [itemId]
      }
    })
    if (data.code === 0) {
      setEditDetail(data.data[0])
      setPricingType(data.data[0].pricingType);
    }
  }

  const cancelMenu = async () => {
    navigate(-1)
  }

  const saveMenu = async () => {
    // ** 校验 **
    const isValid = await checkValidateFields();
    if (!isValid) return;

    // ** 获取值 **
    const formItemInfoData = refItemInfo.current.form.getFieldsValue()
    const formPriceData = refPrice.current.form.getFieldsValue()
    const formTaxRatesData = refTaxRates.current.form.getFieldsValue()
    const formPrepStationsData = refPrepStations.current.form.getFieldsValue()
    const formChannelAvailabilityData = refChannelAvailability.current.form.getFieldsValue()
    const formReport = refReport.current.form.getFieldsValue()

    // ** 不使用form的暴露值 **
    const { isInheritModifierGroups, tableData } = refModifierGroups.current

    // ** 常量定义 **
    const initParamsInAPass = {
      SYS$META$URI: 'rms.overseas.MerchantManagementConsole.Menu.MenuItemVO_VO',
      taxInclude: false,
      combo: false, // 不知道是什么
      inheritTaxInclude: false, // 不知道是什么
      side: false // 不知道是什么
    }

    // ** 解构 **
    const { name, foreignName, shortName, description, itemImage, alcoholContains, color } = formItemInfoData
    const { basePrice, pricingType } = formPriceData
    const { overrideTaxRate, taxRates } = formTaxRatesData
    const { inheritPrepStation, prepStations } = formPrepStationsData
    const { visibleChannelPOS, onlineOrdering } = formChannelAvailabilityData
    const { inheritPrepStation: inheritSalesCategory, salesCategory: salesCategories } = formReport

    // ** 数值二次处理 **
    // * taxRates *
    const taxRatesData = taxRates ? taxRates.map(item => ({ uuid: item })) : []
    // * prepStations *
    const prepStationsData = prepStations ? prepStations.map(item => ({ uuid: item })) : []

    // ** params 拼接 **
    const paramsData = {
      name,
      foreignName,
      shortName,
      description,
      itemImage,
      alcoholContains,
      color,
      basePrice,
      pricingType: pricingType === 'default' ? baseObj : openObj,
      overrideTaxRate,
      taxRates: taxRatesData,
      inheritPrepStation,
      prepStations: prepStationsData,
      inheritModifierGroup: isInheritModifierGroups,
      modifierGroups: tableData,
      visibleChannelPOS,
      onlineOrdering,
      salesCategories: salesCategories ? [{ id: salesCategories }] : [],
      inheritSalesCategory
    }

    // ** API FETCH **
    setSaveButtonLoading(true)
    const res = await ApiSaveAndAssociatedWithMenuGroup({
      params: {
        menuItems: [{
          ...paramsData,
          ...initParamsInAPass,
          uuid: itemId,
        }],
        node: {
          sourceId: subMenuId,
          sourceType: {
            displayName: 'MenuGroup',
            value: 'MenuGroup'
          }
        }
      } })
    setSaveButtonLoading(false)
    if (res.code === 0) {
      message.success('success', 3)
      navigate(-1)
    }
  }

  const checkValidateFields = async () => {
    try {
      await refItemInfo.current.form.validateFields();
      await refPrice.current.form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  }

  const changePricePolicy = (pricePolicy: 'default' | 'openPrice') => {
    setPricingType(pricePolicy === 'default' ? baseObj : openObj)
  }

  useEffect(()=> {
    getQueryDefaultAndInherits()
    if (itemId) {
      getItemDetail()
    } else {
      setEditDetail({
        visibleChannelPOS: true,
        onlineOrdering: true
      })
    }
  }, [])


  return (
    <div className='menus-create-item'>
      <BreadCrumb
        slotAction={
          <div>
            <Button
              style={{ width: 88, marginRight: 15 }}
              onClick={cancelMenu}
            >{i18n.t('cancel')}</Button>
            <Button
              style={{ width: 88 }}
              type="primary"
              onClick={saveMenu}
              loading={saveButtonLoading}
            >{i18n.t('save')}</Button>
          </div>
        }
        needConfirm
        customTitle={itemId ? customTitle.concat({ title: itemName } ) : customTitle.concat({ title: i18n.t('create_item') } ) }
      />

      <ItemInfo
        ref={refItemInfo}
        editInfo={editDetail}
      />

      <Prices
        ref={refPrice}
        editInfo={editDetail}
        changePricePolicy={changePricePolicy}
      />

      <TaxRates
        pageType='item'
        ref={refTaxRates}
        editInfo={editDetail}
        inheritName={subMenuName}
        defaultAndInherits={defaultAndInherits}
      />

      <ModifierGroups
        ref={refModifierGroups}
        inheritName={subMenuName}
        editInfo={editDetail}
        defaultAndInherits={defaultAndInherits}
      />

      <PrepStations
        pageType='item'
        ref={refPrepStations}
        editInfo={editDetail}
        inheritName={subMenuName}
        defaultAndInherits={defaultAndInherits}
      />

      <Report
        pageType='item'
        ref={refReport}
        editInfo={editDetail}
        defaultAndInherits={defaultAndInherits}
        inheritName={subMenuName}
        isShowInheritRadio={true}
      />
      <ChannelAvailability
        ref={refChannelAvailability}
        editInfo={editDetail}
        pricingType={pricingType}
      />

      {
        itemId &&  <Usage />
      }

    </div>
  )
}
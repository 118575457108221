import './index.scss'
import React, { useEffect, useState, FC } from 'react';
import { tryParse, decodeParams } from '@/utils/index'
import { checkAccount, ICheckAccountParams } from '@/request/Login';
import { SignUp, SignUpComponentProps } from './component/SignUp';
import { Binding, BindingComponentProps } from './component/Binding';
import { ForgotPassword, ForgotPasswordComponentProps } from './component/ForgotPassword';
import { LoginCommponent } from './component/Login';
import { globalState } from '@/stores';
import LoginBg from '@/assets/login_bg_720.jpg'

export default function Login () {
  const [componentArr] = useState<FC[]>([SignUp, Binding, ForgotPassword]);
  const [componentIndex, setComponentIndex] = useState<number>(-1);
  const { i18n } = globalState;
  useEffect(() => {
    const fetchData = async () => {
      const pathName = window.location.pathname;
      if (pathName === '/register') {
        const params = tryParse<ICheckAccountParams>(decodeParams());
        console.log('params', params);
        const response = await checkAccount({ ...params });
        if (!!response.accountId) {
          console.log('跳转到bind页面');
          setComponentIndex(1);
        } else {
          setComponentIndex(0);
        }
      } else if (pathName === '/forgetPassword') {
        setComponentIndex(2);
      } else {
        setComponentIndex(-1);
      }
    }
    fetchData()
    console.log('i18----->', i18n);

  }, [])
  return (
    <div className="login-page">
      <div className="login-page-banner">
        <div className="login-page-banner-wrap" style={{ backgroundImage: `url(${LoginBg})` }}>
        </div>
      </div>
      <div className="login-page-login-wrap">
        {
          componentIndex >= 0
            ? componentArr.map((Comp: FC<SignUpComponentProps | BindingComponentProps | ForgotPasswordComponentProps>, i: number) => i === componentIndex && <Comp key={i}/>)
            : <LoginCommponent/>
        }
      </div>
    </div>
  )
}
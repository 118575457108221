import React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import isBetween from 'dayjs/plugin/isBetween'
import * as Sentry from '@sentry/react';
import './assets/iconfont/iconfont.css';
dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(isBetween)
dayjs.locale('zh-cn', { weekStart: 1 })
try {
  console.log(process.env.SWIMLANE, '__process.env.SWIMLANE')
  if (window.location.hostname === 'pos.peppr.com') {
    Sentry.init({
      dsn: 'https://7bd1f837ab39b6a00afde4a6ababf572@o4507326064230400.ingest.us.sentry.io/4507507410927616',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['https://pos.peppr.com'],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      beforeSend (event) {
        // Add the gray label to the event's tags
        event.tags = {
          ...event.tags,
          gray: process.env.SWIMLANE === 'gray',
        };
        return event;
      },
    });
  }
} catch (error) {
  console.log(error);
}
ReactDOM.render(<App />, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
import React, { useEffect, useState } from 'react'
import { globalState } from '@/stores';
import './index.scss'
import { Button, Form, Select, Table, Tooltip } from 'antd';
import PepprDatePicker, { DATE_RANGE_TYPE } from '@/Components/DatePicker';
import { formatDollar, formatPrice, getToday, removeNullUndefinedFields } from '@/utils';
import { ApiQueryCashManagement, ApiQueryEmployeeList } from '@/request/api';
import useGetState from '@/hooks/useGetState';
import dayjs, { Dayjs } from 'dayjs';
import Columns from '@/Components/Columns/Columns';
import { ColumnsType } from 'antd/es/table';
import { TableEmptyContent } from '@/Components/EmptyContent';
import axios from 'axios';
import { CASH_OPERATE_TYPE, CASH_OPERATE_TYPE_I18N, ICashDrawerTransDetail, IPage } from './types';
import { SyncOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { SorterResult } from 'antd/es/table/interface';

let axiosSource = axios.CancelToken.source();

export default function CashManagement () {
  const { i18n } = globalState;

  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)
  const [employeeAllList, setEmployeeAllList] = useState<Array<{ value: string, label: string }>>([])
  const [rangePresets, setRangePresets] = useState<Array<any>>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [showColumnsList, setShowColumnsList] = useState<Array<string>>([])
  const [tableData, setTableData] = useState<Array<ICashDrawerTransDetail>>([])
  const [today, setToday] = useState<Dayjs>(dayjs())
  const [sortedInfo, setSortedInfo, getSortedInfo] = useGetState<SorterResult<ICashDrawerTransDetail>>({ columnKey: 'transTime', order: 'descend' });
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1, pageNo: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const [displayTimeRange, setDisplayTimeRange] = useState<Array<string>>(['', ''])
  const [, setParams, getParams] = useGetState<{ dateRangeType: string }>({
    dateRangeType: DATE_RANGE_TYPE.TODAY,
  })
  const [form] = Form.useForm()

  const formInitialValues = {
    date: [dayjs().startOf('day'), dayjs().endOf('day')],
    employeeIdList: undefined,
    typeList: []
  }

  const tableColumns = [
    {
      dataIndex: 'transTime', key: 'transTime', title: i18n.t('pc_cash_management_operate_time'), width: 220,
      sorter: true, sortOrder: sortedInfo.columnKey === 'transTime' ? sortedInfo.order : null, sortDirections: ['descend', 'ascend', 'descend']
    },
    { dataIndex: 'employeeName', key: 'employeeName', title: i18n.t('pc_cash_management_employee') },
    { dataIndex: 'amount', key: 'amount', title: i18n.t('pc_cash_management_cash_amount'), render: val => formatDollar(val, { plusSign: true }) + formatPrice(val) },
    { dataIndex: 'type', key: 'type', title: i18n.t('pc_cash_management_cash_operation'), render: val => i18n.t(CASH_OPERATE_TYPE_I18N[val]) },
    { dataIndex: 'reason', key: 'reason', title: i18n.t('pc_cash_management_reason'), render: val => val || 'N/A' },
  ]

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setShowColumnsList(tableColumns.map(x => x.dataIndex))
    fetchEmployeeList();
    const _today = await getToday();
    setToday(_today);
    form.setFieldValue('date', [_today, _today])
    await fetchTableData();
  }

  const fetchEmployeeList = async () => {
    const _params = { pageNo: 1, pageSize: 99999, total: 0 }
    const resp = await ApiQueryEmployeeList(_params)
    const { dataList = [] } = resp?.data;
    setEmployeeAllList((dataList || []).map(x => ({ label: x.employee.fullName, value: x.employee.employeeId })))
  }

  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0)
    setShowColumnsList(list);
  }

  const handleFormValuesChange = ({ date }) => {
    setPager({ ...pager, pageNo: 1 });
    if (date) {
      const dateRangeType = rangePresets.find((v) => {
        return (v.value[0].isSame(date[0])) && (v.value[1].isSame(date[1]))
      })?.key ?? DATE_RANGE_TYPE.CUSTOM
      setParams({ dateRangeType })
    }
    fetchTableData();
  }

  const handleTableChange = (_pagination, _, sorter) => {
    setPager({ ...pager, ..._pagination, pageNo: _pagination.current });
    setSortedInfo(sorter)
    fetchTableData();
  }

  const fetchTableData = async () => {
    const pagerParams = getPager();
    const formParams = form.getFieldsValue();
    const curParams = getParams();
    const _sorter = getSortedInfo();
    const params = {
      dateRangeType: curParams.dateRangeType,
      beginTime: formParams.date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: formParams.date[1].format('YYYY-MM-DD 23:59:59'),
      employeeIdList: formParams.employeeIdList && [formParams.employeeIdList],
      sortByDescTime: _sorter.order === 'descend',
      typeList: formParams.typeList,
      page: {
        pageNo: pagerParams.pageNo,
        pageSize: pagerParams.pageSize
      }
    }
    setLoading(true);
    axiosSource.cancel();
    axiosSource = axios.CancelToken.source();
    const resp = await ApiQueryCashManagement(removeNullUndefinedFields(params), { cancelToken: axiosSource.token });
    setLoading(false);
    if (resp.code !== 0) return;
    const { cashDrawerTransDetailList = [], page, beginTime = '', endTime = '' } = resp.data
    setTableData(cashDrawerTransDetailList)
    setDisplayTimeRange([beginTime, endTime])
    setPager({ ...pagerParams, ...page, current: page.pageNo })
  }

  return (
    <div className="cash-management-wrap">
      <div className="main-title">{ i18n.t('pc_cash_management_title') }</div>
      <div className="filter-wrap">
        <div className="left-wrap">
          <Form form={ form } layout="inline" onValuesChange={ handleFormValuesChange } initialValues={ formInitialValues }>
            <Form.Item name={ 'date' }>
              <PepprDatePicker today={ today } setPresets={ setRangePresets }/>
            </Form.Item>
            <Form.Item name={ 'employeeIdList' }>
              <Select
                options={ employeeAllList }
                showSearch
                optionFilterProp="label"
                style={ { width: 200 } }
                allowClear
                placeholder={ i18n.t('pc_employee_report_all_employee') }
              />
            </Form.Item>
            <Form.Item name={ 'typeList' }>
              <Select
                style={ { minWidth: 250 } }
                allowClear
                mode="multiple"
                placeholder={ i18n.t('pc_employee_report_cash_operation') }
                maxTagCount={ 2 } showSearch={ false }
                maxTagPlaceholder={ (omittedValues) => (
                  <Tooltip title={ omittedValues.map(({ label }) => label).join(', ') }>
                    <span>+{ omittedValues.length }...</span>
                  </Tooltip>
                ) }
                options={ [
                  CASH_OPERATE_TYPE.CASH_COLLECTED,
                  CASH_OPERATE_TYPE.CASH_TIP,
                  CASH_OPERATE_TYPE.ORDER_CASH_PAYMENT,
                  CASH_OPERATE_TYPE.PAY_IN,
                  CASH_OPERATE_TYPE.PAY_OUT,
                  CASH_OPERATE_TYPE.REFUND_CASH_OUT,
                  CASH_OPERATE_TYPE.TIP_OUT,
                ].map(x => ({ value: x, label: i18n.t(CASH_OPERATE_TYPE_I18N[x]) })) }
              />
            </Form.Item>
          </Form>
        </div>
        <div className="right-wrap">
          <Button type="link" onClick={ fetchTableData }><SyncOutlined/></Button>
          <Columns value={ showColumnsList } options={ tableColumns.map(item => ({ label: item.title, value: item.dataIndex })) } onChange={ handleChangeColumns }/>
        </div>
      </div>
      <div className="time-info">{ `${ i18n.t('pc_employee_report_time_range') }: ${ displayTimeRange[0] } - ${ displayTimeRange[1] }` }</div>
      <div className="table-wrap">
        <Table
          className={ isShowEmptyTable && 'empty-table' }
          columns={ tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          rowKey={ 'key' }
          loading={ loading }
          pagination={ pager }
          onChange={ handleTableChange }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
          summary={ () => (
            isShowEmptyTable && <Table.Summary.Row>
              <Table.Summary.Cell index={ 0 } colSpan={ 999 }>
                { TableEmptyContent }
              </Table.Summary.Cell>
            </Table.Summary.Row>
          ) }
        />
      </div>
    </div>
  )
}
import './index.scss'
import { Button, Modal, Input, Form, message, Radio, Popover, Divider } from 'antd';
import { globalState } from '@/stores';
import { PlusCircleOutlined, MoreOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react';
import { batchAddDiningOption, queryAllDiningOption, batchArchivedDiningOptionAction, sortDiningOptionAction } from '@/request/DiningOptions';
import { ArchivedPointIcon, ActivePointIcon, ArchivedIcon, ActiveIcon } from '../component/IconComponent';

import type { DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove
} from '@dnd-kit/sortable';
import { isStrictlyEmptyString } from '@/utils'
import DragTable, { DragHandle } from '@/Components/DragTable';
import { DiningOptionsEnum, TabType } from '../enum';
interface IProps{
  activeKey: TabType
}

export default function DiningOptionsTable (props: IProps) {
  const [diningOptionsForm] = Form.useForm();
  const i18n = globalState.i18n;
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState<boolean>();
  const [tableData, setTableData] = useState([])
  const [changeRow, setChangeRow] = useState<string>();
  const [changeRowDiningOption, setChangeRowDiningOption] = useState<string>();
  const DEFAULT_FILTER_TYPE = {
    'STATUS': ['1'],
  }
  const STATUS_FILTER = [{ text: i18n.t('active'), value: '1' }, { text: i18n.t('archived'), value: '2' }]
  const [statusFilter] = useState(STATUS_FILTER);
  const [filteredValue, setFilteredValue] = useState(DEFAULT_FILTER_TYPE.STATUS);

  const [params, setParams] = useState(
    {
      req: {
        status: ['1']
      }
    }
  );
  const layout = {
    labelCol: { span: 2 },
  };

  useEffect(() => {
    fetchTableData();
  }, [params])

  useEffect(() => {
    if (props.activeKey === TabType.DININGOPTION) {
      fetchTableData();
    }
  }, [props])

  const handleOk = async () => {
    const res = await diningOptionsForm.validateFields(['name']);
    if (res) {
      const response = await batchAddDiningOption({
        options: [{
          name: res['name'],
          optionType: {
            value: '1'
          }
        }]
      });
      if (response?.code === 0) {
        message.success(response.message);
        diningOptionsForm.resetFields();
        setModalOpen(false);
        fetchTableData();
      } else {
        message.error(response.message);
      }
    }
  }
  const setDiningOption = async (params) => {
    const response = await batchAddDiningOption({
      options: [params]
    });
    if (response?.code === 0) {
      fetchTableData();
    } else {
      message.error(response.message);
    }
  }
  const handleCancel = () => {
    setModalOpen(false);
    diningOptionsForm.resetFields();
  }
  const openDiningOptionModal = () => {
    setModalOpen(true);
  }
  const handleFormInputBlur = (form, key) => {
    form.validateFields([key]);
  }
  const setOption = (type: DiningOptionsEnum) => {
    return async (row) => {
      const defaultSetting = {
        quickOrder: false,
        tableService: false,
        callIn: false,
        openTab: false
      }
      const defaultList = tableData.filter(item => item.uuid === row?.uuid);
      let otherList = tableData.filter(item => item.uuid !== row?.uuid);
      const getDefaultDisplay = (item, excludeKey: DiningOptionsEnum) => ({
        quickOrder: excludeKey !== DiningOptionsEnum.QUICK_ORDER ? item?.defaultDisplay?.quickOrder || false : false,
        tableService: excludeKey !== DiningOptionsEnum.TABLE_SERVICE ? item?.defaultDisplay?.tableService || false : false,
        callIn: excludeKey !== DiningOptionsEnum.CALL_IN_ORDER ? item?.defaultDisplay?.callIn || false : false,
        openTab: excludeKey !== DiningOptionsEnum.OPEN_TAB ? item?.defaultDisplay?.openTab || false : false,
      });

      const optionsObj = {
        [DiningOptionsEnum.QUICK_ORDER]: (item) => getDefaultDisplay(item, DiningOptionsEnum.QUICK_ORDER),
        [DiningOptionsEnum.TABLE_SERVICE]: (item) => getDefaultDisplay(item, DiningOptionsEnum.TABLE_SERVICE),
        [DiningOptionsEnum.CALL_IN_ORDER]: (item) => getDefaultDisplay(item, DiningOptionsEnum.CALL_IN_ORDER),
        [DiningOptionsEnum.OPEN_TAB]: (item) => getDefaultDisplay(item, DiningOptionsEnum.OPEN_TAB),
      };
      otherList = otherList.map(item => {
        let obj = {};
        if (Object.keys(item.defaultDisplay).length) {
          obj = optionsObj[type](item)
        } else {
          obj = defaultSetting
        }
        item.defaultDisplay = obj;
        return item;
      });
      const setDefaultDisplay = {
        [DiningOptionsEnum.QUICK_ORDER]: (defaultDisplay) => ({ ...{ ...defaultSetting, ...defaultDisplay }, quickOrder: true }),
        [DiningOptionsEnum.TABLE_SERVICE]: (defaultDisplay) => ({ ...{ ...defaultSetting, ...defaultDisplay }, tableService: true }),
        [DiningOptionsEnum.CALL_IN_ORDER]: (defaultDisplay) => ({ ...{ ...defaultSetting, ...defaultDisplay }, callIn: true }),
        [DiningOptionsEnum.OPEN_TAB]: (defaultDisplay) => ({ ...{ ...defaultSetting, ...defaultDisplay }, openTab: true })
      }
      defaultList[0].defaultDisplay = setDefaultDisplay[type](defaultList[0].defaultDisplay)
      const options = defaultList.concat(otherList);
      const response = await batchAddDiningOption({
        options
      });
      if (response.code === 0) {
        message.success(response.message);
        fetchTableData();
      }
    }
  }

  const archiveDiningOption = async (row) => {
    const { uuid, archived } = row;
    const res = await batchArchivedDiningOptionAction({
      uuids: [uuid],
      archived: !archived
    });
    if (res?.code === 0) {
      fetchTableData();
    }
  }
  const diningOptionBlur = (row) => {
    const newRow = Object.assign({}, row)
    if (isStrictlyEmptyString(changeRowDiningOption) || changeRowDiningOption.length > 20) {
      message.error(isStrictlyEmptyString(changeRowDiningOption) ? i18n?.t('frontOfHouse_pc_the_input') : i18n?.t('frontOfHouse_pc_maximum', { length: 20 }));
    } else if (newRow.name !== changeRowDiningOption) {
      newRow.name = changeRowDiningOption;
      setDiningOption(newRow);
    }
    setChangeRow('');
    setChangeRowDiningOption('');
  }

  const diningOptionKeyDown = (val, row) => {
    if (val.key === 'Enter') {
      const newRow = Object.assign({}, row)
      if (isStrictlyEmptyString(changeRowDiningOption) || changeRowDiningOption.length > 20) {
        return;
      }
      if (newRow.name !== changeRowDiningOption) {
        newRow.name = changeRowDiningOption;
        setDiningOption(newRow);
      }
    }
  }

  const diningOptionClick = (row) => {
    setChangeRow(row.uuid);
    setChangeRowDiningOption(row.name);
  }

  const tableColumns: any = [
    { key: 'sort', align: 'center', width: 50, render: () => <DragHandle /> },
    {
      dataIndex: 'name', key: 'name', title: i18n.t('frontOfHouse_pc_dining_option'), width: 100, align: 'center',
      render: (_, row) => {
        return (
          <div>
            {
              row.uuid == changeRow ? (
                <Input autoFocus value={changeRowDiningOption} onChange={(val) => {
                  setChangeRowDiningOption(val.currentTarget.value);
                }} onBlur={() => {
                  diningOptionBlur(row);
                }} onKeyDown={(val) => {
                  diningOptionKeyDown(val, row);
                }} />
              ) : (
                <span style={{ 'cursor': 'pointer' }} onClick={
                  (e) => {
                    e.stopPropagation();
                    diningOptionClick(row);
                  }
                }>
                  {row.name}
                </span>
              )
            }
          </div>
        )
      }
    },
    {
      dataIndex: 'uuid', key: 'uuid', title: i18n.t('frontOfHouse_quickServiceDefault'), width: 140, align: 'center',
      render: (_, row) => {
        return (
          <div>
            <Radio.Group value={+row.defaultDisplay.quickOrder} onChange={() => { setOption(DiningOptionsEnum.QUICK_ORDER)(row) }} disabled={row?.archived}>
              <Radio value={1}></Radio>
            </Radio.Group>
          </div>
        )
      }
    },
    {
      dataIndex: 'tableService', key: 'tableService', title: i18n.t('frontOfHouse_tableServiceDefault'), width: 140, align: 'center',
      render: (_, row) => {
        return (
          <div>
            <Radio.Group value={+row.defaultDisplay.tableService} onChange={() => { setOption(DiningOptionsEnum.TABLE_SERVICE)(row) }} disabled={row?.archived}>
              <Radio value={1}></Radio>
            </Radio.Group>
          </div>
        )
      }
    },
    {
      dataIndex: 'callIn', key: 'callIn', title: i18n?.t('frontOfHouse_takeoutOrder'), width: 120, align: 'center',
      render: (_, row) => {
        return (
          <div>
            <Radio.Group value={+row.defaultDisplay.callIn} onChange={() => { setOption(DiningOptionsEnum.CALL_IN_ORDER)(row) }} disabled={row?.archived}>
              <Radio value={1}></Radio>
            </Radio.Group>
          </div>
        )
      }
    },
    {
      dataIndex: 'openTab', key: 'openTab', title: i18n?.t('frontOfHouse_OpenTabDefault'), width: 120, align: 'center',
      render: (_, row) => {
        return (
          <div>
            <Radio.Group value={+row.defaultDisplay.openTab} onChange={() => { setOption(DiningOptionsEnum.OPEN_TAB)(row) }} disabled={row?.archived}>
              <Radio value={1}></Radio>
            </Radio.Group>
          </div>
        )
      }
    },
    {
      dataIndex: 'archived', key: 'archived', title: i18n.t('status'), width: 50, align: 'center',
      defaultFilteredValue: DEFAULT_FILTER_TYPE.STATUS,
      filteredValue: filteredValue,
      filters: statusFilter,
      filterResetToDefaultFilteredValue: true,
      render: (_, row) => {
        return (
          <div>
            {row?.archived ? <span><ArchivedPointIcon /> Archived</span> : <span><ActivePointIcon /> Active</span>}
          </div>
        )
      }
    },
    {
      key: 'actions', title: i18n.t('table_actions'), width: 50, align: 'center',
      render: (_, row) => {
        return (
          <div >
            <Popover content={
              <Button type="link" onClick={() => {
                archiveDiningOption(row);
              }}>
                {
                  <p style={{ 'display': 'flex', 'alignItems': 'center' }}>
                    { row.archived ? <ArchivedIcon /> : <ActiveIcon />}
                    <span style={{ 'marginLeft': '5px' }}>{i18n?.t?.(row.archived ? 'unarchived' : 'archive')}</span>
                  </p>
                }
              </Button>
            }>
              <Button type="link" icon={<MoreOutlined />}></Button>
            </Popover>
          </div>
        )
      }
    },
  ];

  const fetchTableData = async () => {
    setLoading(true)
    const { code, data } = await queryAllDiningOption(params);
    if (code === 0) {
      setTableData(data);
      setChangeRow('');
      setChangeRowDiningOption('');
    }
    setLoading(false)
  }

  const handleTableChange = (_, filters) => {
    setFilteredValue(filters.Status);
    setParams(
      {
        req: {
          status: filters.archived
        }
      }
    )
  }

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over.id) {
      const uuid = tableData.find(f => f.uuid === active.id).uuid;
      const targetSort = tableData.find(f => f.uuid === over.id).displayOrder;
      setTableData((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.uuid === active.id);
        const overIndex = prevState.findIndex((record) => record.uuid === over.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
      const res = await sortDiningOptionAction({
        uuid,
        targetSort
      })
      if (res.code === 0) {
        message.success(res.message);
        fetchTableData();
      } else {
        message.error(res.message);
      }
    }
  };

  return (
    <div className="dining-options-content">
      <div className="dining-options-content-tabpane">
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={openDiningOptionModal}>{i18n.t('new_dining_option')}</Button>
      </div>
      <div className="dining-options-content-tableWrap">
        <DragTable
          loading={loading}
          onDragEnd={onDragEnd}
          rowKey={'uuid'}
          columns={tableColumns}
          dataSource={tableData}
          onChange={handleTableChange}>
        </DragTable>
      </div>
      <Modal title={i18n.t('frontOfHouse_pc_dining_option')} open={modalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form
          {...layout}
          form={diningOptionsForm}
          validateTrigger="onSubmit"
        >
          <Form.Item
            name="name"
            rules={[
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (isStrictlyEmptyString(value)) {
                    return Promise.reject(i18n?.t('frontOfHouse_pc_the_input'));
                  }
                  if (value.length > 20) {
                    return Promise.reject(i18n?.t('frontOfHouse_pc_maximum', { length: 20 }))
                  }
                  return Promise.resolve()
                }
              }),
            ]}
          >
            {<Input required onBlur={() => { handleFormInputBlur(diningOptionsForm, 'name') }} />}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )

}
import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import './index.scss'
import PepprDatePicker from '@/Components/DatePicker';
import { Button, message, Table } from 'antd';
import useGetState from '@/hooks/useGetState';
import dayjs, { Dayjs } from 'dayjs';
import { getToday, listSortBy, removeNullUndefinedFields, formatTimestampWithoutTimeZone, downloadFile, errorOutput, formatFullPrice } from '@/utils';
import { ApiApaasQueryExportResult, ApiExportCashDiscountReport, ApiQueryCashDiscountReport } from '@/request/api';
import { TableEmptyContent } from '@/Components/EmptyContent';
import { ColumnsType } from 'antd/es/table';
import Columns from '@/Components/Columns/Columns';
import { SyncOutlined } from '@ant-design/icons';
import { IPage, RangeValueType } from '@/types';
import NP from '@/utils/NP';

interface IParamsState {
  date: RangeValueType
}

interface ITableData {
  completeDate?: string;
  cardAmount: string;
  cashDiscountAmount: string;
  estimateAmount: string;
}

let exportTaskId;

export default function CashDiscountsProgramReport () {
  const { i18n } = globalState;
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [today, setToday] = useState<Dayjs>(dayjs())
  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)
  const [tableData, setTableData] = useState<Array<ITableData>>([])
  const [totalRowData, setTotalRowData] = useState<Partial<ITableData>>({})
  const [sortedInfo, setSortedInfo, getSortedInfo] = useGetState({ columnKey: 'completeDate', order: 'descend' });
  const [showColumnsList, setShowColumnsList] = useState([])
  const [isExporting, setIsExporting] = useState(false)
  const [intervalId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const [params, setParams, getParams] = useGetState<IParamsState>({
    date: [dayjs(), dayjs()]
  })
  const tableColumns = [
    {
      dataIndex: 'completeDate', key: 'completeDate', title: i18n.t('pc_cash_discounts_program_date'), width: 100,
      sorter: true, sortOrder: sortedInfo.columnKey === 'completeDate' ? sortedInfo.order : null, sortDirections: ['descend', 'ascend', 'descend'],
      render: (val) => formatTimestampWithoutTimeZone(val, { format: 'MM/DD/YYYY' })
    },
    {
      dataIndex: 'cardAmount', key: 'cardAmount', title: i18n.t('pc_cash_discounts_program_total_card_sales'), width: 150, render: (val) => formatFullPrice(val)
    },
    {
      dataIndex: 'cashDiscountAmount', key: 'cashDiscountAmount', title: i18n.t('pc_cash_discounts_program_cash_deposit_uplift'), width: 200,
      sorter: true, sortOrder: sortedInfo.columnKey === 'cashDiscountAmount' ? sortedInfo.order : null, sortDirections: ['descend', 'ascend', 'descend'],
      render: (val) => formatFullPrice(val)
    },
    {
      dataIndex: 'estimateAmount', key: 'estimateAmount', title: i18n.t('pc_cash_discounts_program_estimated_deposit'), width: 200,
      sorter: true, sortOrder: sortedInfo.columnKey === 'estimateAmount' ? sortedInfo.order : null, sortDirections: ['descend', 'ascend', 'descend'],
      render: (val) => formatFullPrice(val)
    }
  ]

  useEffect(() => {
    initPage();
  }, []);

  const initPage = async () => {
    setShowColumnsList(tableColumns.map(x => x.dataIndex))
    const _today = await getToday();
    setToday(_today);
    setParams({ ...params, date: [_today, _today] });
    await fetchTableData();
  }

  const fetchTableData = async () => {
    const _pager = getPager();
    const _params = getRequestParams();
    setIsLoading(true);
    const res = await ApiQueryCashDiscountReport(removeNullUndefinedFields(_params))
    setIsLoading(false);
    if (res.code !== 0) return;
    const dataList = res.data || [];
    setTotalRowData(dataList.reduce((result, item) => {
      result.cardAmount = NP.plus(result.cardAmount, item.cardAmount);
      result.cashDiscountAmount = NP.plus(result.cashDiscountAmount, item.cashDiscountAmount);
      result.estimateAmount = NP.plus(result.estimateAmount, item.estimateAmount);
      return result;
    }, { cardAmount: 0, cashDiscountAmount: 0, estimateAmount: 0 }))
    setTableData(dataList)
    setPager({ ..._pager, total: dataList.length })
  }

  const shouldTableData = () => {
    const _sorter = getSortedInfo();
    if (_sorter.columnKey === 'completeDate') return sortDate(tableData, 'completeDate', _sorter.order === 'ascend')
    if (['cashDiscountAmount', 'estimateAmount'].includes(_sorter.columnKey)) {
      const _tableData = tableData.map(item=>({
        ...item,
        cashDiscountAmount: Number(item.cashDiscountAmount),
        estimateAmount: Number(item.estimateAmount),
      }))
      return listSortBy(_tableData, [_sorter.columnKey], _sorter.order === 'ascend');
    }
    return tableData;
  }

  const sortDate = (data, field, sortAsc) => {
    return data.sort((a, b) => {
      const aDate = dayjs(a[field])
      const bDate = dayjs(b[field])
      if (sortAsc) {
        if (aDate.isBefore(bDate)) {
          return -1;
        }
        if (aDate.isAfter(bDate)) {
          return 1;
        }
      } else if (!sortAsc) {
        if (aDate.isAfter(bDate)) {
          return -1;
        }
        if (aDate.isBefore(bDate)) {
          return 1;
        }
      }
      return 0;
    });
  }

  const onDateRangeChange = (dates) => {
    setParams(({ ...params, date: dates }))
    setPager({ ...pager, current: 1 })
    fetchTableData();
  };

  const handleTableChange = (_pagination, _, sorter) => {
    setPager({ ...pager, ..._pagination });
    setSortedInfo(sorter)
  }

  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0)
    setShowColumnsList(list);
  }

  const getRequestParams = () => {
    const { date, ...curParams } = getParams();
    return {
      orderBy: {
        field: 'completeDate',
        order: 'desc'
      },
      current: 1,
      pageSize: 99999,
      request: {
        beginTime: date[0].format('YYYY-MM-DD 00:00:00'),
        endTime: date[1].format('YYYY-MM-DD 23:59:59'),
      }
    }
  }

  // region 导出相关
  const handleExport = async () => {
    setIsExporting(true)
    const { date } = getParams();
    const params = {
      beginTime: date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: date[1].format('YYYY-MM-DD 23:59:59'),
    }
    try {
      const resp: any = await ApiExportCashDiscountReport(removeNullUndefinedFields(params));
      exportTaskId = resp?.data ?? null;
      if (resp?.code !== 0 || !exportTaskId) {
        errorOutput(resp, i18n.t('pc_payout_report_download_failed'), i18n)
        setIsExporting(false)
        return;
      }
      getExportResult();
    } catch (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'), i18n)
      setIsExporting(false)
    }
  }

  const getExportResult = async () => {
    try {
      const resp: any = await ApiApaasQueryExportResult({ params: { req: { exportTaskId } } });
      if (resp.code !== 0) {
        errorOutput(resp, i18n.t('pc_payout_report_download_failed'), i18n)
        setIsExporting(false)
        stopExport();
        return;
      }
      const data = resp.data;
      if (data?.result && data?.url) {
        // 开启下载
        downloadFile(data.url);
        message.success(i18n.t('pc_payout_report_successfully_downloaded'))
        setIsExporting(false);
        exportTaskId = null;
      } else {
        const id = setTimeout(async () => {
          getExportResult();
        }, 2000);
        setTimeoutId(id);
      }
    } catch (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'), i18n)
      setIsExporting(false)
      stopExport();
    }
  }

  const stopExport = () => {
    if (intervalId !== null) {
      clearTimeout(intervalId);
      setTimeoutId(null);
    }
  }
  // endregion

  const getTableSummaryRow = () => (
    isShowEmptyTable ?
      (
        <Table.Summary.Row>
          <Table.Summary.Cell index={ 0 } colSpan={ 999 }>
            { TableEmptyContent }
          </Table.Summary.Cell>
        </Table.Summary.Row>
      ) :
      tableData.length > 0 &&
      <Table.Summary fixed>
        <Table.Summary.Row className="total-row">
          { showColumnsList.includes('completeDate') && <Table.Summary.Cell index={ 0 }>{ i18n.t('pc_cash_discounts_program_grand_totals') }</Table.Summary.Cell> }
          { showColumnsList.includes('cardAmount') && <Table.Summary.Cell index={ 1 }>{ formatFullPrice(totalRowData.cardAmount) }</Table.Summary.Cell> }
          { showColumnsList.includes('cashDiscountAmount') && <Table.Summary.Cell index={ 2 }>{ formatFullPrice(totalRowData.cashDiscountAmount) }</Table.Summary.Cell> }
          { showColumnsList.includes('estimateAmount') && <Table.Summary.Cell index={ 3 }>{ formatFullPrice(totalRowData.estimateAmount) }</Table.Summary.Cell> }
        </Table.Summary.Row>
      </Table.Summary>
  )

  return (
    <div className="cash-discounts-program-wrap">
      <div className="main-top-wrap">
        <div className="main-title">{ i18n.t('pc_cash_discounts_program_title') }</div>
        <Button type="primary" loading={ isExporting } onClick={ () => handleExport() }>{ i18n.t('export_pc') }</Button>
      </div>
      <div className="filter-wrap">
        <div className="left-wrap">
          <PepprDatePicker value={ params.date } onChange={ onDateRangeChange } today={ today }/>
        </div>
        <div className="right-wrap">
          <Button type="link" onClick={ fetchTableData }><SyncOutlined/></Button>
          <Columns value={ showColumnsList } options={ tableColumns.map(item => ({ label: item.title, value: item.dataIndex })) } onChange={ handleChangeColumns }/>
        </div>
      </div>
      <div className="table-wrap">
        <Table
          className={ isShowEmptyTable && 'empty-table' }
          columns={ tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType }
          dataSource={ shouldTableData().map((x, i) => ({ ...x, key: i })) }
          rowKey={ 'key' }
          loading={ isLoading }
          pagination={ pager }
          onChange={ handleTableChange }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
          summary={ () => (
            getTableSummaryRow()
          ) }
        />
      </div>
    </div>
  )
}